import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { DivisionLine } from "../../../DivisionLine";
import TextInput from "../../../inputs/TextInput/TextInput";
import { PCInput } from "../../../inputs/PCInput";
import { SelectUI } from "../../../inputs/SelectUI/Select";

import styles from "./MoralCompanyData.module.sass";
import { TextArea } from "../../../inputs/TextArea";
import { banks } from "../ctl_bank";
import {
  updateInput,
  updateInputStatus,
} from "../../../../redux-modules/form/actions";
import { personaMoral } from "../../OfficialData/dataAutoFill";

function MoralCompanyDataComponent({ callValidation, state, dispatch }) {
  const s = styles;
  const [bankDescription, setBankDescription] = useState("");
  const { currentSection } = state;

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  function getValuesFromStore(reduxState) {
    let options;
    reduxState[currentSection.name].map((inputItem) => {
      if (
        inputItem.name ===
        `suburbOptions${capitalizeFirstLetter(currentSection.name)}`
      ) {
        options = inputItem.value;
      }
      return options;
    });
    return options;
  }

  useEffect(() => {
    const dataPM = personaMoral(state);
    if(state.companyData && state.companyData.length>0){
      return
    }

    // COMPANY DATA
    dispatch(
      updateInput({
        name: "companyDataCompanyName",
        value: dataPM.moral.razonSocial ?? "",
        validated: dataPM.moral.razonSocial !== "",
      })
    );
    dispatch(
      updateInput({
        name: "companyDataCommercialName",
        value: dataPM.moral.nombreComercial ?? "",
        validated: dataPM.moral.nombreComercial !== "",
      })
    );

    dispatch(
      updateInput({
        name: "companyDataRFC",
        value: dataPM.moral.rfc ?? "",
        validated: dataPM.moral.rfc !== "",
      })
    );

    //BANK DATA
    dispatch(
      updateInput({
        name: "companyDataBankAccountTitularName",
        value: dataPM.moral.nombreTitular ?? "",
        validated: dataPM.moral.nombreTitular !== "",
      })
    );
    
    dispatch(
      updateInput({
        name: "companyDataBankNumber",
        value: dataPM.moral.cuenta ?? "",
        validated: dataPM.moral.cuenta !== "",
      })
    );
    
    dispatch(
      updateInput({
        name: "companyDataBankCLABE",
        value: dataPM.moral.clabe ?? "",
        validated: dataPM.moral.clabe !== "",
      })
    );

  }, []);

  function getValuesFromStoreBankNumber(reduxState) {
    let options;
    reduxState[currentSection.name].map((inputItem) => {
      if (inputItem.name === `companyDataBankCLABE`) {
        options = inputItem.value;
      }
      return options;
    });
    return options;
  }

  useEffect(() => {
    const options = getValuesFromStoreBankNumber(state);
    if (options) {
      let acount = options?.toString();
      acount = acount?.slice(0, 3);
      if (acount.length == 3) {
        let bank = banks.find((bank) => bank.acountId == acount);
        if (bank) {
          let bankName = bank.name;
          setBankDescription(bankName);
          dispatch(
            updateInput({
              name: "companyDataBankName",
              value: bankName,
              validated: true,
            })
          );
          dispatch(
            updateInputStatus({ name: "companyDataBankName", validated: true })
          );
          return;
        }
        setBankDescription("N/A");
        dispatch(
          updateInputStatus({ name: "companyDataBankName", validated: false })
        );
        return;
      }
      setBankDescription("");
      dispatch(
        updateInputStatus({ name: "companyDataBankName", validated: false })
      );
      return;
    }
    setBankDescription("Ingresa tu CLABE interbancaria");
  }, [getValuesFromStoreBankNumber(state)]);

  const altTitle =
    "Datos de la empresa. Estás en el paso 3 de 5. Necesitarás escribir los datos fiscales de tu empresa, incluyendo dirección. También redes sociales, sitio web, palabras clave y datos bancarios";

  return (
    <div className={s.taxpayerData}>
      <div className={s.titleAndDescription}>
        <h1 className={s.title} alt={altTitle}>Datos de la empresa</h1>
        <p className={s.subtitle}>Verifica que los datos de la empresa sean correctos</p>
      </div>
      <div className={s.inputsCompanyData}>
        <TextInput
          required
          title="Razón social"
          placeholder="Razón social"
          storeName="companyDataCompanyName"
          inputValidation="bussinesName"
          callValidation={callValidation}
          aditionalInfo="Es el nombre legal de tú empresa"
          thisValueWillBeSetFromRedux = {true}
        />

        <TextInput
          required
          title="Nombre comercial"
          placeholder="Nombre comercial"
          storeName="companyDataCommercialName"
          inputValidation="commercialName"
          callValidation={callValidation}
          aditionalInfo="Es el nombre con el que los clientes reconocen tú empresa"
          thisValueWillBeSetFromRedux = {true}

        />

        <div className="" />

        <TextInput
          required
          title="RFC"
          placeholder="RFC"
          storeName="companyDataRFC"
          inputValidation="rfc"
          callValidation={callValidation}
          aditionalInfo="Escribe el RFC con homoclave"
          thisValueWillBeSetFromRedux = {true}

        />
      </div>
      <DivisionLine />
      <div className={s.titleAndDescription}>
        <h2 className={s.title}>Dirección fiscal de la empresa</h2>
        <p className={s.subtitle}>Verifica que los datos de la dirección fiscal de la empresa sean correctos</p>
      </div>
      <div className={s.addressWrapper}>
        <TextInput
          required
          title="Calle"
          placeholder="Calle"
          storeName={`street${capitalizeFirstLetter(currentSection.name)}`}
          inputValidation="street"
          callValidation={callValidation}
        />

        <TextInput
          required
          title="Número exterior"
          placeholder="Número exterior"
          inputValidation="outdoorNumber"
          storeName={`exteriorNumber${capitalizeFirstLetter(
            currentSection.name
          )}`}
          callValidation={callValidation}
        />

        <TextInput
          title="Número interior"
          placeholder="Número interior"
          storeName={`interiorNumber${capitalizeFirstLetter(
            currentSection.name
          )}`}
          callValidation={callValidation}
        />

        <PCInput
          required
          title="Código postal"
          placeholder="Código postal"
          storeName={`postalCode${capitalizeFirstLetter(currentSection.name)}`}
          inputValidation="postalCode"
          callValidation={callValidation}
        />

        <div className={s.whiteSpace} />

        <div className={s.whiteSpace} />

        <SelectUI
          id="Colonia"
          name="Colonia"
          storeName={`suburb${capitalizeFirstLetter(currentSection.name)}`}
          defaultValue="Colonia*"
          options={getValuesFromStore(state) || ["Introduce un código postal"]}
          callValidation={callValidation}
        />

        <TextInput
          required
          title="Municipio o alcaldía"
          placeholder="Municipio o alcaldía"
          storeName={`municipality${capitalizeFirstLetter(
            currentSection.name
          )}`}
          thisValueWillBeSetFromRedux
          callValidation={callValidation}
          inputValidation="option"
        />

        <div className={s.whiteSpace} />

        <TextInput
          required
          title="Estado"
          placeholder="Estado"
          storeName={`state${capitalizeFirstLetter(currentSection.name)}`}
          thisValueWillBeSetFromRedux
          callValidation={callValidation}
          inputValidation="option"
        />

        <TextInput
          required
          title="País"
          placeholder="País"
          storeName={`country${capitalizeFirstLetter(currentSection.name)}`}
          thisValueWillBeSetFromRedux
          callValidation={callValidation}
          inputValidation="option"
        />

        <div className={s.whiteSpace} />

        <TextInput
          required
          title="Teléfono local"
          placeholder="Teléfono local"
          storeName="localPhoneCompanyData"
          hasLengthLimit={10}
          thisValueWillBeSetFromRedux
          callValidation={callValidation}
          inputValidation="phone"
          aditionalInfo="Escribe el número a 10 dígitos"
        />
      </div>

      <DivisionLine />

      <div className={s.titleAndDescription}>
        <h2 className={s.title}>Redes sociales de la empresa</h2>
      </div>
      <div className={s.inputsCompanyData}>
        <TextInput
          title="Facebook"
          placeholder="Facebook"
          storeName="companyDataFacebook"
          inputValidation="default"
          callValidation={callValidation}
        />

        <TextInput
          title="Instagram"
          placeholder="Instagram"
          storeName="companyDataInstagram"
          inputValidation="default"
          callValidation={callValidation}
        />

        <div className={s.whiteSpace} />

        <TextInput
          title="Twitter"
          placeholder="Twitter"
          storeName="companyDataTwitter"
          inputValidation="default"
          callValidation={callValidation}
        />

        <TextInput
          title="Sitio Web"
          placeholder="Sitio Web"
          storeName="companyDataWebsite"
          inputValidation="default"
          callValidation={callValidation}
        />

        <div className={s.whiteSpace} />

        <TextArea
          required
          storeName="companyDataKeywords"
          callValidation={callValidation}
          title="Ingresa palabras clave de la empresa"
          placeholder={`Escribe palabras relacionadas con el producto o servicio, separadas con coma. Por ejemplo: 
"pintura, brochas, ferretería".`}
        />
      </div>

      <DivisionLine />
      <div className={s.titleAndDescription}>
        <h2 className={s.title}>Cuenta bancaria</h2>
        <p className={s.subtitle}>
          Verifica que los datos de la cuenta bancaria que va a recibir los pagos sean correctos
        </p>
      </div>
      <div className={s.inputsCompanyData}>
        <TextInput
          required
          title="Nombre completo del titular"
          placeholder="Nombre completo del titular"
          storeName="companyDataBankAccountTitularName"
          inputValidation="fullName"
          callValidation={callValidation}
          aditionalInfo="Debe coincidir con la razón social de la empresa o nombre del representante legal"
          thisValueWillBeSetFromRedux = {true}

        />
        <TextInput
          required
          title="Número de cuenta"
          placeholder="Número de cuenta"
          storeName="companyDataBankNumber"
          inputValidation="bankAccountNumber"
          callValidation={callValidation}
          hasLengthLimit={18}
          type="number"
          thisValueWillBeSetFromRedux = {true}

        />

        <div className={s.whiteSpace} />

        <TextInput
          required
          title="CLABE interbancaria"
          placeholder="CLABE interbancaria"
          storeName="companyDataBankCLABE"
          inputValidation="CLABE"
          type="number"
          hasLengthLimit="18"
          callValidation={callValidation}
          aditionalInfo="Escribe los 18 dígitos"
          thisValueWillBeSetFromRedux = {true}

        />

        <div className={s.inputReadOnly}>
          <div
            className={`${
              bankDescription == "Ingresa tu CLABE interbancaria"
                ? s.hidden
                : {}
            } ${
              bankDescription == "" || bankDescription == "N/A"
                ? s.bankNameTitleDisabled
                : s.bankNameTitle
            } `}
          >
            {" "}
            Nombre del banco*
          </div>
          <div className={s.bankName}>{bankDescription}</div>
        </div>

        <div className={s.whiteSpace} />
      </div>

      <DivisionLine />
    </div>
  );
}

MoralCompanyDataComponent.propTypes = {
  callValidation: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  state: PropTypes.object.isRequired,
};

MoralCompanyDataComponent.defaultProps = {
  callValidation: false,
};
function mapStateToProps(state) {
  return {
    state,
  };
}

export const MoralCompanyData = connect(mapStateToProps)(
  MoralCompanyDataComponent
);
