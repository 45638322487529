/**
 *
 */
export default function validateFileType(file, onlyPDF = false) {
  let validTypes;
  let errorMessage = "Revisa que el archivo sea formato PDF, JPG, JPEG o PNG";

  if (onlyPDF) {
    errorMessage = "Revisa que el archivo sea un PDF";
  }

  if (onlyPDF) {
    validTypes = ["pdf", "PDF"];
  } else {
    validTypes = ["pdf", "PDF", "jpg", "JPG", "jpeg", "JPEG", "png", "PNG"];
  }

  const fileType = file.name.split(".").pop();

  // if one of the array elements is same as fileType, return true
  if (validTypes.includes(fileType)) {
    return true;
  }

  return errorMessage;
}
