export default async function validateImgDimensions(file) {
  let result = false;
  const errorMessage = "Revisa que la imagen cumpla con las dimensiones";
  const fileReader = new FileReader();

  const promise = new Promise((resolve) => {
    fileReader.onload = () => {
      const img = new Image();
      img.src = fileReader.result;
      img.onload = () => {
        if (img.width <= 100 && img.height <= 100) {
          result = null;
          resolve(result);
        } else {
          result = errorMessage;
          resolve(result);
        }
      };
    };
  });

  fileReader.readAsDataURL(file);
  await promise;
  return result;
}
