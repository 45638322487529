/* eslint-disable react/jsx-no-duplicate-props */
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import {
  changeTypeOfPerson,
  updateRadio,
} from "../../../redux-modules/form/actions";
import checkFromStore from "../utils/checkFromStore";
import styles from "./RadioMainSelector.module.sass";

function RadioMainSelectorComponent({
  state,
  title,
  storeName,
  dispatch,
  optionsList,
  handleValueChange,
}) {
  const s = styles;
  function handleOnChange(e, optionId) {
    const radioValue = e.target.value;
    const payload = { name: storeName, value: radioValue, validated: true };
    handleValueChange(optionId);
    dispatch(changeTypeOfPerson({ value: optionId }));
    dispatch(updateRadio(payload));
    return radioValue;
  }

  function handleTypeOfPerson(itemName, itemId) {
    const isChecked = checkFromStore(itemName, storeName, state, "");
    if (isChecked) {
      handleValueChange(itemId);
    }
    return isChecked;
  }

  return (
    <div className={s.radioGroup}>
      <h2>{title}</h2>
      <div className={s.options}>
        {optionsList.map((item) => (
          <label
            key={item.name + storeName}
            className={s.option}
            htmlFor={item.name + storeName}
            id={item.name + item.id}
          >
            <input
              type="radio"
              name={storeName}
              id={item.name + storeName}
              value={item.name}
              checked={handleTypeOfPerson(item.name, item.id) || false}
              onChange={(e) => {
                handleOnChange(e, item.id);
              }}
            />
            {item.name}
          </label>
        ))}
      </div>
    </div>
  );
}
function mapStateToProps(state) {
  return { state };
}

export const RadioMainSelector = connect(
  mapStateToProps,
  null
)(RadioMainSelectorComponent);

RadioMainSelectorComponent.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  state: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  storeName: PropTypes.string.isRequired,
  optionsList: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.oneOfType(PropTypes.string, PropTypes.number))
  ).isRequired,
  handleValueChange: PropTypes.func.isRequired,
};
