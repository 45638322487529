import {
  ADD_ERROR_MESSAGE_TO_DOCUMENTS,
  CHANGE_TYPE_OF_PERSON,
  CLEAR_DATA,
  CLEAR_POP_ADDRESS,
  NEW_ADMIN_ADDED,
  NEW_ADMIN_REMOVED,
  NEW_ASSOCIATE_ADDED,
  NEW_ASSOCIATE_REMOVED,
  NEXT_SECTION,
  LOG_OUT,
  POSTAL_CODE_DATA,
  PREV_SECTION,
  RESET_ADMIN_COUNT,
  UPDATE_COMPLETED_VALIDATIONS,
  UPDATE_INPUT,
  UPDATE_INPUT_METAMAP,
  UPDATE_STEPPER_LOADER,
  UPDATE_INPUT_EXTRA,
  UPDATE_INPUT_STATUS,
  UPDATE_RADIO,
  UPDATE_VALIDATION_DATA,
  UPDATE_VALIDATION_ERRORS,
  VALIDATIONS_COMPLETED_CONTACT,
  VERIFY_VALIDATIONS_COMMERCIAL_INFORMATION,
  VERIFY_VALIDATIONS_COMPLETED_COMPANY_DATA,
  VERIFY_VALIDATIONS_COMPLETED_DOCUMENTS,
  VERIFY_VALIDATIONS_OFFICIAL_DATA,
  UPDATE_METAMAP_FILEUPLOAD_DATA,
  DELETE_ITEM_BY_NAME,
  UPDATE_INPUT_LOGO,
  COPY_DATA,
  CLEAN_STORE,
  LIST_FILE_ERROR,
  REPLACE_STATE,
  SAVE_STATE_ON_SERVER,
} from "./types";

export const validationsCompletedContact = (payload) => ({
  type: VALIDATIONS_COMPLETED_CONTACT,
  payload,
});

export const verifyValidationsDocuments = (payload) => ({
  type: VERIFY_VALIDATIONS_COMPLETED_DOCUMENTS,
  payload,
});

export const verifyValidationsCompanyData = (payload) => ({
  type: VERIFY_VALIDATIONS_COMPLETED_COMPANY_DATA,
  payload,
});

export const verifyValidationsOfficialData = (payload) => ({
  type: VERIFY_VALIDATIONS_OFFICIAL_DATA,
  payload,
});

export const NewAssociateAdded = () => ({
  type: NEW_ASSOCIATE_ADDED,
});

export const NewAssociateRemoved = (payload) => ({
  type: NEW_ASSOCIATE_REMOVED,
  payload,
});

export const NewAdminAdded = () => ({
  type: NEW_ADMIN_ADDED,
});

export const NewAdminRemoved = (payload) => ({
  type: NEW_ADMIN_REMOVED,
  payload,
});

export const resetAdminCount = () => ({
  type: RESET_ADMIN_COUNT,
});

export const verifyValidationsCommercialInformation = (payload) => ({
  type: VERIFY_VALIDATIONS_COMMERCIAL_INFORMATION,
  payload,
});

export const updateInputExtra = (payload) => ({
  type: UPDATE_INPUT_EXTRA,
  payload,
});

export const updateInput = (payload) => ({
  type: UPDATE_INPUT,
  payload,
});

export const updateInputMetamap = (payload) => ({
  type: UPDATE_INPUT_METAMAP,
  payload,
});

export const updateStepperLoader = (payload) => ({
  type: UPDATE_STEPPER_LOADER,
  payload,
});

export const changeTypeOfPerson = (payload) => ({
  type: CHANGE_TYPE_OF_PERSON,
  payload,
});

export const updateInputStatus = (payload) => ({
  type: UPDATE_INPUT_STATUS,
  payload,
});

export const updateRadio = (payload) => ({
  type: UPDATE_RADIO,
  payload,
});

export const nextSection = () => ({
  type: NEXT_SECTION,
});

export const logOut = () => ({
  type: LOG_OUT,
});

export const prevSection = () => ({
  type: PREV_SECTION,
});

export const clearData = () => ({
  type: CLEAR_DATA,
});

export const clearPOPAddress = (payload) => ({
  type: CLEAR_POP_ADDRESS,
  payload,
});

export const postalCodeData = (payload) => ({
  type: POSTAL_CODE_DATA,
  payload,
});

export const addErrorMessageToDocuments = (payload) => ({
  type: ADD_ERROR_MESSAGE_TO_DOCUMENTS,
  payload,
});

// Metamap

export const updateCompletedValidations = (payload) => ({
  type: UPDATE_COMPLETED_VALIDATIONS,
  payload,
});

export const updateValidationData = (payload) => ({
  type: UPDATE_VALIDATION_DATA,
  payload,
});

export const updateValidationErrors = (payload) => ({
  type: UPDATE_VALIDATION_ERRORS,
  payload,
});

export const updateMetamapFileUploadData = (payload) => ({
  type: UPDATE_METAMAP_FILEUPLOAD_DATA,
  payload,
});

export const deleteItemByName = (payload) => ({
  type: DELETE_ITEM_BY_NAME,
  payload,
});

export const updateInputLogo = (payload) => ({
  type: UPDATE_INPUT_LOGO,
  payload,
});

export const copyData = (payload) => ({
  type: COPY_DATA,
  payload,
});

export const cleanStore = (payload) => ({
  type: CLEAN_STORE,
  payload,
});

export const listFileError = (payload) => ({
  type: LIST_FILE_ERROR,
  payload,
});

export const replaceState = (payload) => ({
  type: REPLACE_STATE,
  payload,
});

export const saveStateOnServer = (payload) => ({
  type: SAVE_STATE_ON_SERVER,
  payload,
});
