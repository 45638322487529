/**
 * Check that every document in the array of documents is valid
 * @param {Array} documents - Array of documents
 * @returns {Boolean} - True if all documents are valid, false otherwise
 */
export default function validateEveryDocument(documents) {
  function getValueFromMetamapArrays(myArray, nameKey) {
    for (const element of myArray) {
      if (element === undefined || element == null) continue;
      if (element[nameKey] !== undefined) {
        return element[nameKey].value;
      }
    }
    return "";
  }
  function checkCSF(document) {
    return (
      document.value.status === "verified" &&
      getValueFromMetamapArrays(
        document.value.data,
        "custom-constancia-fiscal-rfc"
      ) !== "" &&
      getValueFromMetamapArrays(document.value.data, "name") !== "" &&
      getValueFromMetamapArrays(document.value.data, "activity") !== ""
    );
  }
  function checkIne(document) {
    return (
      document.value.status === "verified" &&
      getValueFromMetamapArrays(document.value.data, "fullName") !== "" &&
      getValueFromMetamapArrays(document.value.data, "dateOfBirth") !== "" &&
      getValueFromMetamapArrays(document.value.data, "documentNumber") !== "" &&
      getValueFromMetamapArrays(document.value.data, "ocrNumber") !== ""
    );
  }

  function checkPassport(document) {
    return (
      document.value.status === "verified" &&
      getValueFromMetamapArrays(document.value.data, "fullName") !== "" &&
      getValueFromMetamapArrays(document.value.data, "dateOfBirth") !== ""
    );
  }
  function checkBankStatement(document) {
    return document.value.status === "verified";
  }
  function checkData(document) {
    switch (document.name) {
      case "CSF":
        return checkCSF(document);
      case "passport":
        return checkPassport(document);
      case "ineBack":
        return checkIne(document);
      case "bankStatement":
        return checkBankStatement(document);
      default:
        return document.value.status === "verified";
    }
  }

  return documents.every((document) => {
    const status = checkData(document);
    if (!status) {
      document.value.status = "rejected";
      document.value.errors = [
        "No pudimos validar el documento, intenta de nuevo",
      ];
    }
    return status;
  });
}
