export default function Franchise(officialData, companyData, commercialInfo) {
  const { commercialInformationMainCategorySales } = commercialInfo;
  return {
    name: companyData.companyDataCommercialName,
    description: companyData.companyDataCommercialName,
    product_id: 31,
    category_id: commercialInformationMainCategorySales,
    voucher_header: "voucher_header",
    status: "0",
    logo: "url_logo",
  };
}
