import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import TagManager from "react-gtm-module";
import { returnErrorsAndDocumentName } from "../../scripts/returnDocumentsWithErrors";
import { listFileError } from "../../redux-modules/form/actions";
import styles from "./ListErrors.module.sass";
import BaseMD from "../../google-analytics/events/baseMD";

export default function ListErrors({ subTitle }) {
  const s = styles;
  const dispatch = useDispatch();
  // use Selector to get the errors from the store
  // and update the component when the errors change
  const documents = useSelector((state) => state.documents);

  const documentsWithError = returnErrorsAndDocumentName(documents);
  const currentSection = useSelector((state) => state.currentSection.name);
  const officialData = useSelector((state) => state.officialData);

  useEffect(() => {
    documentsWithError.forEach((document) => {
      document.errors.forEach((error) => {
        TagManager.dataLayer(
          BaseMD.MD3C(
            error,
            currentSection,
            `${subTitle} ${document.fileName}`,
            officialData
          )
        );
      });
    });
    dispatch(listFileError({ fileError: documentsWithError }));
  }, []);
  return (
    <div className={s.container}>
      <span className={s.subTitle}>{subTitle}</span>
      <ul className={s.list}>
        {documentsWithError.map((doc) => (
          <div key={doc.fileName}>
            <li> {doc.fileName}</li>
            <span>
              {doc.errors.map((error) => (
                <p className={s.error} key={error}>
                  {" "}
                  {error}{" "}
                </p>
              ))}
            </span>
          </div>
        ))}
      </ul>
    </div>
  );
}
