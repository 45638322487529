/* eslint-disable no-unused-vars */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import styles from "./Checkbox.module.sass";
import {
  updateInput,
  updateInputStatus,
} from "../../../redux-modules/form/actions";

function Checkbox({
  storeName,
  externalHandler,
  title,
  description,
  state,
  dispatch,
  classExt,
  isRequired = true,
}) {
  const s = styles;

  const handleOnClick = (e) => {
    if (externalHandler) {
      externalHandler(e);
    }
    let isChecked = false;
    if (e.target.checked) {
      isChecked = true;
    }

    const payload = {
      name: storeName,
      value: isChecked,
    };

    const payloadValidated = {
      name: storeName,
      validated: isRequired && isChecked,
    };

    dispatch(updateInput(payload));
    dispatch(updateInputStatus(payloadValidated));
    return payload;
  };

  const checkboxValueFromStore = (reduxState, checkboxNameInStore) => {
    const currentSection = reduxState.currentSection.name;
    let checkValue;

    reduxState[currentSection].map((input) => {
      if (input.name === checkboxNameInStore) {
        checkValue = input.value;
        return checkValue;
      }
      return false;
    });
    return checkValue;
  };

  useEffect(() => {
    if (!checkboxValueFromStore(state, storeName)) {
      const payload = { name: storeName, value: false, validated: false };
      dispatch(updateInput(payload));
    }
  }, []);

  return (
    <label htmlFor={storeName} className={classExt ?? s.wrapper}>
      <label htmlFor={storeName} className={s.title}>
        <input
          className={classExt ? s.checkbox : ""}
          type="checkbox"
          name={storeName}
          id={storeName}
          defaultChecked={checkboxValueFromStore(state, storeName) || false}
          onClick={(e) => {
            handleOnClick(e);
          }}
        />
        {title}
      </label>
      {description && <p className={s.description}>{description}</p>}
    </label>
  );
}

Checkbox.propTypes = {
  storeName: PropTypes.string.isRequired,
  externalHandler: PropTypes.func,
  title: PropTypes.string,
  description: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  state: PropTypes.object.isRequired,
};

Checkbox.defaultProps = {
  externalHandler: false,
  title: "title",
  description: "",
};

function mapStateToProps(state) {
  return {
    state,
  };
}

const connectedComponent = connect(mapStateToProps)(Checkbox);

export { connectedComponent as CheckBox };
