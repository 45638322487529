import React, { useEffect } from "react";
import Proptypes from "prop-types";
import infoIcon from "../assets/info.svg";
import cerrarIcon from "../assets/cerrar.svg";
import styles from "./InfoModal.module.sass";

const ModalInfo = ({ children, toggle, tittle, center }) => {
  const s = styles;
  return (
    <>
      <div>
        <div>
          <button className={s.closeBtn} onClick={toggle}>
            <img src={cerrarIcon} alt="" />
          </button>
        </div>
        <div className={s.header}>
          <img src={infoIcon} alt="" />
        </div>
        <h2 className={`${center ? s.tittleCenter : s.tittle}`}>{tittle}</h2>
        <div className={s.children}>{children}</div>
      </div>
    </>
  );
};

ModalInfo.propTypes = {
  children: Proptypes.node,
  toggle: Proptypes.func.isRequired,
  tittle: Proptypes.string.isRequired,
  center: Proptypes.bool,
};


export default ModalInfo;
