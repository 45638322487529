/**
 * If value given is a string, it will be coverted to lowercase
 * @param {*} valueGiven
 * @returns valueGiven as a string
 */
export default function ifStringToLowerCase(valueGiven) {
  if (typeof valueGiven === "string") {
    return valueGiven.toLowerCase();
  }
  return valueGiven;
}
