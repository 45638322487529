export default function validateDocuments(
  state,
  pathName,
  pathINE,
  pathPassport,
  pathReupload,
  currentStep
) {
  let stepName;

  if (pathName === "reupload") {
    stepName = pathReupload[currentStep];
  } else if (pathName === "pathINE") {
    stepName = pathINE[currentStep];
  } else if (pathName === "pathPassport") {
    stepName = pathPassport[currentStep];
  }
  if (stepName === "reuploadTheFollowingDocuments") {
    // because this view doesn't have a file input
    return true;
  }
  const item = state.find((i) => i.name === stepName);

  if (item !== undefined) {
    return item.value.errors === null || item.value.errors.length === 0;
  }

  return false;
}
