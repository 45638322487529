/* eslint-disable react/jsx-no-duplicate-props */
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import TagManager from "react-gtm-module";
import { connect, useSelector } from "react-redux";
import BaseMD from "../../../google-analytics/events/baseMD";
import {
  updateRadio,
  validationsCompletedContact,
  verifyValidationsCommercialInformation,
  verifyValidationsCompanyData,
  verifyValidationsOfficialData,
} from "../../../redux-modules/form/actions";
import checkFromStore from "../utils/checkFromStore";
import styles from "./RadioGroup.module.sass";

function RadioGroupComponent({
  title,
  storeName,
  state,
  dispatch,
  optionsList,
  externalHandler,
  callValidation,
  canCopy,
  copyFrom,
  sectionCopyFrom,
}) {
  const s = styles;
  const [validationErrorMsg, setValidationErrorMsg] = useState("");
  const currentSectionMD = useSelector((state) => state.currentSection.name);
  const typeOfPersonId = useSelector((state) => state.typeOfPerson?.id);
  const officialData = useSelector((state) => state.officialData);

  function handleOnChange(e) {
    const radioValue = e.target.value;
    const payload = { name: storeName, value: radioValue, validated: true };

    setValidationErrorMsg("");

    dispatch(updateRadio(payload));

    if (externalHandler) {
      externalHandler(e);
    }

    const currentSection = state.currentSection.name;
    switch (currentSection) {
      case "contact":
        dispatch(validationsCompletedContact());
        break;
      case "officialData":
        dispatch(verifyValidationsOfficialData());
        break;
      case "companyData":
        dispatch(verifyValidationsCompanyData());
        break;
      case "commercialInfo":
        dispatch(verifyValidationsCommercialInformation());
        break;
      default:
        break;
    }

    return this;
  }

  function verifyRequired() {
    const radioButtons = document.querySelector(
      `input[name = ${storeName}]:checked`
    );

    if (radioButtons === null) {
      setValidationErrorMsg("Elige una opción para continuar");
    } else {
      setValidationErrorMsg("");
    }
  }

  const alreadyMounted = useRef(false);

  useEffect(() => {
    if (alreadyMounted.current) {
      verifyRequired();
    } else {
      alreadyMounted.current = true;
    }
  }, [callValidation]);

  useEffect(() => {
    if (validationErrorMsg === "") return;

    TagManager.dataLayer(
      BaseMD.MD3C(
        validationErrorMsg,
        currentSectionMD,
        title,
        officialData,
        typeOfPersonId
      )
    );
  }, [validationErrorMsg]);
  return (
    <div className={s.radioGroup}>
      <h4 className={s.groupTitle}>{title}</h4>
      <div className={s.options}>
        {optionsList.map((item) => (
          <label
            className={s.option}
            htmlFor={item.name + storeName}
            key={item.name + storeName}
          >
            <input
              disabled={canCopy}
              type="radio"
              checked={checkFromStore(
                item.name,
                canCopy ? copyFrom : storeName,
                state,
                sectionCopyFrom,
                canCopy
              )}
              name={storeName}
              id={item.name + storeName}
              value={item.name}
              onChange={(e) => {
                handleOnChange(e);
              }}
            />
            {item.name}
          </label>
        ))}
      </div>
      {validationErrorMsg !== "" && !canCopy && (
        <small className={s.inputErrorFeedback}>{validationErrorMsg}</small>
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    state,
  };
}

export const RadioGroup = connect(mapStateToProps, null)(RadioGroupComponent);

RadioGroupComponent.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  state: PropTypes.object.isRequired,
  title: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  storeName: PropTypes.string.isRequired,
  optionsList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
    })
  ).isRequired,
  externalHandler: PropTypes.func,
  callValidation: PropTypes.bool,
};

RadioGroupComponent.defaultProps = {
  title: "",
  callValidation: false,
  externalHandler: () => {
    return false;
  },
};
