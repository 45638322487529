import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./StepperDocs.sass";

function StepperReload({ funct }) {
  const steps = [];
  const [currentStep, setCurrentStep] = useState(0);
  const metamapCounter = useSelector((state) => state.utils);
  const processed = metamapCounter.map(({ count }) => count);

  metamapCounter.forEach((item) => {
    if (item.fileError) {
      item.fileError.forEach((errorItem) => {
        if (errorItem.fileName) {
          steps.push(errorItem.fileName);
        }
      });
    }
  });
  const stepName = steps.filter((step) => !step.includes("INE"));
  if (steps.length > stepName.length) {
    stepName.push("Identificación oficial");
  }

  let alterStep = 0;
  if (alterStep !== processed[0]) {
    // eslint-disable-next-line prefer-destructuring
    alterStep = processed[0];
  }

  useEffect(() => {
    setCurrentStep(alterStep);
  }, [alterStep]);

  setTimeout(() => {
    funct();
  }, 180000);

  const [value, setValue] = useState(0);

  const MAX = 100;

  useEffect(() => {
    if (currentStep > 0) {
      const valuePercent = 100 / stepName.length;
      setValue(value + valuePercent);
    }
  }, [currentStep]);

  const getBackgroundSize = () => {
    return { backgroundSize: `${(value * 100) / MAX}% 100%` };
  };

  return (
    <>
      <div className="steps-container">
        <input
          type="range"
          min="0"
          max={MAX}
          value={value}
          step={parseInt(100 / stepName.length)}
          style={getBackgroundSize()}
        />
      </div>
      <div className="documentName">
        <p className="pTextName">{stepName[currentStep]}</p>
      </div>
    </>
  );
}

export default StepperReload;
