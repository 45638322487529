import axios from "axios";
import PropTypes from "prop-types";
import React from "react";
import TagManager from "react-gtm-module";
import { useDispatch, useSelector } from "react-redux";
import {
  addErrorMessageToDocuments,
  deleteItemByName,
  updateInputMetamap,
  updateMetamapFileUploadData,
} from "../../redux-modules/form/actions";
import validateFileSize from "../../scripts/validations/validateFileSize";
import validateFileType from "../../scripts/validations/validateFileType";
import { flowsId } from "../../utils/flowsId";
import LightBlueBtn from "../lightBlueBtn/LightBlueBtn";
import InfoMetaMap from "./InfoMetaMap";
import BaseMD from "../../google-analytics/events/baseMD";
import {
  getIdDocumentType,
  sendFileToMetamap,
} from "../../scripts/sendFileMetamap";

function getEndpointDocument(storeName) {
  switch (storeName) {
    case "CSF":
      return "csf";
    case "ineBack":
      return "ine";
    case "bankStatement":
      return "estado-cuenta";
    case "passport":
      return "pasaporte";
  }
}

export default function BlueBtnFileWithValidations({
  text,
  className,
  storeName,
  onlyPdf,
  onSendMessage,
  title,
}) {
  const dispatch = useDispatch();
  const getInfoMetamap = useSelector((state) => state.infoMetamap);
  const currentSection = useSelector((state) => state.currentSection.name);
  const errorDocument = "No pudimos validar el documento, intenta de nuevo";
  // general validations
  const val = [validateFileSize, validateFileType];
  const officialData = useSelector((state) => state.officialData);

  const executeSendFile = async (file, flowID) => {
    const formData = new FormData();
    formData.append("file", file, file.name.toLowerCase());

    if (storeName === "ineBack") {
      const fileIneFront = getInfoMetamap[1].file;
      formData.append("file2", fileIneFront, fileIneFront.name.toLowerCase());
    }

    const payloadErrorMessage = {
      name: storeName,
      value: {
        errors: [errorDocument],
        status: "rejected",
      },
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_UPLOAD_DOCUMENT_URL}/${getEndpointDocument(
          storeName
        )}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      const respData = response.data;
      if (respData.error) {
        TagManager.dataLayer(
          BaseMD.MD3C(errorDocument, currentSection, text, officialData)
        );

        dispatch(addErrorMessageToDocuments(payloadErrorMessage));
      } else {
        // return respData.data;

        // TODO: Flujo de envio de archivos a metamap
        return await sendFileToMetamap(
          file,
          flowID,
          storeName,
          storeName === "ineBack" ? getInfoMetamap[1].file : null
        );
      }
    } catch (e) {
      TagManager.dataLayer(
        BaseMD.MD3C(errorDocument, currentSection, text, officialData)
      );
      dispatch(addErrorMessageToDocuments(payloadErrorMessage));
    }
    return null;
  };

  const handleOnClick = () => {
    TagManager.dataLayer(BaseMD.MD3E(true, title));
    dispatch(deleteItemByName({ name: storeName }));
    const input = document.createElement("input");

    input.type = "file";
    input.accept = "image/*, application/pdf";
    // file validations
    input.onchange = async (e) => {
      const file = e.target.files[0];
      // run every validation for the given file
      const validations = val.map((validation) => validation(file, onlyPdf));

      const validationsDoesntHaveErrorMessages = validations.every(
        (res) => typeof res !== "string"
      );

      // TODO: Revisar si el flowID sigue siendo necesario
      const fi = flowsId[storeName];

      if (validationsDoesntHaveErrorMessages) {
        // store in documents
        // send file to metamap

        let documentId;
        if (storeName !== "ineFront") {
          const respSendFile = await executeSendFile(file, fi);

          documentId = respSendFile.id;
          const identity = respSendFile.identity;

          // envio de mendsaje websocket
          onSendMessage(
            JSON.stringify({
              id_verification: documentId,

              // TODO: Flujo de envio de archivos a metamap
              email_user: localStorage.getItem("email"),
              id_tipo_archivo: getIdDocumentType(storeName),
            })
          );

          // carga de info en redux InfoMetamap
          const infoMetamap = new InfoMetaMap(
            fi,
            identity,
            file.name,
            documentId
          );

          const payloadInfoMetamap = {
            name: `infoMetamap${storeName}`,
            file,
            value: {
              infoMetamap,
            },
          };

          dispatch(updateMetamapFileUploadData(payloadInfoMetamap));

          const payload = {
            name: storeName,
            flowId: fi,
            verificationId: documentId,
            sendThisWhenSendInfoByFirstTime: true,
            value: {
              status: "pending",
              errors: null,
              name: file.name,
              file,
              fileName: file.name,
            },
          };

          dispatch(updateInputMetamap(payload));
        } else {
          const payload = {
            name: storeName,
            flowId: fi,
            verificationId: "N/A",
            sendThisWhenSendInfoByFirstTime: true,
            value: {
              status: "pending",
              errors: null,
              name: file.name,
              file,
              fileName: file.name,
            },
          };

          dispatch(updateInputMetamap(payload));
        }
      } else {
        const payload = {
          name: storeName,
          flowId: fi,
          verificationId: "N/A",
          sendThisWhenSendInfoByFirstTime: true,
          value: {
            status: "rejected",
            errors: [...validations],
            // name: file.name,
            // file,
          },
        };
        dispatch(updateInputMetamap(payload));
      }

      // borrar desopues
      const infoMetamap = new InfoMetaMap(
        fi,
        "identityN/A",
        file.name,
        "documentIdN/A"
      );

      const payloadInfoMetamap = {
        name: `infoMetamap${storeName}`,
        file,
        value: {
          infoMetamap,
        },
      };

      dispatch(updateMetamapFileUploadData(payloadInfoMetamap));
    };
    input.click();
  };

  return (
    <LightBlueBtn
      handleOnClick={handleOnClick}
      text={text}
      extraClass={className}
    />
  );
}

BlueBtnFileWithValidations.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
  storeName: PropTypes.string.isRequired,
  onlyPdf: PropTypes.bool,
};

BlueBtnFileWithValidations.defaultProps = {
  className: "",
  onlyPdf: false,
};
