import axios from "axios";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import TagManager from "react-gtm-module";
import { useDispatch } from "react-redux";
import BaseMD from "../../google-analytics/events/baseMD";
import {
  addErrorMessageToDocuments,
  updateInputLogo,
  updateInputMetamap,
} from "../../redux-modules/form/actions";
import validateFileSize from "../../scripts/validations/validateFileSize";
import validateImgDimensions from "../../scripts/validations/validateImgDimensions";
import validatePNG from "../../scripts/validations/validatePNG";
import BlueBtn from "../BlueBtn/BlueBtn";

export default function BlueBtnLogo({ text, className, storeName }) {
  const dispatch = useDispatch();
  const [logo, setLogo] = useState(null);

  // async validation of width and height of the image
  // this runs after other validations because it is async
  useEffect(() => {
    if (logo) {
      const validate = async () => {
        const errors = await validateImgDimensions(logo);

        if (errors) {
          const payload = {
            name: storeName,
            value: {
              errors: [errors],
            },
          };
          dispatch(addErrorMessageToDocuments(payload));
        } else {
          const formData = new FormData();
          formData.append("file", logo);

          axios
            .post(
              `${process.env.REACT_APP_UPLOAD_DOCUMENT_URL}/logo`,
              formData,
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
              }
            )
            .then((res) => {
              const payload = {
                name: storeName,
                sendThisWhenSendInfoByFirstTime: true,
                value: {
                  url: "URL se sobrescribira en el backend",
                },
              };
              dispatch(updateInputLogo(payload));
            })
            .catch((err) => {
              throw new Error(err);
            });
        }
      };

      validate();
    }
  }, [logo]);

  // general validations

  const handleOnClick = () => {
    TagManager.dataLayer(BaseMD.MD3E(true, "Logo"));

    const input = document.createElement("input");

    input.type = "file";
    input.accept = "image/*, application/pdf";
    // file validations
    input.onchange = async (e) => {
      const file = e.target.files[0];

      // run every validation for the given file
      const validations = [validateFileSize(file), validatePNG(file)];

      const validationsDoesntHaveErrorMessages = validations.every(
        (res) => typeof res !== "string"
      );

      if (validationsDoesntHaveErrorMessages) {
        const payload = {
          name: storeName,
          flowId: "N/A",
          verificationId: "N/A",
          sendThisWhenSendInfoByFirstTime: true,
          value: {
            status: "verified",
            errors: null,
            name: file.name,
            file,
            fileName: file.name,
          },
        };
        dispatch(updateInputMetamap(payload));
        setLogo(file);
      } else {
        const payload = {
          name: storeName,
          flowId: "N/A",
          verificationId: "N/A",
          sendThisWhenSendInfoByFirstTime: true,
          value: {
            status: "rejected",
            errors: validations,
            name: file.name,
          },
        };
        dispatch(updateInputMetamap(payload));
      }
    };
    input.click();
  };

  return (
    <BlueBtn
      handleOnClickBlueBtn={handleOnClick}
      text={text}
      extraClass={className}
    />
  );
}

BlueBtnLogo.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
  storeName: PropTypes.string.isRequired,
};

BlueBtnLogo.defaultProps = {
  className: "",
};
