

export const getRegimen = (persona) => {
    if (persona === "Moral") {
        return [
            {
                name: "General de Ley Personas Morales",
                id: 601,
            },

            {
                name: "Personas Morales con Fines no Lucrativos",
                id: 603,
            },

            {
                name: "Residentes en el Extranjero sin Establecimiento Permanente en México",
                id: 610,
            },

            {
                name: "Sociedades Cooperativas de Producción que optan por diferir sus ingresos",
                id: 620,
            },

            {
                name: "Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras",
                id: 622,
            },

            {
                name: "Opcional para Grupos de Sociedades",
                id: 623,
            },

            {
                name: "Coordinados",
                id: 624,
            },

            {
                name: "Régimen Simplificado de Confianza",
                id: 626,
            }
        ]
    }

    if (persona === "Fisica") {
        return [

            {
                name: "Personas Fisicas con Actividades Empresariales y Profesionales",
                id: 612,
            },

            {
                name: "Sueldos y Salarios e Ingresos Asimilados a Salarios",
                id: 605,
            },

            {
                name: "Arrendamiento",
                id: 606,
            },

            {
                name: "Régimen de Enajenación o Adquisición de Bienes",
                id: 607,
            },

            {
                name: "Demás ingresos",
                id: 608,
            },

            {
                name: "Residentes en el Extranjero sin Establecimiento Permanente en México",
                id: 610,
            },

            {
                name: "Ingresos por Dividendos (socios y accionistas)",
                id: 611,
            },

            {
                name: "Ingresos por intereses",
                id: 614,
            },

            {
                name: "Régimen de los ingresos por obtención de premios",
                id: 615,
            },

            {
                name: "Sin obligaciones fiscales",
                id: 616,
            },

            {
                name: "Incorporación Fiscal",
                id: 621,
            },

            {
                name: "Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas",
                id: 625,
            },

            {
                name: "Régimen Simplificado de Confianza",
                id: 626,
            }
        ]
    }
}

