import PropTypes from "prop-types";
import React from "react";
import TagManager from "react-gtm-module";
import { useDispatch, useSelector } from "react-redux";
import BaseMD from "../../google-analytics/events/baseMD";
import {
  addErrorMessageToDocuments,
  nextSection,
} from "../../redux-modules/form/actions";
import validateDocuments from "../../scripts/validateDocuments";
import BlueBtn from "../BlueBtn/BlueBtn";
import saveState from "../../scripts/saveState";

export default function BlueBtnForDocuments({
  extraClass,
  currentStep,
  currentPosition,
  setCurrentStep,
  pathINE,
  pathPassport,
  pathReupload,
  handleFinalStep,
}) {
  let name = "";
  const documents = useSelector((state) => state.documents);
  const estado = useSelector((state) => state);

  const userTypeOfIdentificationSelected =
    documents.find((i) => i.name === "identificationSelection")?.value
      .selection || "INE";

  if (userTypeOfIdentificationSelected === "INE") {
    name = pathINE[currentStep];
  } else {
    name = pathPassport[currentStep];
  }

  const dispatch = useDispatch();

  const handleOnClick = () => {
    let pageName = "";
    let option = "";
    switch (currentPosition.name) {
      case "CSF":
        pageName = "Sube la Constancia de Situación Fiscal";
        break;
      case "identificationSelection":
        pageName = "Selecciona un documento de identificación";
        if (userTypeOfIdentificationSelected === "INE") {
          option = " - Credencial de elector";
        } else {
          option = " - Pasaporte";
        }
        break;
      case "ineFront":
        pageName = "Sube la parte delantera de tu credencial de elector";
        break;
      case "ineBack":
        pageName = "Sube el reverso de tu credencial de elector";
        break;
      case "passport":
        pageName = "Pasaporte";
        break;
      case "bankStatement":
        pageName = "Sube tu estado de cuenta";
        break;
      case "logo":
        pageName = "Logo";
        break;
      case "success":
        pageName = "Validamos tus documentos";
        break;
      default:
        pageName = "Error";
    }
    TagManager.dataLayer(BaseMD.MD3A(true, pageName, option));

    if (currentPosition.name === "success") {
      saveState(estado);
      dispatch(nextSection());
    }

    if (currentStep >= 0 && currentStep < currentPosition.maxLength) {
      // validations are required if missing then file is null
      if (
        validateDocuments(
          documents,
          currentPosition.type,
          pathINE,
          pathPassport,
          pathReupload,
          currentStep
        )
      ) {
        const thisIsTheFinalStep =
          currentStep === currentPosition.maxLength - 1;

        if (thisIsTheFinalStep) {
          handleFinalStep();
        } else {
          setCurrentStep(currentStep + 1);
        }
      } else {
        const payload = {
          name,
          value: {
            errors: ["El archivo es requerido"],
          },
        };

        dispatch(addErrorMessageToDocuments(payload));
      }
    }
  };

  return (
    <BlueBtn
      text="Continuar"
      extraClass={extraClass}
      handleOnClickBlueBtn={handleOnClick}
    />
  );
}

BlueBtnForDocuments.propTypes = {
  extraClass: PropTypes.string,
  currentStep: PropTypes.number.isRequired,
  currentPosition: PropTypes.shape({
    maxLength: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  setCurrentStep: PropTypes.func.isRequired,
  pathINE: PropTypes.arrayOf(PropTypes.string).isRequired,
  pathPassport: PropTypes.arrayOf(PropTypes.string).isRequired,
  pathReupload: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleFinalStep: PropTypes.func.isRequired,
};

BlueBtnForDocuments.defaultProps = {
  extraClass: "",
};
