import React from 'react'
import cerrarIcon from "../assets/cerrar.svg";
import successIcon from "../assets/success.svg";
import styles from "./SuccessModal.module.sass";

const SuccessModal = ({ children, toggle, tittle }) => {
    const s = styles;
  return (
    <>
      <div>
        <div>
          <button className={s.closeBtn} onClick={toggle}>
            <img src={cerrarIcon} alt="" />
          </button>
        </div>
        <div className={s.header}>
          <img src={successIcon} alt="" />
        </div>
        <h2 className={s.tittle}>{tittle}</h2>
        <div className={s.children}>{children}</div>
      </div>
    </>
  );
}

export default SuccessModal