import React from "react";
import PropTypes from "prop-types";
import styles from "./LightBlueBtn.module.sass";

export default function LightBlueBtn({ text, handleOnClick, extraClass }) {
  const s = styles;
  return (
    <button
      type="button"
      className={`${s.btnLight} ${extraClass}`}
      onClick={handleOnClick}
    >
      {text}
    </button>
  );
}

LightBlueBtn.propTypes = {
  text: PropTypes.string.isRequired,
  handleOnClick: PropTypes.func,
  extraClass: PropTypes.string,
};

LightBlueBtn.defaultProps = {
  handleOnClick: () => {},
  extraClass: "",
};
