/**
 * @description
 * Util para obtener el mapa de datos de los socios y administradores
 * @param {Object} officialData
 * @returns
 */
function getSociosAdministrador(officialData) {
  if (officialData === undefined) return {};
  function getOfficialData(key) {
    return officialData.find((item) => item.name === key);
  }
  const hasMoreAssociates = getOfficialData("associates")?.value === "Sí";
  const hasMoreAdministrators =
    getOfficialData("administrators")?.value === "Sí";

  return {
    sociosAdministrador: `${hasMoreAssociates ? "1" : "0"}|${hasMoreAdministrators ? "1" : "0"
      }`,
  };
}

/**
 *  @description
 *  1 = Persona Moral
 *  2 = Persona Física
 * @param {int} typeOfPersonId
 * @returns
 */
function getTypeOfPerson(typeOfPersonId) {
  if (typeOfPersonId === undefined) return "";
  return typeOfPersonId === 1 ? "Persona Moral" : "Persona Física";
}

export function getAfiliateID() {
  return { idAfiliado: localStorage.getItem("idAfiliado") || "N/A" };
}
export default class BaseMD {
  static welcomeTitle = "Afiliate Coppel Pay - Inicia tu solicitud";

  static registerTitle = "Afiliate Coppel Pay - Registrate";

  static documentsTitle = "Afiliate Coppel Pay - Documentos";

  static contactsTitle = "Afiliate Coppel Pay - Contactos";

  static officialDataTitle = "Afiliate Coppel Pay - Datos oficiales";

  static companyDataTitle = "Afiliate Coppel Pay - Datos de la empresa";

  static commercialInfoTitle = "Afiliate Coppel Pay - Información comercial";

  static confirmationTitle = "Afiliate Coppel Pay - Recibimos tu solicitud";

  static moralPerson = "Persona moral";

  static physicalPerson = "Persona física";

  static login = "Inicia sesión para dar de alta tu negocio";

  static register = "Registrate llenando los siguientes campos";

  static eventClic = "clicLandingAfiliateCPGeneral";

  static afiliateCoppelPayTitle = "Afiliate Coppel Pay";

  static restablecerContrasena = "Restablecer contraseña";

  static clicLandingAfiliateCPGeneral = "clicLandingAfiliateCPGeneral";

  /**
   * @description
   * Medición para el formuario paso 2
   *
   */
  static MD2() {
    return {
      dataLayer: {
        page: "/afiliate-cp/inicia-solicitud",
        title: this.welcomeTitle,
        ...getAfiliateID(),
        event: "pvAfiliateCPSolicitud",
      },
    };
  }
  /**
   * @description
   * Medición para el formuario paso 2-A1
   * @types "<<Logo Coppel Pay|Logo Coppel.com|Soy cliente>>"
   */

  static MD2A1(type) {
    return {
      dataLayer: {
        nd1: this.welcomeTitle,
        nd2: type,
        nd3: "",
        nd4: "",
        ...getAfiliateID(),
        event: "clicLandingAfiliateCPGeneral",
      },
    };
  }
  /**
   * @description
   * Medición para el formuario paso 2-A
   *
   */

  static MD2A() {
    return {
      dataLayer: {
        nd1: this.welcomeTitle,
        nd2: "Términos y condiciones",
        nd3: "",
        nd4: "",
        ...getAfiliateID(),
        event: "clicLandingAfiliateCPGeneral",
      },
    };
  }
  /**
   * @description
   * Medición para el formuario paso 2B
   */

  static MD2B(type) {
    // types: "<<Términos y condiciones|Documentos legales|reCaptcha>>",
    return {
      dataLayer: {
        nd1: this.welcomeTitle,
        nd2: type,
        nd3: "Mensaje: Marca la casilla para continuar",
        nd4: "",
        ...getAfiliateID(),
        event: "clicLandingAfiliateCPGeneral",
      },
    };
  }
  /**
   * @description
   * Medición para el formuario paso 2C
   * @type: "<<Términos y condiciones|Documentos legales|reCaptcha>>",
   */

  static MD2C(type) {
    return {
      dataLayer: {
        nd1: this.welcomeTitle,
        nd2: type,
        nd3: "Selección de checkbox",
        nd4: "",
        ...getAfiliateID(),
        event: "clicLandingAfiliateCPGeneral",
      },
    };
  }
  /**
   * @description
   * Medición para el formuario paso 2D
   */

  static MD2D() {
    return {
      dataLayer: {
        nd1: this.welcomeTitle,
        nd2: this.login,
        nd3: "Registrate",
        nd4: "",
        ...getAfiliateID(),
        event: this.eventClic,
      },
    };
  }

  static MD4DWelcome() {
    return {
      dataLayer: {
        nd1: this.registerTitle,
        nd2: this.register,
        nd3: "Inicia sesión",
        nd4: "",
        ...getAfiliateID(),
        event: this.eventClic,
      },
    };
  }

  /**
   * @description
   * Medición para el formuario para los pasos:
   *  2E, 3D
   * @param {string} type es el nombre de la pagina actual
   * @type "<<Aviso de Privacidad|Términos y condiciones|¿Tienes dudas? Contáctanos>>",
   * @returns
   */
  static MD2E(type, linkName, officialData, typeOfPersonId = undefined) {
    let localTitle = "";
    switch (linkName) {
      case "welcome":
        localTitle = this.welcomeTitle;
        break;
      case "documents":
        localTitle = this.documentsTitle;
        break;
      case "contact":
        localTitle = this.contactsTitle;
        break;
      case "officialData":
        localTitle = this.officialDataTitle;
        break;
      case "companyData":
        localTitle = this.companyDataTitle;
        break;
      case "commercialInfo":
        localTitle = this.commercialInfoTitle;
        break;
      case "confirmation":
        localTitle = this.confirmationTitle;
        break;
      default:
        localTitle = "error";
    }
    let typeOfPerson = getTypeOfPerson(typeOfPersonId);
    typeOfPerson =
      getTypeOfPerson(typeOfPersonId) !== ""
        ? `${getTypeOfPerson(typeOfPersonId)} - `
        : "";
    return {
      dataLayer: {
        nd1: localTitle,
        nd2: `${typeOfPerson}Menú inferior`,
        nd3: type,
        nd4: "",
        ...getSociosAdministrador(officialData),
        ...getAfiliateID(),
        event: "clicLandingAfiliateCPGeneral",
      },
    };
  }
  // --- DOCUMENTS ---

  static MD3(officialData, typeOfPersonId, msg) {
    if (msg !== undefined) msg = ` - ${msg}`;
    else msg = "";
    return {
      dataLayer: {
        page: `/afiliate-cp/documentos${msg}`,
        title: `${this.documentsTitle + msg}`,
        event: "pvLandingAfiliateCPProceso",
        ...getSociosAdministrador(officialData),
        ...getAfiliateID(),
        tipoPersona: getTypeOfPerson(typeOfPersonId),
      },
    };
  }

  /**
   * @description
   *  Medicion para el paso 3-A, 3-B, recibe un booleano que indica si se regresa a la seccion anterior o no
   *
   * case true
   * nd2: "Continuar",
   *
   * case false
   * nd2: "Regresar",
   * @param {boolean} option
   * @param {string} linkName
   * @returns {Object}
   *
   */
  static MD3A(option, linkName, msg = "") {
    let localSectionName = "";
    switch (linkName) {
      case "welcome":
        localSectionName = "Welcome";
        break;
      case "documents":
        localSectionName = "Documentos";
        break;
      case "contact":
        localSectionName = "Contactos";
        break;
      case "officialData":
        localSectionName = "Datos oficiales";
        break;
      case "companyData":
        localSectionName = "Datos de la empresa";
        break;
      case "commercialInfo":
        localSectionName = "Información comercial";
        break;
      case "confirmation":
        localSectionName = "Recibimos tu solicitud";
        break;
      default:
        localSectionName = linkName; // En caso de que sea otra pestaña se le pasa el nombre por parametro
    }
    return {
      dataLayer: {
        nd1: `${this.documentsTitle}`,
        nd2: `${localSectionName}  ${msg}`,
        nd3: `${option ? "Continuar" : "Regresar"}`,
        nd4: "",
        ...getAfiliateID(),
        event: "clicLandingAfiliateCPGeneral",
      },
    };
  }

  static MD3B() {
    return {
      dataLayer: {
        nd1: `${this.documentsTitle}`,
        nd2: ``,
        nd3: "Enviar solicitud",
        nd4: "",
        ...getAfiliateID(),
        event: "clicAfiliateCPDatosEmpresaGeneral",
      },
    };
  }

  /**
   * @description
   * Medicion para el paso 3C, 4A, 5K
   *
   * Sirve para medir los errores que se muestran abajo de los documentos
   *
   * @param {string} error es el mensaje de error que se muestra
   * @param {string} linkName pagina actual
   * @returns {Object}
   */
  static MD3C(
    error,
    linkName,
    inputTitle,
    officialData,
    typeOfPersonId = undefined
  ) {
    let localTitle = "";
    switch (linkName) {
      case "welcome":
        localTitle = this.welcomeTitle;
        break;
      case "documents":
        localTitle = this.documentsTitle;
        break;
      case "contact":
        localTitle = this.contactsTitle;
        break;
      case "officialData":
        localTitle = this.officialDataTitle;
        break;
      case "companyData":
        localTitle = this.companyDataTitle;
        break;
      case "commercialInfo":
        localTitle = this.commercialInfoTitle;
        break;
      case "confirmation":
        localTitle = this.confirmationTitle;
        break;
      default:
        localTitle = "error";
    }
    let typeOfPerson = getTypeOfPerson(typeOfPersonId);
    typeOfPerson =
      getTypeOfPerson(typeOfPersonId) !== ""
        ? `${getTypeOfPerson(typeOfPersonId)} - `
        : "";
    return {
      dataLayer: {
        nd1: `${localTitle}`,
        nd2: `${typeOfPerson}Mensaje: ${error}`,
        nd3: inputTitle,
        nd4: "",
        ...getAfiliateID(),
        ...getSociosAdministrador(officialData),
        event: "clicAfiliateCPDatosEmpresaGeneral",
      },
    };
  }

  /**
   *
   * @param {boolean} option
   * @param {string} inputName
   * @returns
   */
  static MD3E(option, inputName) {
    return {
      dataLayer: {
        nd1: `${this.documentsTitle}`,
        nd2: inputName,
        nd3: option ? "Subir archivo" : "Quitar archivo",
        nd4: "",
        event: "clicLandingAfiliateCPGeneral",
      },
    };
  }

  // --- ModalInfo ---

  static MD3Modal() {
    return {
      dataLayer: {
        nd1: this.welcomeTitle,
        nd2: "Modal: Antes de comenzar toma en cuenta",
        nd3: "Llegada",
        nd4: "",
        ...getAfiliateID(),
        event: "clicLandingAfiliateCPGeneral",
      },
    };
  }

  static MD3AModal(action) {
    return {
      dataLayer: {
        nd1: this.welcomeTitle,
        nd2: "Modal: Antes de comenzar toma en cuenta",
        nd3: action,
        nd4: "",
        ...getAfiliateID(),
        event: "clicLandingAfiliateCPGeneral",
      },
    };
  }

  // --- CONTACTS ---

  static MD4(officialData, typeOfPersonId) {
    return {
      dataLayer: {
        page: "/afiliate-cp/contactos",
        title: this.contactsTitle,
        presolicitudId: "",
        event: "pvLandingAfiliateCPProceso",
        ...getSociosAdministrador(officialData),
        ...getAfiliateID(),
        tipoPersona: getTypeOfPerson(typeOfPersonId),
      },
    };
  }

  static MD4B() {
    return {
      dataLayer: {
        nd1: this.contactsTitle,
        nd2: "Regresar al inicio",
        nd3: "",
        nd4: "",
        ...getAfiliateID(),
        event: "clicLandingAfiliateCPGeneral",
      },
    };
  }

  static MD4C() {
    return {
      dataLayer: {
        nd1: this.contactsTitle,
        nd2: "Continuar",
        nd3: "",
        nd4: "",
        ...getAfiliateID(),
        event: "clicLandingAfiliateCPGeneral",
      },
    };
  }

  // -- LEAD REGISTER --
  static MD4Register() {
    return {
      dataLayer: {
        page: "/afiliate-cp/registrate",
        title: this.registerTitle,
        event: "pvAfiliateCPSolicitud",
        ...getAfiliateID(),
      },
    };
  }

  static MD4ARegister(flagSelectedButton) {
    return {
      dataLayer: {
        nd1: this.registerTitle,
        nd2: this.register,
        nd3: flagSelectedButton ? "Ver contraseña" : "Ocultar contraseña",
        nd4: "",
        ...getAfiliateID(),
        event: "clicLandingAfiliateCPGeneral",
      },
    };
  }

  static MD4BRegister(chackboxSelected) {
    return {
      dataLayer: {
        nd1: this.registerTitle,
        nd2: chackboxSelected,
        nd3: "Selección de checkbox",
        nd4: "",
        ...getAfiliateID(),
        event: "clicLandingAfiliateCPGeneral",
      },
    };
  }

  static MD4CRegister() {
    return {
      dataLayer: {
        nd1: this.registerTitle,
        nd2: this.register,
        nd3: "Continuar",
        nd4: "",
        ...getAfiliateID(),
        event: "clicLandingAfiliateCPGeneral",
      },
    };
  }

  // --- OFFICIAL DATA ---

  static DM5(officialData, typeOfPersonId) {
    return {
      dataLayer: {
        page: "/afiliate-cp/datos-oficiales",
        title: "Afiliate coppel pay - Datos Oficiales",
        event: "pvLandingAfiliateCPProceso",
        ...getSociosAdministrador(officialData),
        ...getAfiliateID(),
        tipoPersona: getTypeOfPerson(typeOfPersonId),
      },
    };
  }

  static DM5A() {
    return {
      dataLayer: {
        nd1: this.officialDataTitle,
        nd2: "Selección de input: Persona moral",
        nd3: "",
        nd4: "",
        ...getAfiliateID(),
        event: "clicLandingAfiliateCPGeneral",
      },
    };
  }

  static DM5B() {
    return {
      dataLayer: {
        nd1: this.officialDataTitle,
        nd2: "Selección de input: Persona física con actividad empresarial",
        nd3: "",
        nd4: "",
        ...getAfiliateID(),
        event: "clicLandingAfiliateCPGeneral",
      },
    };
  }

  static DM5C(typeOfPersonId = undefined) {
    if (typeOfPersonId === undefined)
      return {
        dataLayer: {
          nd1: this.officialDataTitle,
          nd2: `Regresar a contactos`,
          nd3: "",
          nd4: "",
          ...getAfiliateID(),
          event: "clicLandingAfiliateCPGeneral",
        },
      };

    return {
      dataLayer: {
        nd1: this.officialDataTitle,
        nd2: getTypeOfPerson(typeOfPersonId),
        nd3: "Regresar a contactos",
        nd4: "",
        ...getAfiliateID(),
        event: "clicLandingAfiliateCPGeneral",
      },
    };
  }

  /**
   * @description
   * Medición para el paso 5D es para medir si tiene más socios
   *
   * @param {boolean} hasMoreAssociates
   * @returns {Object}
   */

  static DM5D(hasMoreAssociates) {
    return {
      dataLayer: {
        nd1: this.officialDataTitle,
        nd2: this.moralPerson,
        nd3: `¿La empresa cuenta con más socios? - ${hasMoreAssociates ? "Si" : "No"
          }`,
        nd4: "",
        ...getAfiliateID(),
        event: "clicLandingAfiliateCPGeneral",
      },
    };
  }

  static DM5E(hasMoreAdmins) {
    return {
      dataLayer: {
        nd1: this.officialDataTitle,
        nd2: this.moralPerson,
        nd3: `¿La empresa cuenta con más administradores? - ${hasMoreAdmins ? "Si" : "No"
          }`,
        nd4: "",
        ...getAfiliateID(),
        event: "clicLandingAfiliateCPGeneral",
      },
    };
  }

  static DM5F() {
    return {
      dataLayer: {
        nd1: this.officialDataTitle,
        nd2: this.moralPerson,
        nd3: "¿La empresa cuenta con más socios? - Añadir socio",
        nd4: "",
        ...getAfiliateID(),
        event: "clicLandingAfiliateCPGeneral",
      },
    };
  }

  static DM5G() {
    return {
      dataLayer: {
        nd1: this.officialDataTitle,
        nd2: this.moralPerson,
        nd3: "¿La empresa cuenta con más socios? - Quitar socio",
        nd4: "",
        ...getAfiliateID(),
        event: "clicLandingAfiliateCPGeneral",
      },
    };
  }

  static DM5H() {
    return {
      dataLayer: {
        nd1: this.officialDataTitle,
        nd2: this.moralPerson,
        nd3: "¿La empresa cuenta con más administradores? - Añadir administrador",
        nd4: "",
        ...getAfiliateID(),
        event: "clicLandingAfiliateCPGeneral",
      },
    };
  }

  static DM5I() {
    return {
      dataLayer: {
        nd1: this.officialDataTitle,
        nd2: this.moralPerson,
        nd3: "¿La empresa cuenta con más administradores? - Quitar administrador",
        nd4: "",
        ...getAfiliateID(),
        event: "clicLandingAfiliateCPGeneral",
      },
    };
  }
  /**
   *@description
   * Medición para el paso 5J
   *
   * @param {Object} officialData
   * @param {int} typeOfPersonId
   * @returns
   */

  static DM5J(officialData, typeOfPersonId = undefined) {
    return {
      dataLayer: {
        nd1: this.officialDataTitle,
        nd2: getTypeOfPerson(typeOfPersonId),
        nd3: "Continuar",
        nd4: "",
        ...getSociosAdministrador(officialData),
        ...getAfiliateID(),
        event: "clicAfiliateCPDatosEmpresaGeneral",
      },
    };
  }

  static DM5L(officialData, typeOfPersonId = undefined) {
    return {
      dataLayer: {
        nd1: this.officialDataTitle,
        nd2: getTypeOfPerson(typeOfPersonId),
        nd3: "Buscar código postal",
        nd4: "",
        ...getSociosAdministrador(officialData),
        ...getAfiliateID(),
        event: "clicLandingAfiliateCPGeneral",
      },
    };
  }


  // --- UPDATE CLV ---

  static MD6UpdatePass() {
    return {
      dataLayer: {
        page: "/afiliate-cp/nueva-contraseña",
        title: "Afiliate coppel pay - Nueva contraseña",
        ...getAfiliateID(),
        event: "pvAfiliateCPSolicitud",
      },
    };
  }

  static MD6AUpdatePass() {
    return {
      dataLayer: {
        nd1: this.afiliateCoppelPayTitle,
        nd2: "Ingresa nueva contraseña",
        nd3: "Ir al inicio",
        nd4: "",
        ...getAfiliateID(),
        event: this.clicLandingAfiliateCPGeneral,
      },
    };
  }

  static MD6BUpdatePass(flagSelectedButton) {
    return {
      dataLayer: {
        nd1: this.afiliateCoppelPayTitle,
        nd2: "Ingresa nueva contraseña",
        nd3: flagSelectedButton ? "Ver contraseña" : "Ocultar contraseña",
        nd4: "",
        ...getAfiliateID(),
        event: this.clicLandingAfiliateCPGeneral,
      },
    };
  }

  static MD6CUpdatePass() {
    return {
      dataLayer: {
        nd1: this.afiliateCoppelPayTitle,
        nd2: "Ingresa nueva contraseña",
        nd3: "Cambiar contraseña",
        nd4: "",
        ...getAfiliateID(),
        event: this.clicLandingAfiliateCPGeneral,
      },
    };
  }




  // ---- 6 COMPANY DATA ----

  static MD6(officialData, typeOfPersonId) {
    return {
      dataLayer: {
        page: "/afiliate-cp/datos-de-la-empresa",
        title: "Afiliate coppel pay - Datos de la empresa",
        ...getSociosAdministrador(officialData),
        ...getAfiliateID(),
        tipoPersona: getTypeOfPerson(typeOfPersonId),
        event: "pvLandingAfiliateCPProceso",
      },
    };
  }

  static MD6C(officialData, typeOfPersonId) {
    return {
      dataLayer: {
        nd1: this.companyDataTitle,
        nd2: getTypeOfPerson(typeOfPersonId),
        nd3: "Regresar a datos oficiales",
        nd4: "",
        ...getAfiliateID(),
        ...getSociosAdministrador(officialData),
        event: "clicAfiliateCPDatosEmpresaGeneral",
      },
    };
  }

  static MD6D(officialData, typeOfPersonId) {
    return {
      dataLayer: {
        nd1: this.companyDataTitle,
        nd2: getTypeOfPerson(typeOfPersonId),
        nd3: "Continuar",
        nd4: "",
        ...getAfiliateID(),
        ...getSociosAdministrador(officialData),
        event: "clicAfiliateCPDatosEmpresaGeneral",
      },
    };
  }

  // ----  MODAL UPDATEPASSWORD ----

  static MD7ModalUpPass(modalName) {
    return {
      dataLayer: {
        nd1: this.afiliateCoppelPayTitle,
        nd2: "Ingresa nueva contraseña",
        nd3: modalName + " - Llegada",
        nd4: "",
        ...getAfiliateID(),
        event: this.clicLandingAfiliateCPGeneral,
      },
    };
  }

  static MD7AModalUpPass(modalName) {
    return {
      dataLayer: {
        nd1: this.afiliateCoppelPayTitle,
        nd2: "Ingresa nueva contraseña",
        nd3: modalName,
        nd4: "",
        ...getAfiliateID(),
        event: this.clicLandingAfiliateCPGeneral,
      },
    };
  }





  // ---- 7 COMMERCIAL INFO ----

  static MD7(officialData, typeOfPersonId) {
    return {
      dataLayer: {
        page: "/afiliate-cp/informacion-comercial",
        title: this.commercialInfoTitle,
        ...getSociosAdministrador(officialData),
        ...getAfiliateID(),
        tipoPersona: getTypeOfPerson(typeOfPersonId),
        event: "pvLandingAfiliateCPProceso",
      },
    };
  }

  static MD7A(typeOfIntegration, officialData, typeOfPersonId) {
    let typeOfPerson = getTypeOfPerson(typeOfPersonId);
    typeOfPerson =
      getTypeOfPerson(typeOfPersonId) !== ""
        ? `${getTypeOfPerson(typeOfPersonId)} - `
        : "";
    return {
      dataLayer: {
        nd1: this.commercialInfoTitle,
        nd2: `${typeOfPerson}Selección de checkbox`,
        nd3: typeOfIntegration,
        nd4: "",
        ...getSociosAdministrador(officialData),
        ...getAfiliateID(),
        event: "clicAfiliateCPDatosEmpresaGeneral",
      },
    };
  }

  static MD7B(officialData, msg, typeOfPersonId = undefined) {
    let typeOfPerson = getTypeOfPerson(typeOfPersonId);
    typeOfPerson =
      getTypeOfPerson(typeOfPersonId) !== ""
        ? `${getTypeOfPerson(typeOfPersonId)} - `
        : "";
    return {
      dataLayer: {
        nd1: this.commercialInfoTitle,
        nd2: `${typeOfPerson}Selección de checkbox`,
        nd3: `Selección de input: ${msg}`,
        nd4: "",
        ...getSociosAdministrador(officialData),
        ...getAfiliateID(),
        event: "clicAfiliateCPDatosEmpresaGeneral",
      },
    };
  }

  static MD7E(officialData, typeOfPersonId = undefined) {
    return {
      dataLayer: {
        nd1: this.commercialInfoTitle,
        nd2: getTypeOfPerson(typeOfPersonId),
        nd3: "Regresar a datos de la empresa",
        nd4: "",
        ...getSociosAdministrador(officialData),
        ...getAfiliateID(),
        event: "clicAfiliateCPDatosEmpresaGeneral",
      },
    };
  }

  static MD7F(officialData, typeOfPersonId = undefined) {
    return {
      dataLayer: {
        nd1: this.commercialInfoTitle,
        nd2: getTypeOfPerson(typeOfPersonId),
        nd3: "Continuar",
        nd4: "",
        ...getSociosAdministrador(officialData),
        ...getAfiliateID(),
        event: "clicAfiliateCPDatosEmpresaGeneral",
      },
    };
  }

  static MD7H(officialData, typeOfPersonId, select, name) {
    return {
      dataLayer: {
        nd1: this.commercialInfoTitle,
        nd2: `${getTypeOfPerson(
          typeOfPersonId
        )} - Selección de opción - ${select}`,
        nd3: name,
        nd4: "",
        ...getSociosAdministrador(officialData),
        ...getAfiliateID(),
        event: "clicAfiliateCPDatosEmpresaGeneral",
      },
    };
  }
  // ---- 8 CONFIRMATION ----

  static MD8(officialData, typeOfPersonId = undefined) {
    return {
      dataLayer: {
        page: "/afiliate-cp/recibimos-tu-solicitud",
        title: this.confirmationTitle,
        ...getSociosAdministrador(officialData),
        ...getAfiliateID(),
        tipoPersona: getTypeOfPerson(typeOfPersonId),
        event: "pvLandingAfiliateCPProceso",
      },
    };
  }

  static MD8A(officialData, typeOfPersonId = undefined) {
    return {
      dataLayer: {
        nd1: this.confirmationTitle,
        nd2: getTypeOfPerson(typeOfPersonId),
        nd3: "Envíanos a un correo de afiliados",
        nd4: "",
        ...getSociosAdministrador(officialData),
        ...getAfiliateID(),
        event: "clicAfiliateCPDatosEmpresaGeneral",
      },
    };
  }

  static MD8B(officialData, typeOfPersonId = undefined) {
    return {
      dataLayer: {
        nd1: this.confirmationTitle,
        nd2: getTypeOfPerson(typeOfPersonId),
        nd3: "Volver a la página principal",
        nd4: "",
        ...getSociosAdministrador(officialData),
        ...getAfiliateID(),
        event: "clicAfiliateCPDatosEmpresaGeneral",
      },
    };
  }

  // ---- 9 RECOVERY CONTRASEÑA----

  static MD5_R() {
    return {
      dataLayer: {
        page: "/afiliate-cp/recuperacion",
        title: "Afiliate coppel pay - Recuperación contraseña",
        ...getAfiliateID(),
        event: "pvAfiliateCPSolicitud",
      },
    };
  }

  static DM5A_R() {
    return {
      dataLayer: {
        nd1: this.afiliateCoppelPayTitle,
        nd2: this.restablecerContrasena,
        nd3: "Ir al inicio",
        nd4: "",
        ...getAfiliateID(),
        event: this.clicLandingAfiliateCPGeneral,
      },
    };
  }

  static DM5B_R() {
    return {
      dataLayer: {
        nd1: this.afiliateCoppelPayTitle,
        nd2: this.restablecerContrasena,
        nd3: "reCaptcha - Selección de checkbox",
        nd4: "",
        ...getAfiliateID(),
        event: this.clicLandingAfiliateCPGeneral,
      },
    };
  }

  static DM5C_R() {
    return {
      dataLayer: {
        nd1: this.afiliateCoppelPayTitle,
        nd2: this.restablecerContrasena,
        nd3: "Enviar",
        nd4: "",
        ...getAfiliateID(),
        event: this.clicLandingAfiliateCPGeneral,
      },
    };
  }

  static DM5D_R(ErrorMsg) {
    return {
      dataLayer: {
        nd1: this.afiliateCoppelPayTitle,
        nd2: this.restablecerContrasena,
        nd3: ErrorMsg,
        nd4: "",
        ...getAfiliateID(),
        event: this.clicLandingAfiliateCPGeneral,
      },
    };
  }

  static DM5E_R() {
    return {
      dataLayer: {
        nd1: this.afiliateCoppelPayTitle,
        nd2: this.restablecerContrasena,
        nd3: "Editar correo",
        nd4: "",
        ...getAfiliateID(),
        event: this.clicLandingAfiliateCPGeneral,
      },
    };
  }

  static DM5F_R() {
    return {
      dataLayer: {
        nd1: this.afiliateCoppelPayTitle,
        nd2: this.restablecerContrasena,
        nd3: "Reenviar correo",
        nd4: "",
        ...getAfiliateID(),
        event: this.clicLandingAfiliateCPGeneral,
      },
    };
  }

  static DM5G_R() {
    return {
      dataLayer: {
        nd1: this.afiliateCoppelPayTitle,
        nd2: this.restablecerContrasena,
        nd3: "Superaste los intentos - Aceptar",
        nd4: "",
        ...getAfiliateID(),
        event: this.clicLandingAfiliateCPGeneral,
      },
    };
  }
}
