import axios from "axios";

export function recoveryClvAPI(params) {
  return axios
    .post(process.env.REACT_APP_RECOVERY_CLV, params)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
