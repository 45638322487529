import PropTypes from "prop-types";

export default function useSetPosition(
  documentsStore,
  stateOfValidations,
  setCurrentPosition,
  setCurrentStep,
  currentStep,
  validatingPath,
  documentsToReUpload,
  pathINE,
  pathPassport
) {
  const userTypeOfIdentificationSelected =
    documentsStore.find((i) => i.name === "identificationSelection")?.value
      .selection || "INE";

  if (stateOfValidations === "validating") {
    setCurrentStep(0);
    setCurrentPosition({
      name: validatingPath[currentStep],
      maxLength: 0,
      type: "validating",
    });
  } else if (stateOfValidations === "success") {
    setCurrentStep(0);
    setCurrentPosition({
      name: "success",
      maxLength: 0,
      type: "success",
    });
  } else if (stateOfValidations === "reupload") {
    setCurrentPosition({
      name: documentsToReUpload[currentStep],
      maxLength: documentsToReUpload.length,
      type: "reupload",
    });
  } else if (userTypeOfIdentificationSelected === "INE") {
    setCurrentPosition({
      name: pathINE[currentStep],
      maxLength: pathINE.length,
      type: "pathINE",
    });
  } else if (userTypeOfIdentificationSelected === "passport") {
    setCurrentPosition({
      name: pathPassport[currentStep],
      maxLength: pathPassport.length,
      type: "pathPassport",
    });
  }

  return true;
}
PropTypes.useSetPosition = {
  documentsStore: PropTypes.array,
  stateOfValidations: PropTypes.string,
  setCurrentPosition: PropTypes.func,
  setCurrentStep: PropTypes.func,
  currentStep: PropTypes.number,
  validatingPath: PropTypes.array,
  documentsToReUpload: PropTypes.array,
  pathINE: PropTypes.array,
  pathPassport: PropTypes.array,
};

PropTypes.useSetPosition.defaultProps = {
  documentsStore: [],
};
