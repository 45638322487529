import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import deleteIcon from "../../../images/icons/deleteIcon.svg";
import {
  updateInputExtra,
  verifyValidationsOfficialData,
} from "../../../redux-modules/form/actions";
import { SelectUI } from "../SelectUI";
import TextInputBase from "../TextInputBase/TextInputBase";
import styles from "./TextInputWithDobleValue.module.sass";

/**
 * This Component is required to to have rfc and fullName inputs in one object in redux store
 * this is the only way to have a single validation for both inputs
 *
 * @param {string} callValidation - Call validation of section
 * @param {string} index - this component is used in a map, this is the index of the map
 * @param {string} onClickDelete - function to delete the current associate or admin inputs
 * @returns {JSX.Element}
 */
export default function TextInputWithDobleValue({
  typeOfInput,
  callValidation,
  index,
  indexRemove = 0,
  onClickDelete,
}) {
  const s = styles;
  const dispatch = useDispatch();

  let inputStateName;

  if (typeOfInput === "associates") {
    inputStateName = `extraAssociate${index}`;
  } else if (typeOfInput === "administrators") {
    inputStateName = `extraAdministrator${index}`;
  }

  const state = useSelector((stat) => stat);

  // save the name in store
  function handleOnChangeName(e) {
    const pay = { name: inputStateName, fullName: e };
    dispatch(updateInputExtra(pay));
  }

  // save the RFC in store
  function handleOnChangeRfc(e) {
    const pay = { name: inputStateName, rfc: e };
    dispatch(updateInputExtra(pay));
  }

  // save the CURP in store
  function handleOnChangeCurp(e) {
    const pay = { name: inputStateName, curp: e };
    dispatch(updateInputExtra(pay));
  }

  // current validations for both inputs
  const [extraInputsValidations, setExtraInputsValidations] = useState({
    rfc: false,
    fullName: false,
    curp: false,
  });
  const personaMoral = "Persona moral";
  // check if the current user is a PFAE
  const [typeOfPerson, setTypeOfPerson] = useState(personaMoral);

  const getTypeOfPersonFromStore = (reduxState) => {
    const currentSection = reduxState.currentSection.name;

    // eslint-disable-next-line array-callback-return
    reduxState[currentSection].map((input) => {
      if (
        input.name === inputStateName &&
        input.value !== undefined &&
        input.value !== ""
      ) {
        setTypeOfPerson(input.value);
      } else {
        setTypeOfPerson(personaMoral);
      }
    });
  };

  useEffect(() => {
    getTypeOfPersonFromStore(state);
  }, []);
  // call this when a value in the inputs change
  function setValidationValues(validationResult) {
    if (validationResult.rfc !== undefined) {
      setExtraInputsValidations((prev) => ({
        ...prev,
        rfc: validationResult.rfc,
      }));
    }

    if (validationResult.fullName !== undefined) {
      setExtraInputsValidations((prev) => ({
        ...prev,
        fullName: validationResult.fullName,
      }));
    }

    if (
      !typeOfPerson.includes("moral") &&
      validationResult.curp !== undefined
    ) {
      setExtraInputsValidations((prev) => ({
        ...prev,
        curp: validationResult.curp,
      }));
    }
  }

  // when value change we set the validation results so this run and update the validation status of the input in the store
  useEffect(() => {
    let validated = false;
    if (extraInputsValidations.rfc && extraInputsValidations.fullName) {
      validated = true;
    }

    if (!typeOfPerson.includes("moral")) {
      validated = extraInputsValidations.curp ? validated : false;
    }

    const pay = {
      name: inputStateName,
      validated,
    };
    dispatch(updateInputExtra(pay));
    dispatch(verifyValidationsOfficialData());
  }, [extraInputsValidations]);

  useEffect(() => {
    // eslint-disable-next-line array-callback-return
    state.officialData.map((input) => {
      if (
        input.name === inputStateName &&
        (input.curp === null || input.curp === undefined || input.curp === "")
      ) {
        if (typeOfPerson.includes("moral")) {
          const pay = { name: inputStateName, curp: "" };
          dispatch(updateInputExtra(pay));
        }

        setExtraInputsValidations((prev) => ({
          ...prev,
          curp: false,
        }));
      }
    });
  }, [typeOfPerson]);

  return (
    <div className={s.newMember}>
      <div className={s.select}>
        <SelectUI
          id={`typeOfPerson${index}`}
          storeName={inputStateName}
          name="Regimen"
          defaultValue={typeOfPerson}
          customRequiredMessage="Elige una opción para continuar"
          options={[
            personaMoral,
            "Persona física con actividades empresariales",
          ]}
          callValidation={callValidation}
          onChange={(e) => {
            setTypeOfPerson(e);
            const payload = { name: inputStateName, value: e };
            dispatch(updateInputExtra(payload));
          }}
          externalValidation
        />
      </div>

      <TextInputBase
        required
        handleOnChange={(e) => {
          handleOnChangeName(e);
        }}
        title="Nombre y apellidos"
        placeholder="Nombre y apellidos"
        storeName={inputStateName}
        callValidation={callValidation}
        inputValidation="fullName"
        setValidationValues={(values) => {
          setValidationValues(values);
        }}
        fullNameInput
      />

      <TextInputBase
        required
        handleOnChange={(e) => {
          handleOnChangeRfc(e);
        }}
        title="RFC"
        placeholder="RFC"
        storeName={inputStateName}
        inputValidation="rfc"
        callValidation={callValidation}
        setValidationValues={(values) => {
          setValidationValues(values);
        }}
        rfcInput
      />

      {!typeOfPerson.includes("moral") && (
        <TextInputBase
          required
          handleOnChange={(e) => {
            handleOnChangeCurp(e);
          }}
          title="CURP"
          placeholder="CURP"
          storeName={inputStateName}
          callValidation={callValidation}
          inputValidation="curp"
          setValidationValues={(values) => {
            setValidationValues(values);
          }}
          curpInput
        />
      )}

      {index <= indexRemove && (
        <div className={s.deleteMember}>
          <img
            src={deleteIcon}
            style={{ visibility: "hidden", width: "36px" }}
            alt="delete"
          />
        </div>
      )}

      {index > indexRemove && (
        <button
          className={s.deleteMember}
          type="button"
          onClick={onClickDelete}
        >
          <img src={deleteIcon} alt="delete" />
        </button>
      )}
    </div>
  );
}

TextInputWithDobleValue.propTypes = {
  typeOfInput: PropTypes.string.isRequired,
  callValidation: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  onClickDelete: PropTypes.func.isRequired,
};
