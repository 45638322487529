import React from "react";
import SockJsClient from "react-stomp";
import { Documents } from "../components/sections/Documents";

class WebSocketClassComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      messages: [],
    };
  }

  sendMessage = (msg) => {
    this.clientRef.sendMessage(
      process.env.REACT_APP_SOCKET_METAMAP_MESSAGE,
      msg
    );
  };

  onMessageReceive = (msg) => {
    this.setState(() => ({
      messages: msg,
    }));
  };

  render() {
    const { messages } = this.state;

    return (
      <div>
        <Documents onSendMessage={this.sendMessage} messages={messages} />

        <SockJsClient
          url={process.env.REACT_APP_SOCKET_METAMAP}
          topics={[process.env.REACT_APP_SOCKET_METAMAP_RESPONSES]}
          onMessage={this.onMessageReceive}
          onConnect={() => {
            const interval = setInterval(() => {
              try {
                this.clientRef.sendMessage("heartbeat");
              } catch (e) {
                clearInterval(interval);
              }
            }, 6000);
          }}
          onDisconnect={() => {}}
          ref={(client) => {
            this.clientRef = client;
          }}
          autoReconnect
          heartbeatOutgoing={6000}
          heartbeatIncoming={1500}
        />
      </div>
    );
  }
}

export default WebSocketClassComponent;
