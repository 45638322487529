import { ConfigProvider as ConfigProvider5, Modal } from "antd-v5";
import React, { useEffect, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import TagManager from "react-gtm-module";
import { connect } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import BaseMD from "../../../google-analytics/events/baseMD";
import arrowLeftIcon from "../../../images/icons/arrow-left.svg";
import { recoveryClvAPI } from "../../../scripts/recoveryClvAPI";
import WelcomeBtn from "../../WelcomeBtn/WelcomeBtn";
import TextInputWelcome from "../../inputs/TextInputWelcome/TextInputWelcome";
import ModalError from "../../modals/ModalError/ModalError";
import styles from "./RecoveryClv.module.sass";
import { RecoveryLanding } from "./RecoveryLanding/RecoveryLanding";

function RecoveryClvComponent() {
  const navigate = useNavigate();
  const s = styles;
  const recaptchaRef = useRef(null);
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });
  const afterRender = useRef(false);

  const [emailHasError, setEmailHasError] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [imNotARobot, setImNotARobot] = useState(false);
  const [showErrors, setShowErrors] = useState(false);

  const [formData, setFormData] = useState({
    email: "",
  });
  const [showModalError, setShowModalError] = useState(false);

  const handleCancel = () => {
    setShowModalError(false);
  };
  const toggleError = () => setShowModalError(!showModalError);

  const inputs = [
    {
      id: "email",
      name: "email",
      placeholder: "Escribe tu correo",
      maxLength: 100,
      inputType: "email",
      label: "Escribe tu correo electrónico",
      errorMsj: "Ingresa una dirección de correo electrónico válida",
      hasEmailError: emailHasError,
      validEmail: isValid,
    },
  ];

  const [wasSent, setWasSent] = useState(false);

  const handleOnChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const typesOfErrors = {
    reCaptcha() {},
  };

  useEffect(() => {
    TagManager.dataLayer(BaseMD.MD5_R());
  }, []);

  useEffect(() => {
    if (afterRender.current && showErrors && !imNotARobot) {
      typesOfErrors.reCaptcha();
    } else {
      afterRender.current = true;
    }
  }, [showErrors, imNotARobot]);

  const handleRecaptcha = () => {
    TagManager.dataLayer(BaseMD.DM5B_R());
    if (recaptchaRef.current.getValue()) {
      setImNotARobot(true);
    } else {
      setImNotARobot(false);
    }
    setShowErrors(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    TagManager.dataLayer(BaseMD.DM5C_R());
    const response = await recoveryClvAPI(formData);

    if (response.status === 200) {
      setWasSent(true);
      setImNotARobot(false);
    } else {
      setImNotARobot(false);
      setShowModalError(true);
      recaptchaRef.current.reset();
    }
  };

  const handleBack = () => {
    TagManager.dataLayer(BaseMD.DM5A_R());
    navigate("/");
  };

  useEffect(() => {
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const isEmailValid = emailRegex.test(formData.email);
    setEmailHasError(!isEmailValid);
    setIsValid(isEmailValid);
  }, [formData.email]);

  useEffect(() => {
    if (recaptchaRef.current.getValue()) {
      setImNotARobot(true);
    } else {
      setImNotARobot(false);
    }
  }, [recaptchaRef]);

  return (
    <>
      <ConfigProvider5 prefixCls="ant5">
        <Modal
          open={showModalError}
          footer={null}
          onCancel={handleCancel}
          centered
          closeIcon={null}
          width={500}
        >
          <ModalError toggle={toggleError} tittle="Coppel Pay se desconectó">
            <p>El sistema falló. Intenta de nuevo.</p>
            <div className={s.modalBtn}>
              <WelcomeBtn
                tittle="Aceptar"
                disabled={false}
                onClick={handleCancel}
              />
            </div>
          </ModalError>
        </Modal>
      </ConfigProvider5>
      <div>
        {wasSent ? (
          <RecoveryLanding formData={formData} handleEditEmail={setWasSent} />
        ) : (
          <div className={s.content}>
            <div className={s.btnRegresarContainer}>
              <button
                type="button"
                className={s.btnRegresar}
                onClick={handleBack}
              >
                <img src={arrowLeftIcon} alt="Ir al inicio" />{" "}
                {isMobile ? "" : "Ir al inicio"}
              </button>
            </div>
            <div className={s.recoveryForm}>
              <form onSubmit={handleSubmit}>
                <img
                  alt="Logo de Coppel Pay."
                  src={process.env.REACT_APP_LOGO_COPPELPAY}
                  width="324"
                  height="154"
                  className={s.imageLogo}
                />
                <h2 className={s.titleRecover}>Restablece tu contraseña</h2>
                {inputs.map((input) => (
                  <TextInputWelcome
                    key={input.id}
                    {...input}
                    value={formData[input.id]}
                    onChange={handleOnChange}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                      }
                    }}
                  />
                ))}
                <div className={s.reCaptchaContainer}>
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey="6Lfk0d8eAAAAAM2w75cCicnMWmEhrUvu72gHNR_2"
                    onChange={() => {
                      handleRecaptcha();
                    }}
                    size="normal"
                  />
                </div>
                <div className={s.btnContainer}>
                  <button
                    type="button"
                    className={`${s.btnEnviar} ${
                      imNotARobot && isValid ? s.active : ""
                    }`}
                    disabled={!imNotARobot || !isValid}
                    onClick={(e) => handleSubmit(e)}
                  >
                    Enviar
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export const RecoveryClv = connect()(RecoveryClvComponent);
