export const VALIDATIONS_COMPLETED_CONTACT = "VALIDATIONS_COMPLETED_CONTACT";

export const VERIFY_VALIDATIONS_COMPLETED_DOCUMENTS =
  "VERIFY_VALIDATIONS_COMPLETED_DOCUMENTS";

export const VERIFY_VALIDATIONS_COMPLETED_COMPANY_DATA =
  "VERIFY_VALIDATIONS_COMPLETED_COMPANY_DATA";

export const VERIFY_VALIDATIONS_OFFICIAL_DATA =
  "VERIFY_VALIDATIONS_OFFICIAL_DATA";

export const NEW_ASSOCIATE_ADDED = "NEW_ASSOCIATE_ADDED";
export const NEW_ASSOCIATE_REMOVED = "NEW_ASSOCIATE_REMOVED";
export const NEW_ADMIN_ADDED = "NEW_ADMIN_ADDED";

export const NEW_ADMIN_REMOVED = "NEW_ADMIN_REMOVED";

export const RESET_ADMIN_COUNT = "RESET_ADMIN_COUNT";

export const VERIFY_VALIDATIONS_COMMERCIAL_INFORMATION =
  "VERIFY_VALIDATIONS_COMMERCIAL_INFORMATION";
export const UPDATE_INPUT_EXTRA = "UPDATE_INPUT_EXTRA";

export const UPDATE_INPUT = "UPDATE_INPUT";
export const UPDATE_INPUT_METAMAP = "UPDATE_INPUT_METAMAP";

export const UPDATE_STEPPER_LOADER = "UPDATE_STEPPER_LOADER";

export const CHANGE_TYPE_OF_PERSON = "CHANGE_TYPE_OF_PERSON";

export const UPDATE_INPUT_STATUS = "UPDATE_INPUT_STATUS";

export const UPDATE_RADIO = "UPDATE_RADIO";

export const NEXT_SECTION = "NEXT_SECTION";

export const LOG_OUT = "LOG_OUT";

export const PREV_SECTION = "PREV_SECTION";

export const CLEAR_DATA = "CLEAR_DATA";

export const CLEAR_POP_ADDRESS = "CLEAR_POP_ADDRESS";

export const POSTAL_CODE_DATA = "POSTAL_CODE_DATA";

export const ADD_ERROR_MESSAGE_TO_DOCUMENTS = "ADD_ERROR_MESSAGE_TO_DOCUMENTS";

export const DELETE_ITEM_BY_NAME = "DELETE_ITEM_BY_NAME";

export const UPDATE_INPUT_LOGO = "UPDATE_INPUT_LOGO";

export const COPY_DATA = "COPY_DATA";

export const CLEAN_STORE = "CLEAN_STORE";

export const LIST_FILE_ERROR = "LIST_FILE_ERROR";

export const REPLACE_STATE = "REPLACE_STATE";

export const SAVE_STATE_ON_SERVER = "SAVE_STATE_ON_SERVER";

// Metamap types

export const UPDATE_COMPLETED_VALIDATIONS = "UPDATE_COMPLETED_VALIDATIONS";
export const UPDATE_VALIDATION_DATA = "UPDATE_INPUT_DATA";
export const UPDATE_VALIDATION_ERRORS = "UPDATE_VALIDATION_ERRORS";
export const UPDATE_METAMAP_FILEUPLOAD_DATA = "UPDATE_METAMAP_FILEUPLOAD_DATA";
