/**
 * Return only associates from the list of inputs in officialData section
 * return a object with the list of associates
 * @param {object} officialData - list of inputs
 * @return {object} - list of associates
 */
export function returnOnlyExtraAssociates(officialData) {
  // this is to make a non-referenced copy of the object
  let associates = JSON.parse(JSON.stringify(officialData));

  associates = associates.filter((item) =>
    item.name.includes("extraAssociate")
  );

  // prepare data to send to the API with rfc and fullName
  associates = associates.map((item) => {
    const { fullName } = item;
    const [name, middle, last] = fullName.split(" ");

    item.name = name;
    item.surname = middle;
    item.second_surname = last || "";
    item.contact_role = "socio";

    if (item.value?.includes("moral")) {
      delete item.curp;
    }

    delete item.fullName;
    delete item.validated;
    delete item.value;

    return item;
  });

  return associates;
}

/**
 * Return admins from the list of inputs in officialData section
 * @param {object} officialData - list of inputs
 * @return a object with the list of associates or admins
 */
export function returnOnlyExtraAdmins(officialData) {
  // this is to make a non-referenced copy of the object
  let admins = JSON.parse(JSON.stringify(officialData));

  admins = admins.filter((item) => item.name.includes("extraAdministrator"));

  // prepare data to send to the API with rfc and fullName
  admins = admins.map((item) => {
    const { fullName } = item;
    const [name, middle, last] = fullName.split(" ");

    item.name = name;
    item.surname = middle;
    item.second_surname = last || "";
    item.contact_role = "administrador_extra";

    delete item.fullName;
    delete item.validated;
    return item;
  });

  return admins;
}
