import { getAfiliateID } from "./baseMD";
export default class LoginMD {
  static event = "clicLandingAfiliateCPGeneral";

  static tittle = "Afiliate Coppel Pay - Inicia tu solicitud";

  static sectionName = "Inicia sesión para dar de alta tu negocio";

  static MD2A1(flagSelectedButton) {
    return {
      dataLayer: {
        nd1: this.tittle,
        nd2: flagSelectedButton ? "Soy cliente" : "Logo Coppel.com",
        nd3: "",
        nd4: "",
        ...getAfiliateID(),
        event: this.event,
      },
    };
  }

  static MD2A(flagSelectedButton) {
    return {
      dataLayer: {
        nd1: this.tittle,
        nd2: this.sectionName,
        nd3: flagSelectedButton ? "Ver contraseña" : "Ocultar contraseña",
        nd4: "",
        ...getAfiliateID(),
        event: this.event,
      },
    };
  }

  static MD2B() {
    return {
      dataLayer: {
        nd1: this.tittle,
        nd2: this.sectionName,
        nd3: "Olvidaste tu contraseña",
        nd4: "",
        ...getAfiliateID(),
        event: this.event,
      },
    };
  }

  static MD2C() {
    return {
      dataLayer: {
        nd1: this.tittle,
        nd2: this.sectionName,
        nd3: "Continuar",
        nd4: "",
        ...getAfiliateID(),
        event: this.event,
      },
    };
  }

  static MD2Unregistered() {
    //TODO ATENDER PORQUE NO TIENE REGISTRO
    return {
      dataLayer: {
        nd1: this.tittle,
        nd2: this.sectionName,
        nd3: "Login Exitoso",
        ...getAfiliateID(),
        nd4: "",
        event: this.event,
      },
    };
  }

  static MD2D() {
    return {
      dataLayer: {
        nd1: this.tittle,
        nd2: this.sectionName,
        nd3: "Registrarse",
        nd4: "",
        ...getAfiliateID(),
        event: this.event,
      },
    };
  }

  static MD2E(errorInput, errorText) {
    return {
      dataLayer: {
        nd1: this.tittle,
        nd2: errorInput,
        nd3: errorText,
        nd4: "",
        ...getAfiliateID(),
        event: this.event,
      },
    };
  }

  static MD3() {
    return {
      dataLayer: {
        nd1: this.tittle,
        nd2: "Modal: Antes de comenzar toma en cuenta",
        nd3: "Llegada",
        nd4: "",
        ...getAfiliateID(),
        event: this.event,
      },
    };
  }

  static MD3A(flagSelectedButton) {
    return {
      dataLayer: {
        nd1: this.tittle,
        nd2: "Modal: Antes de comenzar toma en cuenta",
        nd3: flagSelectedButton ? "Aceptar" : "Cerrar",
        nd4: "",
        ...getAfiliateID(),
        event: this.event,
      },
    };
  }
}
