import React from "react";
import PropTypes from "prop-types";

export default function UlList({ info, itemClassName, listClassName }) {
  return (
    <ul className={listClassName}>
      {info.map((item) => {
        return (
          <li className={itemClassName} key={item}>
            {item}
          </li>
        );
      })}
    </ul>
  );
}

UlList.propTypes = {
  info: PropTypes.arrayOf(PropTypes.string).isRequired,
  itemClassName: PropTypes.string,
  listClassName: PropTypes.string,
};

UlList.defaultProps = {
  itemClassName: "",
  listClassName: "",
};
