export function generateKeys() {
  const characters =
    "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ/=";
  const charactersLength = characters.length;

  const lengthPublica = 124;
  const lengthPrivada = 424;

  let llavePublica = "";
  let llavePrivada = "";

  for (let i = 0; i < lengthPublica; i += 1) {
    llavePublica += characters.charAt(
      Math.floor(Math.random() * charactersLength)
    );
  }

  for (let i = 0; i < lengthPrivada; i += 1) {
    llavePrivada += characters.charAt(
      Math.floor(Math.random() * lengthPrivada)
    );
  }

  return {
    private: llavePrivada,
    public: llavePublica,
  };
}
