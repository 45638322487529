export default function inputValidations(typeOfInput) {
  switch (typeOfInput) {
    case "name":
      return "Escribe el nombre o nombres";

    case "number":
      return "Escribe un número";

    case "subsidiary":
      return "Escribe el número de sucursales";

    case "fullName":
      return "Escribe nombre y apellidos";

    case "lastName":
      return "Escribe el apellido paterno";

    case "mothersLastName":
      return "Escribe el apellido materno";

    case "email":
      return "Escribe el correo electrónico";

    case "phone":
      return "Escribe el número a 10 dígitos";

    case "rfc":
      return "Escribe el RFC con homoclave";

    case "curp":
      return "Escribe el CURP";

    case "outdoorNumber":
      return "Escribe el número exterior";

    case "street":
      return "Escribe la calle";

    case "exteriorNumber":
      return "Escribe el número exterior";

    case "option":
      return "Elige una opción";

    case "dd/mm/aaaa":
      return "Escribe el día, el mes y el año (DD/MM/AAAA)";

    case "commercialName":
      return "Escribe el nombre comercial";

    case "bussinesName":
      return "Escribe la razón social";

    case "bankName":
      return "Escribe el nombre del banco";

    case "bankAccountNumber":
      return "Escribe el número de cuenta";

    case "CLABE":
      return "Escribe la CLABE interbancaria";

    case "questionAnswer":
      return "Escribe tu respuesta para continuar";
      
    default:
      return "Escribe un valor";
  }
}
