// eslint-disable-next-line no-unused-vars
import debounce from "lodash.debounce";
import { PropTypes } from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import TagManager from "react-gtm-module";
import { connect, useSelector } from "react-redux";
import BaseMD from "../../../google-analytics/events/baseMD";
import {
  updateInput,
  updateInputStatus,
  validationsCompletedContact,
  verifyValidationsCommercialInformation,
  verifyValidationsCompanyData,
  verifyValidationsOfficialData,
} from "../../../redux-modules/form/actions";
import styles from "./TextArea.module.sass";

function TextAreaComponent({
  state,
  dispatch,
  storeName,
  required,
  callValidation,
  aditionalInfo,
  title,
  placeholder,
  customRequiredMessage,
}) {
  const s = styles;

  const [titleColor, setTitleColor] = useState(s.gray);
  const [validationErrorMsg, setValidationErrorMsg] = useState("");
  const [requiredIdentifier, setRequiredIdentifier] = useState("");
  const [validationErrorClass, setValidationErrorClass] = useState("");
  const currentSectionMD = useSelector((state) => state.currentSection.name);
  const typeOfPersonId = useSelector((state) => state.typeOfPerson?.id);
  const officialData = useSelector((state) => state.officialData);

  useEffect(() => {
    if (validationErrorMsg === "") return;

    TagManager.dataLayer(
      BaseMD.MD3C(
        validationErrorMsg,
        currentSectionMD,
        title,
        officialData,
        typeOfPersonId
      )
    );
  }, [validationErrorMsg]);
  function getValueFromStore(reduxState, inputName) {
    const currentSection = reduxState.currentSection.name;
    let inputValue;

    if (currentSection) {
      reduxState[currentSection].map((input) => {
        if (input.name === inputName) {
          inputValue = input.value;
        }
        return inputValue;
      });
      // delete?
      return inputValue;
    }
    return inputValue;
  }

  function removeValidated() {
    const payload = { name: storeName, validated: false };
    dispatch(updateInputStatus(payload));
  }

  function setValidated() {
    const payload = { name: storeName, validated: true };
    dispatch(updateInputStatus(payload));
  }

  function validateRequired() {
    // todo use ref
    const { value } = document.getElementById(storeName);
    if (value !== "") {
      setValidationErrorMsg("");
      setValidationErrorClass("");
      setValidated();
    } else {
      if (customRequiredMessage) {
        setValidationErrorMsg(customRequiredMessage);
      } else {
        setValidationErrorMsg("Por favor introduce un valor");
      }
      setValidationErrorClass(s.error);
      removeValidated();
    }
  }

  

  function handleOnBlur() {
    if (required) {
      validateRequired();
    }
    const currentSection = state.currentSection.name;
    switch (currentSection) {
      case "contact":
        dispatch(validationsCompletedContact());
        break;
      case "officialData":
        dispatch(verifyValidationsOfficialData());
        break;
      case "companyData":
        dispatch(verifyValidationsCompanyData());
        break;
      case "commercialInfo":
        dispatch(verifyValidationsCommercialInformation());
        break;
      default:
        break;
    }
  }

  const didMount = useRef(false);

  useEffect(() => {
    if (required) {
      setRequiredIdentifier("*");
      if (didMount.current) {
        validateRequired();
      } else {
        didMount.current = true;
      }
    }
  }, [callValidation]);
  const debouncedHandleOnBlur = React.useMemo(
    () => debounce(handleOnBlur, 700),
    []
  );

  function handleOnChangeInputValue(e) {
    const { value } = e.target;
    const pay = { name: storeName, value };
    dispatch(updateInput(pay));
    if (value !== "") {
      setTitleColor(s.blue);
    } else {
      setTitleColor(s.gray);
    }
    validateRequired();
    debouncedHandleOnBlur();
  }
  return (
    <div className={`${validationErrorClass} ${s.input}`}>
      <label htmlFor={storeName} className={`${titleColor} ${s.inputTitle}`}>
        {title + requiredIdentifier}
      </label>
      <textarea
        className={s.inputText}
        value={getValueFromStore(state, storeName) || ""}
        type="text"
        id={storeName}
        placeholder={placeholder}
        onChange={(e) => {
          handleOnChangeInputValue(e);
        }}
        onBlur={handleOnBlur}
        maxLength={500}
      />
      <small className={s.inputErrorFeedback}>{validationErrorMsg}</small>
      <small className={s.inputAditionalInfo}>{aditionalInfo}</small>
    </div>
  );
}

TextAreaComponent.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  state: PropTypes.object.isRequired,
  customRequiredMessage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  dispatch: PropTypes.func.isRequired,
  storeName: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  callValidation: PropTypes.bool.isRequired,
  aditionalInfo: PropTypes.string,
  title: PropTypes.string,
  placeholder: PropTypes.string,
};

TextAreaComponent.defaultProps = {
  customRequiredMessage: false,
  aditionalInfo: "",
  title: "title",
  placeholder: "",
};

function mapStateToProps(state) {
  return { state };
}

export const TextArea = connect(mapStateToProps, null)(TextAreaComponent);
