import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import TagManager from "react-gtm-module";
import { useDispatch, useSelector } from "react-redux";
import BaseMD from "../../../google-analytics/events/baseMD";
import { updateInputStatus } from "../../../redux-modules/form/actions";
import inputValidations from "../TextInput/inputUtils/inputValidations";
import styles from "./TextInputBase.module.sass";

export default function TextInputBase({
  setValidationValues,
  storeName,
  handleOnChange,
  title,
  type,
  rfcInput,
  fullNameInput,
  curpInput,
  placeholder,
  required,
  inputValidation,
  aditionalInfo,
  callValidation,
  hasLengthLimit,
  customRequiredMessage,
}) {
  const s = styles;
  const [visibilityLabel, setVisibilityLabel] = useState(s.hidden);
  const [validationErrorMsg, setValidationErrorMsg] = useState("");
  const [validationErrorClass, setValidationErrorClass] = useState("");
  const [requiredIdentifier, setRequiredIdentifier] = useState("");
  const currentSectionMD = useSelector((state) => state.currentSection.name);
  const typeOfPersonId = useSelector((state) => state.typeOfPerson?.id);
  const officialData = useSelector((state) => state.officialData);

  const state = useSelector((stat) => stat);
  const dispatch = useDispatch();

  const inputRef = useRef(rfcInput || fullNameInput || curpInput);

  function removeValidated() {
    const payload = { name: storeName, validated: false };
    dispatch(updateInputStatus(payload));
  }

  let inputValue;

  const valueFromStore = (reduxState, sName) => {
    const currentSection = reduxState.currentSection.name;
    if (reduxState.currentSection.name) {
      reduxState[currentSection].map((input) => {
        if (input.name === sName) {
          if (rfcInput) {
            inputValue = input.rfc;
          } else if (fullNameInput) {
            inputValue = input.fullName;
          } else if (curpInput) {
            inputValue = input.curp;
          }
        }
        return inputValue;
      });
    }
    return inputValue;
  };

  // validations
  function validateRequired() {
    const { value } = document.getElementById(storeName);
    if (value !== "") {
      setValidationErrorMsg("");
      setValidationErrorClass("");
      const payload = { name: storeName, validated: true };
      dispatch(updateInputStatus(payload));
    } else {
      if (customRequiredMessage) {
        setValidationErrorMsg(customRequiredMessage);
      } else {
        const validationText = inputValidations(inputValidation);
        setValidationErrorMsg(validationText);
      }

      // Error class hides aditional info text
      setValidationErrorClass(s.error);
      removeValidated();
    }
  }

  function validateRFC() {
    let rfc = inputRef.current.value;

    if (typeof rfc === "string") {
      rfc = rfc.toUpperCase();
    }

    if (
      rfc.match(
        /^([A-ZÑ]|&){3,4}\d{2}(0[1-9]|1[0-2])([12]\d|0[1-9]|3[01])[A-Z0-9]{3}$/
      )
    ) {
      setValidationErrorMsg("");
      setValidationErrorClass("");
      setValidationValues({ rfc: true });
    } else {
      // error class hides aditional info text
      setValidationErrorMsg("Escribe las 13 letras y números del RFC");
      setValidationErrorClass(s.error);
      setValidationValues({ rfc: false });
    }
  }

  function validateName() {
    let name = inputRef.current.value;
    name = name.trim();

    const nameRegex = /^[a-zA-ZÀ-ÿ\s]{1,250}$/;

    if (nameRegex.test(name)) {
      setValidationErrorMsg("");
      setValidationErrorClass("");
      setValidationValues({ fullName: true });
    } else {
      // error class hides aditional info text
      setValidationErrorMsg("Escribe el nombre o nombres");
      setValidationErrorClass(s.error);
      setValidationValues({ fullName: false });
    }
  }

  function validateCurp() {
    let curp = inputRef.current.value;

    curp = curp.toUpperCase();

    const curpRegex = new RegExp(
      "^(" +
        "[A-Z][AEIOUX][A-Z]{2}\\d{2}" +
        "(?:0[1-9]|1[0-2])" +
        "(?:0[1-9]|[12]\\d|3[01])" +
        "[HM]" +
        "(?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)" +
        "[B-DF-HJ-NP-TV-Z]{3}[A-Z\\d]" +
        ")(\\d)$"
    );

    if (curp.match(curpRegex)) {
      setValidationErrorMsg("");
      setValidationErrorClass("");
      setValidationValues({ curp: true });
    } else {
      // error class hides aditional info text
      setValidationErrorMsg("Revisa que el CURP tenga 18 carácteres");
      setValidationErrorClass(s.error);
      setValidationValues({ curp: false });
    }
  }

  const handleOnBlur = () => {
    if (required) {
      validateRequired();
    }

    if (inputValidation === "fullName") {
      validateName();
    } else if (inputValidation === "rfc") {
      validateRFC();
    } else {
      validateCurp();
    }

    // const currentSection = state.currentSection.name;
  };

  function returnFalseOnLargeValues(maxLength, valueFromInput) {
    return valueFromInput.length <= maxLength;
  }

  function handleOnChangeInputValue(ivalue) {
    if (
      hasLengthLimit !== false &&
      !returnFalseOnLargeValues(hasLengthLimit, ivalue)
    ) {
      return false;
    }

    if (ivalue !== "") {
      setVisibilityLabel(s.visible);
    } else {
      setVisibilityLabel(s.hidden);
    }

    handleOnChange(ivalue);

    return true;
  }

  function validateValueOnRender() {
    const { value } = document.getElementById(storeName);
    if (value !== "") {
      setVisibilityLabel(s.visible);
    } else {
      setVisibilityLabel(s.hidden);
    }
  }

  // use when value is set from redux state

  const didMount = useRef(false);
  useEffect(() => {
    validateValueOnRender();

    if (required) {
      setRequiredIdentifier("*");
      if (didMount.current) {
        handleOnBlur();
      } else {
        didMount.current = true;
      }

      const currentValue = valueFromStore(state, storeName);
      if (currentValue) {
        if (inputValidation === "fullName") {
          validateName();
        } else if (inputValidation === "rfc") {
          validateRFC();
        } else {
          validateCurp();
        }
      }
    }
  }, [callValidation]);

  useEffect(() => {
    if (validationErrorMsg === "") return;
    TagManager.dataLayer(
      BaseMD.MD3C(
        validationErrorMsg,
        currentSectionMD,
        title,
        officialData,
        typeOfPersonId
      )
    );
  }, [validationErrorMsg]);
  // Conditional attribute

  function dontAllowNumbers(event) {
    // Allow: Numbers
    if (!/\d/.test(event.key)) {
      event.preventDefault();
    }
  }

  return (
    <div className={`${validationErrorClass} ${s.input}`}>
      <label
        htmlFor={storeName}
        className={`${visibilityLabel} ${s.inputTitle}`}
      >
        {title + requiredIdentifier}
      </label>
      <input
        ref={inputRef}
        className={s.inputText}
        value={valueFromStore(state, storeName) || ""}
        type={type}
        id={storeName}
        onKeyPress={type === "number" ? dontAllowNumbers : null}
        placeholder={placeholder + requiredIdentifier}
        onChange={(e) => {
          handleOnChangeInputValue(e.target.value);
        }}
        onBlur={handleOnBlur}
      />
      <small className={s.inputErrorFeedback}>{validationErrorMsg}</small>
      <small className={s.inputAditionalInfo}>{aditionalInfo}</small>
    </div>
  );
}

TextInputBase.propTypes = {
  rfcInput: PropTypes.bool,
  fullNameInput: PropTypes.bool,
  curpInput: PropTypes.bool,
  title: PropTypes.string.isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  required: PropTypes.bool,
  inputValidation: PropTypes.string,
  aditionalInfo: PropTypes.string,
  handleOnChange: PropTypes.func.isRequired,
  storeName: PropTypes.string,
  callValidation: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  // eslint-disable-next-line react/forbid-prop-types
  hasLengthLimit: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  customRequiredMessage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
};

TextInputBase.defaultProps = {
  rfcInput: false,
  fullNameInput: false,
  curpInput: false,
  type: "text",
  callValidation: false,
  required: false,
  inputValidation: "",
  aditionalInfo: "",
  storeName: "",
  hasLengthLimit: false,
  customRequiredMessage: false,
};
