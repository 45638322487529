/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import TagManager from "react-gtm-module";
import { connect, useSelector } from "react-redux";
import { categories } from "../../../categories/categories";
import BaseMD from "../../../google-analytics/events/baseMD";
import {
  copyData,
  cleanStore,
  nextSection,
  logOut,
  prevSection,
  updateInput,
  verifyValidationsCommercialInformation,
} from "../../../redux-modules/form/actions";
import makeBodyPostAssociate from "../../../scripts/makeBodyPostAssociate";
import { DivisionLine } from "../../DivisionLine";
import { CheckBox } from "../../inputs/CheckBox";
import { PCInput } from "../../inputs/PCInput";
import { RadioControllerSelector } from "../../inputs/RadioControlledSelector";
import { RadioGroup } from "../../inputs/RadioGroup";
import { SelectUI } from "../../inputs/SelectUI/Select";
import { SelectWithId } from "../../inputs/SelectWithId";
import { plataformas } from "../../../plataformas/plataformas";
import TextInput from "../../inputs/TextInput/TextInput";
import styles from "./CommercialInformation.module.sass";
import saveState from "../../../scripts/saveState";
import { Modal } from "antd-v5";
import { ConfigProvider as ConfigProvider5 } from "antd-v5";
import ModalError from "../../modals/ModalError/ModalError";
import ModalInfo from "../../modals/ModalInfo/ModalInfo";
import LoaderModal from "../../modals/LoaderModal/LoaderModal";
import WelcomeBtn from "../../WelcomeBtn/WelcomeBtn";




function Component({ state, dispatch }) {
  const s = styles;

  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showModalError, setShowModalError] = useState(false);



  const [callValidation, setCallValidation] = useState(0);
  const [typeOfPOPAddress, setTypeOfPOPAddress] = useState("false");
  const [checkDataPOP, setCheckDataPOP] = useState(false);
  const officialData = useSelector((state) => state.officialData);
  const typeOfPersonId = useSelector((state) => state.typeOfPerson?.id);
  const currentSection = state.currentSection.name;
  useEffect(() => {
    TagManager.dataLayer(BaseMD.MD7(officialData, typeOfPersonId));
  }, []);
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  function getValuesFromStore(reduxState) {
    let options;
    reduxState[currentSection].map((inputItem) => {
      if (
        inputItem.name ===
        `suburbOptions${capitalizeFirstLetter(currentSection)}`
      ) {
        options = inputItem.value;
      }
      return options;
    });
    return options;
  }


  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));


  async function goNextSection() {
    if (state.commercialInformationValidations === true) {
      setIsLoading(true);
      document.getElementById("endButton").disabled = true;
      await delay(2000);
      TagManager.dataLayer(BaseMD.MD7F(officialData, typeOfPersonId));

      makeBodyPostAssociate(state)
        .then((data) => {
          setIsLoading(false);
          if (data) {
            saveState(state);
            dispatch(nextSection());
          } else {
            document.getElementById("endButton").disabled = false;
            // alert("Error al registrarse");
            setShowModalError(true);
          }
        })
        .catch((error) => {
          saveState(state);
          setIsLoading(false);
          if (error?.errorCode === "40076") {
            setShowModal(true);
            return;
          }
          document.getElementById("endButton").disabled = false;
          // alert("Error al registrarse");
          setShowModalError(true);
        });
    }
  }

  const optionsPOPAddressMoral = [
    {
      name: "Enviar a la misma dirección que capturé en Datos de empresa",
      id: 1,
    },
    {
      name: "Enviar a otra dirección",
      id: 2,
    },
  ];

  const optionsPOPAddressPhysical = [
    {
      name: "Enviar a la misma dirección que capturé en Datos oficiales",
      id: 1,
    },
    {
      name: "Enviar a otra dirección",
      id: 2,
    },
  ];

  const boolean = [
    {
      name: "Sí",
      id: 1,
    },
    {
      name: "No",
      id: 2,
    },
  ];

  const phoneOptions = [
    {
      name: "Oficina",
      id: 1,
    },
    {
      name: "Celular",
      id: 2,
    },
    {
      name: "Casa",
      id: 3,
    },
  ];

  const emailOptions = [
    {
      name: "Empresarial",
      id: 1,
    },
    {
      name: "Personal",
      id: 2,
    },
  ];

  const [hasPhysicalPoint, setHasPhysicalPoint] = useState(false);
  const [hasOnlineSales, setHasOnlineSales] = useState(false);
  const [checksAreMissing, setChecksAreMissing] = useState(false);
  const [checksMissingMessage, setChecksMissingMessage] = useState(null);
  const currentSectionMD = useSelector((state) => state.currentSection.name);

  const handlePhysicalPoint = (e) => {
    if (e.target.checked === true) {
      TagManager.dataLayer(
        BaseMD.MD7A("Punto de venta físico", officialData, typeOfPersonId)
      );
      setHasPhysicalPoint(true);
    } else {
      setHasPhysicalPoint(false);
      cleanPVF();
    }
    setChecksMissingMessage("");
    dispatch(verifyValidationsCommercialInformation());
  };

  const handleOnlineSales = (e) => {
    if (e.target.checked === true) {
      TagManager.dataLayer(
        BaseMD.MD7A("Venta por internet", officialData, typeOfPersonId)
      );
      setHasOnlineSales(true);
    } else {
      setHasOnlineSales(false);
      cleanVPI();
    }
    setChecksMissingMessage("");
    dispatch(verifyValidationsCommercialInformation());
  };

  function validateChecks() {
    if (!hasPhysicalPoint && !hasOnlineSales) {
      TagManager.dataLayer(
        BaseMD.MD3C(
          "Elíge al menos una opción para continuar",
          currentSectionMD,
          "¿Dónde quieres usar Coppel Pay?*",
          officialData,
          typeOfPersonId
        )
      );
      setChecksAreMissing(true);
      setChecksMissingMessage("Elíge al menos una opción para continuar");
    } else {
      setChecksMissingMessage("");
    }
  }

  function valueFromOfficialData(stateName) {
    let value = "";
    state.officialData.map((item) => {
      if (item.name === stateName) {
        value = item.value;
        return item.value;
      }
      return null;
    });
    return value;
  }

  function valueFromCompanyData(stateName) {
    let value = "";
    state.companyData.map((item) => {
      if (item.name === stateName) {
        value = item.value;
        return item.value;
      }
      return null;
    });
    return value;
  }

  useEffect(() => {
    state.commercialInfo.map((item) => {
      if (
        item.name === "commercialInformationWhereToUseCPPhysical" &&
        item.value === true
      ) {
        setHasPhysicalPoint(true);
      }
      if (
        item.name === "commercialInformationOnline" &&
        item.value === true
      ) {
        setHasOnlineSales(true);
      }
    });
  }, []);

  const altTitle =
    "Información comercial. Estás en el paso 4 de 5. Necesitarás indicar la categoría del producto o servicio de tu empresa, el monto de facturación mensual y escribir la dirección a la que podemos enviarte materiales";

  const copyToState = () => {
    const payload = {
      commercialInformationPopName: "contactAdminName",
      commercialInformationPopLastname: "contactAdminFirstLastname",
      commercialInformationPopNameMothersLastname: "contactAdminSecondLastname",
      commercialInformationTypePhone: "contactAdminPhoneType",
      commercialInformationTypeEmail: "contactAdminEmailType",
      commercialInformationPopPhone: "contactAdminPhone",
      commercialInformationPopEmail: "contactAdminEmail",
    };
    dispatch(copyData(payload));
    dispatch(verifyValidationsCommercialInformation());
  };
  const cleanPVF = () => {//limpiar el store de punto de venta fisico
    const payload = {
      sectionName: "commercialInfo",
      fields: [
        //"commercialInformationWhereToUseCPPhysical",
        "commercialInformationNumberOfSub",
        "commercialInformationOptionsPOPAddress",
        "commercialInformationPopName",
        "commercialInformationPopLastname",
        "commercialInformationPopNameMothersLastname",
        "commercialInformationTypePhone",
        "commercialInformationTypeEmail",
        "commercialInformationPopPhone",
        "commercialInformationPopEmail",
        "streetPhysicalTaxpayer",
        "exteriorNumberPhysicalTaxpayer",
        "interiorNumberPhysicalTaxpayer",
        "physicalPostalCode",
        "suburbCommercialInfo",
        "municipalityCommercialInfo",
        "cityCommercialInfo",
        "stateCommercialInfo",
        "countryCommercialInfo",
      ],
    };
    dispatch(cleanStore(payload));
  };
  const cleanVPI = () => {//limpiar el store de venta por internet
    const payload = {
      sectionName: "commercialInfo",
      fields: [
        //"commercialInformationOnline",
        "commercialInformationWebsite",
        "commercialInformationPlataforma",
      ],
    };
    dispatch(cleanStore(payload));
  };
  const cleanPOP = () => {
    const payload = {
      sectionName: "commercialInfo",
      fields: [
        "commercialInformationPopName",
        "commercialInformationPopLastname",
        "commercialInformationPopNameMothersLastname",
        "commercialInformationTypePhone",
        "commercialInformationTypeEmail",
        "commercialInformationPopPhone",
        "commercialInformationPopEmail",
      ],
    };
    dispatch(cleanStore(payload));
  };

  const toggle = () => {
    dispatch(logOut());
  }

  const toggleError = () => {
    setShowModalError(false);
  }

  const openEmailClient = () => {
    window.open("mailto:afiliatecoppelpay@coppel.com", '_blank');
    dispatch(logOut());
  }


  return (
    <>
      <ConfigProvider5 prefixCls="ant5">
        <Modal open={showModal} footer={null} onCancel={toggle} centered closeIcon={null} width={600} maskClosable={false}>
          <ModalInfo
            toggle={toggle}
            tittle={"Esta cuenta ya existe"}
            center={true}
          >
            <div className={s.childrenInfoModal}>
              <p>El sistema nos indica que la cuenta ya ha sido registrada anteriormente.</p>
              <p>Para aclararlo contacta a <a href="#" onClick={openEmailClient}>afiliatecoppelpay@coppel.com</a></p>
              <div className={s.modalBtn}>
                <WelcomeBtn
                  tittle="Aceptar"
                  disabled={false}
                  onClick={toggle}
                />
              </div>
            </div>
          </ModalInfo>
        </Modal>

        <Modal open={isLoading} footer={null} centered closeIcon={null} width={500} maskClosable={false}>
          <LoaderModal tittle={"Estamos procesando tu solicitud"} />
        </Modal>

        <Modal
          open={showModalError}
          footer={null}
          onCancel={toggleError}
          centered
          closeIcon={null}
          width={600}
          maskClosable={false}
        >
          {/* cambiar medicion */}
          <ModalError toggle={toggleError} MD="MD7ModalUpPass" MDEvent="MD7AModalUpPass" />
        </Modal>

      </ConfigProvider5>

      <section className={s.contactForm}>
        <h1 className={s.title} alt={altTitle}>
          Información comercial
        </h1>
        <h2>Responde estas preguntas sobre tu empresa</h2>

        <h3 className={s.secondaryTitle}>¿Cómo te enteraste de Coppel Pay?*</h3>
        <p className={s.subtitle}>Dinos cuál fue el medio por el que supiste de nuestro programa</p>
        <div className={s.selectWrapper}>
          <TextInput
            required
            id="commercialInformationSource"
            storeName="commercialInformationSource"
            title="Medio"
            placeholder="Escribe aquí tu respuesta"
            inputValidation="questionAnswer"
            hasLengthLimit="50"
            callValidation={callValidation}
          />
        </div>

        <h3 className={s.secondaryTitle}>
          ¿Cuál es la categoría de la que vendes más productos?*
        </h3>
        <p className={s.subtitle}>Elige la categoría principal</p>

        <div className={s.selectWrapper}>
          <SelectWithId
            id="commercialInformationMainCategorySales"
            storeName="commercialInformationMainCategorySales"
            name="Categoría"
            defaultValue="Categoría"
            customRequiredMessage="Elige una categoría para continuar"
            options={categories}
            callValidation={callValidation}
          />
        </div>

        <h3 className={s.secondaryTitle}>
          ¿Cuál es el monto de facturación mensual?*
        </h3>
        <p className={s.subtitle}>
          Elige el rango promedio de facturación en pesos mexicanos
        </p>
        <div className={s.selectWrapper}>
          <SelectUI
            id="commercialInformationBillsRange"
            storeName="commercialInformationBillsRange"
            name="Rango"
            defaultValue="Facturación mensual"
            customRequiredMessage="Elige un rango promedio de facturación en pesos mexicanos"
            options={[
              "Menos de $ 500,000",
              "$ 500,001 - $ 1,000,000",
              "$ 1,000,001 - $ 5,000,000",
              "Más de $ 5,000,000",
            ]}
            onChange={(e) => {
              if (e !== undefined) {
                TagManager.dataLayer(
                  BaseMD.MD7H(
                    officialData,
                    typeOfPersonId,
                    e,
                    "Facturación mensual"
                  )
                );
              }
            }}
            callValidation={callValidation}
          />
        </div>

        <h3 className={s.secondaryTitle}>¿Dónde quieres usar Coppel Pay?*</h3>
        <p className={s.subtitle}>Puedes elegir más de una opción</p>

        <div className={s.checkboxWrapper}>
          <CheckBox
            classExt={s.changeCheckboxIntegration}
            storeName="commercialInformationWhereToUseCPPhysical"
            title="Punto de venta físico"
            description="Desde una computadora en tu tienda física o sucursales"
            externalHandler={handlePhysicalPoint}
            callValidation={checksAreMissing}
          />

          <CheckBox
            classExt={s.changeCheckboxIntegration}
            storeName="commercialInformationOnline"
            title="Venta por internet"
            description="Como un botón de pago en tu sitio web o tienda en línea"
            externalHandler={handleOnlineSales}
          />
        </div>
        {checksMissingMessage && (
          <span className={s.selectACheckMessage}>{checksMissingMessage}</span>
        )}
        {hasPhysicalPoint && (
          <>
            <h3 className={s.secondaryTitle}>
              ¿Cuántas sucursales tiene tu negocio?*
            </h3>
            <p className={s.subtitle}>
              Usa números para escribir la cantidad de sucursales que tiene tu
              negocio
            </p>

            <div className={s.inputsSection}>
              <TextInput
                required
                title="Número de sucursales"
                type="number"
                hasLengthLimit={5}
                placeholder="Número de sucursales"
                storeName="commercialInformationNumberOfSub"
                customRequiredMessage="Escribe el número de sucursales"
                callValidation={callValidation}
              />
            </div>

            <DivisionLine />

            <h2 className={s.secondaryTitle}>
              Datos para envío de material POP
            </h2>

            <p className={s.subtitle}>
              Escribe la dirección a la que enviaremos letreros, folletos y
              otros materiales impresos con información de Coppel Pay
            </p>

            <RadioControllerSelector
              storeName="commercialInformationOptionsPOPAddress"
              options={
                state.typeOfPerson.name === "moral"
                  ? optionsPOPAddressMoral
                  : optionsPOPAddressPhysical
              }
              callValidation={callValidation}
              propertyName="commercialInformationOptionsPOPAddress"
              externalHandler={(e) => {
                if (e === undefined) {
                  return;
                }

                if (e === "1") {
                  TagManager.dataLayer(
                    BaseMD.MD7B(
                      officialData,
                      "Enviar a la misma dirección que capturé en Datos de empresa",
                      typeOfPersonId
                    )
                  );
                  setTypeOfPOPAddress("sameOfficialData");
                } else {
                  TagManager.dataLayer(
                    BaseMD.MD7B(
                      officialData,
                      "Enviar a otra dirección",
                      typeOfPersonId
                    )
                  );
                  setTypeOfPOPAddress("capture");
                }
                const payload = {
                  name: "commercialInformationOptionsPOPAddress",
                  value: e,
                  validated: true,
                };
                dispatch(updateInput(payload));
                dispatch(verifyValidationsCommercialInformation());
              }}
            />
            {typeOfPOPAddress === "sameOfficialData" && (
              <div className="">
                {state.typeOfPerson.id === 1 ? (
                  <div className={s.addressFromOfficialData}>
                    <span className={s.addressTitle}>
                      Domicilio de entrega:
                    </span>
                    <br />
                    <span className="">
                      {valueFromCompanyData("streetCompanyData")}
                    </span>
                    {" #"}
                    <span>
                      {valueFromCompanyData("exteriorNumberCompanyData")}
                    </span>
                    {valueFromCompanyData("interiorNumberCompanyData") !==
                      "" && (
                        <span>
                          {" Int. #"}
                          {valueFromCompanyData("interiorNumberCompanyData")}
                        </span>
                      )}
                    <br />
                    <span className="">
                      {valueFromCompanyData("suburbCompanyData")}, CP.{" "}
                    </span>
                    <span>{valueFromCompanyData("postalCodeCompanyData")}</span>{" "}
                    <br />
                    <span className="">
                      {valueFromCompanyData("municipalityCompanyData")}
                    </span>
                    {", "}
                    <span className="">
                      {valueFromCompanyData("stateCompanyData")}
                    </span>{" "}
                    <br />
                    {valueFromCompanyData("countryCompanyData")}.
                  </div>
                ) : (
                  <div className={s.addressFromOfficialData}>
                    <span className={s.addressTitle}>
                      Domicilio de entrega:
                    </span>
                    <br />
                    <span className="">
                      {valueFromOfficialData("streetOfficialData")}
                    </span>
                    {" #"}
                    <span>
                      {valueFromOfficialData("exteriorNumberOfficialData")}
                    </span>
                    {valueFromOfficialData("interiorNumberOfficialData") !==
                      "" && (
                        <span>
                          {" Int. #"}
                          {valueFromOfficialData("interiorNumberOfficialData")}
                        </span>
                      )}
                    <br />
                    <span className="">
                      {valueFromOfficialData("suburbOfficialData")} CP.{" "}
                    </span>
                    <span>
                      {valueFromOfficialData("postalCodeOfficialData")}
                    </span>{" "}
                    <br />
                    <span className="">
                      {valueFromOfficialData("stateOfficialData")}
                    </span>{" "}
                    {", "}
                    <span className="">
                      {valueFromOfficialData("municipalityOfficialData")}
                    </span>
                    <br />
                    {valueFromOfficialData("countryOfficialData")}
                  </div>
                )}
              </div>
            )}
            {typeOfPOPAddress === "capture" && (
              <div className={s.addressWrapper}>
                <TextInput
                  required
                  title="Calle"
                  placeholder="Calle"
                  storeName="streetPhysicalTaxpayer"
                  inputValidation="street"
                  callValidation={callValidation}
                />

                <TextInput
                  required
                  title="Número exterior"
                  placeholder="Número exterior"
                  storeName="exteriorNumberPhysicalTaxpayer"
                  inputValidation="exteriorNumber"
                  callValidation={callValidation}
                  type="number"
                />

                <TextInput
                  title="Número interior"
                  placeholder="Número interior"
                  storeName="interiorNumberPhysicalTaxpayer"
                  callValidation={callValidation}
                  type="number"
                />

                <PCInput
                  required
                  title="Código postal"
                  placeholder="Código postal"
                  storeName="physicalPostalCode"
                  inputValidation="postalCode"
                  callValidation={callValidation}
                />

                <div className={s.whiteSpace} />

                <div className={s.whiteSpace} />

                <SelectUI
                  id="slc_colonia"
                  name="Colonia"
                  storeName="suburbCommercialInfo"
                  defaultValue="Colonia"
                  options={
                    getValuesFromStore(state) || ["Introduce un código postal"]
                  }
                  callValidation={callValidation}
                />

                <TextInput
                  required
                  title="Municipio o alcaldía"
                  placeholder="Municipio o alcaldía"
                  storeName="municipalityCommercialInfo"
                  thisValueWillBeSetFromRedux
                  callValidation={callValidation}
                  inputValidation="option"
                />

                <div className={s.whiteSpace} />

                <TextInput
                  required
                  title="Estado"
                  placeholder="Estado"
                  storeName="stateCommercialInfo"
                  thisValueWillBeSetFromRedux
                  callValidation={callValidation}
                  inputValidation="option"
                />

                <TextInput
                  required
                  title="País"
                  placeholder="País"
                  storeName="countryCommercialInfo"
                  thisValueWillBeSetFromRedux
                  callValidation={callValidation}
                  inputValidation="option"
                />

                <div className={s.whiteSpace} />
              </div>
            )}
            <div className={s.column}>
              <h3 className={s.secondaryTitle}>
                ¿Quién recibirá el material POP?
              </h3>
              <CheckBox
                classExt={s.changeCheckbox}
                storeName="checkDataPOP"
                title="Usar los mismos datos del administrador"
                externalHandler={(e) => {
                  setCheckDataPOP(e.target.checked);
                  if (e.target.checked) {
                    copyToState();
                  } else {
                    cleanPOP();
                  }
                }}
                isRequired={false}
              />
            </div>
            <p className={s.subtitle}>
              Escribe los datos de la persona que recibirá el material
            </p>

            <div className={s.radiosSection}>
              <TextInput
                required
                storeName="commercialInformationPopName"
                title="Nombre"
                placeholder="Nombre"
                inputValidation="name"
                callValidation={callValidation}
                canCopy={checkDataPOP}
                copyFrom="contactAdminName"
                sectionCopyFrom="contact"
              />

              <TextInput
                required
                storeName="commercialInformationPopLastname"
                title="Apellido paterno"
                placeholder="Apellido paterno"
                inputValidation="lastName"
                callValidation={callValidation}
                canCopy={checkDataPOP}
                copyFrom="contactAdminFirstLastname"
                sectionCopyFrom="contact"
              />

              <TextInput
                required
                storeName="commercialInformationPopNameMothersLastname"
                title="Apellido materno"
                placeholder="Apellido materno"
                inputValidation="mothersLastName"
                callValidation={callValidation}
                canCopy={checkDataPOP}
                copyFrom="contactAdminSecondLastname"
                sectionCopyFrom="contact"
              />

              <RadioGroup
                title="Tipo de teléfono"
                storeName="commercialInformationTypePhone"
                optionsList={phoneOptions}
                callValidation={callValidation}
                canCopy={checkDataPOP}
                copyFrom="contactAdminPhoneType"
                sectionCopyFrom="contact"
              />

              <RadioGroup
                title="Tipo de correo"
                storeName="commercialInformationTypeEmail"
                optionsList={emailOptions}
                callValidation={callValidation}
                canCopy={checkDataPOP}
                copyFrom="contactAdminEmailType"
                sectionCopyFrom="contact"
              />
            </div>
            <div className={s.inputsSection}>
              <TextInput
                required
                storeName="commercialInformationPopPhone"
                title="Teléfono"
                placeholder="Teléfono"
                inputValidation="phone"
                type="number"
                aditionalInfo="Escribe el número a 10 dígitos"
                callValidation={callValidation}
                hasLengthLimit={10}
                canCopy={checkDataPOP}
                copyFrom="contactAdminPhone"
                sectionCopyFrom="contact"
              />
              <TextInput
                required
                storeName="commercialInformationPopEmail"
                title="Correo electrónico"
                placeholder="Correo electrónico"
                inputValidation="email"
                aditionalInfo="Lo usaremos solo como un medio de contacto"
                callValidation={callValidation}
                canCopy={checkDataPOP}
                copyFrom="contactAdminEmail"
                sectionCopyFrom="contact"
              />
            </div>
          </>
        )}
        {hasOnlineSales && (
          <>
            <h3 className={s.secondaryTitle}>
              Elige la plataforma de comercio electrónico que usas para tu sitio
              web*
            </h3>
            <p className={s.subtitle}>
              Es la plataforma donde se encuentra tu página, como: Shopify,
              Magento, Prestashop, etc.
            </p>
            <div className={s.selectWrapper}>
              <SelectWithId
                id="commercialInformationPlataforma"
                storeName="commercialInformationPlataforma"
                name="Plataforma"
                defaultValue="Plataforma"
                customRequiredMessage="Elige una opción para continuar"
                options={plataformas}
                callValidation={callValidation}
              />
            </div>

            <h3 className={s.secondaryTitle}>¿Cuál es tu sitio web?*</h3>
            <p className={s.subtitle}>Escribe la URL de tu sitio web</p>
            <div className={s.selectWrapper}>
              <TextInput
                required
                storeName="commercialInformationWebsite"
                title="Sitio web"
                placeholder="Sitio web"
                inputValidation="url"
                callValidation={callValidation}
              />
            </div>
          </>
        )}
      </section>

      <div className={s.heroControls}>
        <button
          type="button"
          alt="Ir a la sección anterior"
          className={s.btnBack}
          onClick={() => {
            TagManager.dataLayer(BaseMD.MD7E(officialData, typeOfPersonId));
            saveState(state);
            dispatch(prevSection());
            setCallValidation((currentValue) => currentValue + 1);
          }}
        >
          <span className="arrow">🡠 </span> Regresar
        </button>
        <button
          alt="Ir a la siguiente sección"
          className={`${s.btnNext} ${state.commercialInformationValidations ? s.active : ""
            }`}
          type="button"
          id="endButton"
          onClick={() => {
            setCallValidation((currentValue) => currentValue + 1);
            validateChecks();
            dispatch(verifyValidationsCommercialInformation());
            goNextSection();
          }}
        >
          Finalizar
        </button>
      </div>
    </>
  );
}

Component.propTypes = {
  dispatch: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  state: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    state,
  };
}

export const CommercialInformation = connect(mapStateToProps)(Component);
