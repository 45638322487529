/**
 * Validate the state documents and if there are any errors, return the document name
 */
export function returnDocumentsWithErrors(documentsStore) {
  return documentsStore
    .filter((document) => {
      return document.value.status === "rejected";
    })
    .map((document) => {
      return document.name;
    });
}

export function returnErrorsAndDocumentName(documentsStore) {
  const documentName = {
    CSF: "Constancia de situación fiscal",
    passport: "Pasaporte",
    ineFront: "INE Frente",
    ineBack: "INE Atrás",
    bankStatement: "Estado de cuenta",
  };

  return documentsStore
    .filter((document) => {
      return document.value.status === "rejected";
    })
    .map((document) => {
      const documentErrors = [...new Set(document.value.errors)];
      const fileName = documentName[document.name] || document.name;
      return { fileName, errors: documentErrors };
    });
}
