import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { prevSection } from "../../../redux-modules/form/actions";

import styles from "./Documents.module.sass";

import ModalUploadProcess from "../../modals/modalUploadProcess/modalUploadProcess";

function DocumentsComponent({ dispatch, onSendMessage, messages }) {
  const s = styles;

  return (
    <>
      <ModalUploadProcess onSendMessage={onSendMessage} messages={messages} />
      <div className={s.heroControls}>
        <button
          type="button"
          alt="Ir a la sección anterior"
          className={s.btnBack}
          onClick={() => {
            dispatch(prevSection());
          }}
        >
          <span className="arrow">🡠 </span>Regresar al inicio
        </button>
      </div>
    </>
  );
}

DocumentsComponent.propTypes = {
  dispatch: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  // state: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    state,
  };
}

export const Documents = connect(mapStateToProps)(DocumentsComponent);
