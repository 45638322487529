import React, { useState, useMemo } from "react";
import debounce from "lodash.debounce";
import {
  EyeOutlined,
  EyeInvisibleOutlined,
  ExclamationCircleOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import styles from "./TextInputWelcome.module.sass";

function TextInputWelcome(props) {
  const { id, label, onChange, inputType, ...inputProps} = props;
  const s = styles;
  const [isFocused, setIsFocused] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [inputTouched, setInputTouched] = useState(false);


  const handleFocus = () => {
    setIsFocused(true);
  };
  const handleBlur = () => {
    setIsFocused(false);
    setInputTouched(true);
  };

  const handleOnChange = (e) => {
    setInputTouched(false);
    if (onChange) {
      onChange(e);
    }
    debouncedHandleOnChange()
  };

  const inputState = () =>{
    setInputTouched(true);
  }
  function showPassword(flagShow) {
    setIsPasswordVisible(!isPasswordVisible);
    inputProps.MDPasswordVisibility(flagShow);
  }
  const debouncedHandleOnChange = useMemo(
    () => debounce(inputState, 1250),
    []
  );  
  return (
    <div className={s.inputContainer}>
      <label htmlFor={id}>{label}</label>
      <div className={`${s.inputWrapper} ${(inputProps.hasPasswordError || inputProps.hasEmailError || inputProps.usrNameHasError || inputProps.hasConfirmError) && inputTouched  ? s.inputWrapperError : ''} ${(inputProps.validEmail && inputTouched) ? s.validEmail : ''}`}>
        <input
          id={id}
          type={isPasswordVisible || inputType != "password" ? "text" : "password"}
          onFocus={handleFocus}
          onBlur={handleBlur}
          {...inputProps}
          onChange={(e) => handleOnChange(e)}
        />

        {inputType == "password"&& 
          ((inputProps.hasPasswordError && inputTouched) ? (
            <ExclamationCircleOutlined className={`${s.errorIcon}`} />
          ) : isPasswordVisible ? (
            <EyeOutlined
              className={s.icon}
              onClick={() => showPassword(false)}
            />
          ) : (
            <EyeInvisibleOutlined
              className={s.icon}
              onClick={() => showPassword(true)}
            />
          ))}

        {(inputType == "email" || inputType == "emailConfirm" ) && inputProps.validEmail && inputTouched && <CheckOutlined className={s.validIcon} />}
        {((inputType == "email" && inputProps.hasEmailError ) || (inputType == "emailConfirm" && inputProps.hasConfirmError ) || (inputType == "usrName" && inputProps.usrNameHasError )) && inputTouched && (
          <ExclamationCircleOutlined className={`${s.errorIcon}`} />
        )}

      </div>
        {inputType == "usrName" && (isFocused || (inputProps.usrNameHasError && inputTouched) ) && <p className={`${(inputProps.usrNameHasError && inputTouched) ? s.errorText : ""}`}>{(inputProps.usrNameHasError && inputTouched) ? inputProps.errorMsj : "Ingresa tu nombre y apellido"}</p>}

        {inputType == "password" && inputProps.validConfirmPassword && inputTouched && <div className={s.checkListItem}>
        <CheckOutlined
          className={`${s.icon} ${s.iconValid}`}
        />
        <p>{inputProps.successMsj}</p>
      </div>}

        {inputType == "password" && inputProps.hasPasswordError && inputTouched && (
          <p className={s.errorText}>{inputProps.errorMsj}</p>
        )}
        {inputType == "email" && inputProps.hasEmailError && inputTouched && (
          <p className={s.errorText}>{inputProps.errorMsj}</p>
        )}
        {inputType == "emailConfirm" && inputProps.hasConfirmError && inputTouched && (
          <p className={s.errorText}>{inputProps.errorMsj}</p>
        )}
    </div>
  );
}

export default TextInputWelcome;
