import React, { useEffect } from "react";
import TagManager from "react-gtm-module";
import { connect } from "react-redux";
import BaseMD from "../../../google-analytics/events/baseMD";
import automaticSave from "../../../images/InformationCards/automaticSave.svg";
import building from "../../../images/InformationCards/building.svg";
import catalog from "../../../images/InformationCards/catalog.svg";
import documentBag from "../../../images/InformationCards/documentBag.svg";
import documentID from "../../../images/InformationCards/documentID.svg";
import documentImage from "../../../images/InformationCards/documentImage.svg";
import documentValid from "../../../images/InformationCards/documentValid.svg";
import legalData from "../../../images/InformationCards/legalData.svg";
import logistic from "../../../images/InformationCards/logistic.svg";
import pendingApproval from "../../../images/InformationCards/pendingApproval.svg";
import protectedData from "../../../images/InformationCards/protectedData.svg";
import { InformationCard } from "../../InformationCard/InformationCard";
import { Login } from "../Login/Login";
import Register from "../Register/Register";
import styles from "./Welcome.module.sass";

function Component() {
  const s = styles;
  const [isLogin, setIsLogin] = React.useState(true);
  function handleClick() {
    setIsLogin(!isLogin);
    if (isLogin) {
      TagManager.dataLayer(BaseMD.MD2D());
    } else {
      TagManager.dataLayer(BaseMD.MD4DWelcome());
    }
  }

  useEffect(() => {
    TagManager.dataLayer(BaseMD.MD2());
  }, []);

  return (
    <div className={s.mainSection}>
      <div className={s.mainForm}>
        <img
          style={{ marginBottom: "2rem" }}
          className={s.logoCoppelpay}
          alt="Logo de Coppel Pay"
          src={process.env.REACT_APP_LOGO_COPPELPAY}
        />

        {isLogin ? (
          <div>
            <h2 className={s.titleh2}>
              Inicia sesión para dar de alta tu negocio
            </h2>

            <Login />
            <p className={s.bodyText}>
              ¿Aún no tienes cuenta?{" "}
              <button
                type="button"
                className={s.buttonText}
                onClick={handleClick}
              >
                Regístrate
              </button>
            </p>
          </div>
        ) : (
          <div>
            <h2 className={s.titleh2}>
              Regístrate para dar de alta tu negocio
            </h2>
            <Register />
            <p className={s.bodyText}>
              ¿Ya tienes una cuenta?{" "}
              <button
                type="button"
                className={s.buttonText}
                onClick={handleClick}
              >
                Inicia sesión
              </button>
            </p>
          </div>
        )}
      </div>

      <h2
        className={s.titleh2}
        style={{ marginTop: "2rem", marginBottom: "4rem" }}
      >
        Requisitos para darte de alta:
      </h2>
      <div className={s.container}>
        <div className={s.physical}>
          <h4 className={s.titleh4}>Personas físicas</h4>

          <InformationCard
            imageSrc={documentValid}
            title="Constancia de Situación Fiscal del contribuyente"
            description="Formato PDF, no mayor a 1 año"
          />
          <InformationCard
            imageSrc={documentBag}
            title="Estado de cuenta"
            description="Formato PDF, no mayor a 3 meses"
          />
          <InformationCard
            imageSrc={documentID}
            title="Identificación oficial del representante 
            legal o contribuyente"
            description="Una fotografía de ambos lados de tu credencial de elector o pasaporte vigentes (no escaneados)"
          />
          <InformationCard
            imageSrc={documentImage}
            title="Logotipo"
            description="Formato PNG, sin fondo, medidas 100 x 100 px"
          />
        </div>
        <div className={s.divider} />
        <div className={s.moral}>
          <h4 className={s.titleh4}>Personas morales</h4>

          <InformationCard
            imageSrc={documentValid}
            title="Constancia de Situación Fiscal de la empresa"
            description="Formato PDF, no mayor a 1 año"
          />
          <InformationCard
            imageSrc={documentBag}
            title="Estado de cuenta"
            description="Formato PDF, no mayor a 3 meses"
          />
          <InformationCard
            imageSrc={documentID}
            title="Identificación oficial del representante 
            legal o contribuyente"
            description="Una fotografía de ambos lados de tu credencial de elector o pasaporte vigentes (no escaneados)"
          />
          <InformationCard
            imageSrc={documentImage}
            title="Logotipo"
            description="Formato PNG, sin fondo, medidas 100 x 100 px"
          />
        </div>
      </div>

      <br />
      <div className={s.additionalData}>
        <h3 className={s.titleh3}>
          Adicionalmente para ambos casos necesitarás:
        </h3>
        <InformationCard
          imageSrc={catalog}
          title="Catálogo y venta"
          description="Categoría del producto o servicio y promedio de facturación mensual"
        />
        <InformationCard
          imageSrc={building}
          title="Datos de la empresa o del contribuyente"
          description="Régimen fiscal, domicilio y datos bancarios"
        />
        <InformationCard
          imageSrc={legalData}
          title="Datos del representante legal, socios y administradores"
          description="Nombres y RFC, solo para personas morales"
        />
        <InformationCard
          imageSrc={logistic}
          title="Información de logística"
          description="Dirección para envíos de material promocional y datos de contacto comercial"
        />
      </div>
      <div className={s.containerFyi}>
        <h3 className={s.titleh3}>Toma en cuenta:</h3>
        <div className={s.fyi}>
          <InformationCard
            imageSrc={protectedData}
            title="Tus datos están protegidos"
            description="Tus datos personales y legales se mantendrán protegidos en todo momento"
            isImageAtRight={false}
          />
          <InformationCard
            imageSrc={automaticSave}
            title="Guardado automático"
            description="Los cambios se podrán guardar para que puedas retomar el proceso en el momento que desees"
            isImageAtRight={false}
          />
          <InformationCard
            imageSrc={pendingApproval}
            title="Solicitud sujeta a aprobación"
            description="Recuerda que tu solicitud estará sujeta a aprobación"
            isImageAtRight={false}
          />
        </div>
      </div>
    </div>
  );
}

export const Welcome = connect()(Component);
