// redux
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// style
import TagManager from "react-gtm-module";
import styles from "./modalUploadProcess.module.sass";

// actions
import {
  updateInputMetamap,
  updateStepperLoader,
} from "../../../redux-modules/form/actions";

// scripts
import { returnDocumentsWithErrors } from "../../../scripts/returnDocumentsWithErrors";
import validateEveryDocument from "../../../scripts/validateEveryDocument";

// Custom Hooks and Components
import BaseMD from "../../../google-analytics/events/baseMD";
import validateAllDocumentsAreUploaded from "../../../scripts/validateAllDocumentsAreUploaded";
import BlueBtnForDocuments from "../../BlueBtnForDocuments/BlueBtnForDocuments";
import LightBlueBtn from "../../lightBlueBtn/LightBlueBtn";
import MetaViews from "./MetaViews";
import ViewsIcons from "./ViewsIcons";
import InfoDocuments from "./sections/InfoDocuments/InfoDocuments";
import useSetPosition from "./useSetPosition";
import saveState from "../../../scripts/saveState";

export default function ModalUploadProcess({ onSendMessage, messages }) {
  const dispatch = useDispatch();
  const documentsStore = useSelector((state) => state.documents);
  const currentSection = useSelector((state) => state.currentSection.name);
  const stateSave = useSelector((state) => state);
  const s = styles;
  // counter for steps
  const [counter, setCounter] = useState(0);

  const [currentStep, setCurrentStep] = useState(0);

  // current name of the path
  const [stateOfValidations, setStateOfValidations] = useState(false);
  const [reupload, setReupload] = useState(false);
  // documents order
  const pathINE = [
    "info",
    "CSF",
    "identificationSelection",
    "ineFront",
    "ineBack",
    "bankStatement",
    "logo",
  ];

  // documents order
  const pathPassport = [
    "info",
    "CSF",
    "identificationSelection",
    "passport",
    "bankStatement",
    "logo",
  ];

  const validatingPath = ["validating"];
  const [documentsToReUpload, setDocumentsToReUpload] = useState([
    "reuploadTheFollowingDocuments",
  ]);

  // name of sections in the path
  // must be a state to re-render the component when the path changes
  const [currentPosition, setCurrentPosition] = useState({
    name: pathINE[currentStep],
    maxLength: pathINE.length,
    type: "pathINE",
  });

  // after component mount we check the type of identification selected and set the path
  // of the steps accordingly
  let userTypeOfIdentificationSelected = "INE";

  useEffect(() => {
    useSetPosition(
      documentsStore,
      stateOfValidations,
      setCurrentPosition,
      setCurrentStep,
      currentStep,
      validatingPath,
      documentsToReUpload,
      pathINE,
      pathPassport
    );
  }, [documentsStore, currentStep, stateOfValidations]);

  // from the socket subscription we get this data
  // const [socketData, __setSocketData] = useState(messages);
  const socketData = messages;

  useEffect(() => {
    dispatch(updateStepperLoader({ count: counter }));
  }, [counter]);

  // this runs erevery socket update
  useEffect(() => {
    if (socketData) {
      userTypeOfIdentificationSelected =
        documentsStore.find((i) => i.name === "identificationSelection")?.value
          .selection || "INE";

      dispatch(
        updateInputMetamap({
          flowId: socketData.flowId,
          verificationId: socketData.verificationId,
          value: socketData,
        })
      );
      if (socketData.status) {
        // Solo se llamará cuando es texto
        setCounter(counter + 1);
      }

      // every time some document has completed his validation
      // we check if all documents are validated
      if (
        ((socketData.status === "verified" ||
          socketData.status === "rejected") &&
          userTypeOfIdentificationSelected === "INE" &&
          documentsStore.length === 6 &&
          validateAllDocumentsAreUploaded(documentsStore)) ||
        ((socketData.status === "verified" ||
          socketData.status === "rejected") &&
          userTypeOfIdentificationSelected === "passport" &&
          documentsStore.length === 5 &&
          validateAllDocumentsAreUploaded(documentsStore))
      ) {
        setTimeout(() => {
          if (validateEveryDocument(documentsStore)) {
            // all good
            setStateOfValidations("success");
          } else {
            // some documents are not valid
            setDocumentsToReUpload(() => {
              return [
                "reuploadTheFollowingDocuments",
                ...returnDocumentsWithErrors(documentsStore),
              ];
            });
            setStateOfValidations("reupload");
            setCurrentStep(0);
            setCounter(0);
            dispatch(updateStepperLoader({ count: 0 }));
            setReupload(true);
          }
        }, 2000);
      }
    }
  }, [socketData, messages]);

  const handleDocumentsFinalReupload = () => {
    setStateOfValidations("validating");
    if (
      (userTypeOfIdentificationSelected === "INE" &&
        documentsStore.length === 6 &&
        validateAllDocumentsAreUploaded(documentsStore)) ||
      (userTypeOfIdentificationSelected === "passport" &&
        documentsStore.length === 5 &&
        validateAllDocumentsAreUploaded(documentsStore))
    ) {
      if (validateEveryDocument(documentsStore)) {
        // all good
        setStateOfValidations("success");
      } else {
        // some documents are not valid
        setDocumentsToReUpload(() => {
          return [
            "reuploadTheFollowingDocuments",
            ...returnDocumentsWithErrors(documentsStore),
          ];
        });
        setStateOfValidations("reupload");
        setCurrentStep(0);
        setCounter(0);
        dispatch(updateStepperLoader({ count: 0 }));
        setReupload(true);
      }
    }
  };
  const handleFinalStep = () => {
    TagManager.dataLayer(BaseMD.MD3A(true, currentSection));
    handleDocumentsFinalReupload();
  };

  // this is the validation of the current step
  let stepCurrent = 0;
  if (currentPosition.name === "CSF") {
    stepCurrent = 1;
  }
  if (
    currentPosition.name === "identificationSelection" ||
    currentPosition.name === "ineFront" ||
    currentPosition.name === "ineBack" ||
    currentPosition.name === "passport"
  ) {
    stepCurrent = 2;
  }
  if (currentPosition.name === "bankStatement") {
    stepCurrent = 3;
  }
  if (currentPosition.name === "logo") {
    stepCurrent = 4;
  }
  const searchForDocument = (name) => {
    return documentsStore.find((i) => i.name === name);
  };

  useEffect(() => {
    saveState(stateSave);
  }, [stateSave.documents.length]);

  function deleteIneMemory() {
    for (let i = 0; i < documentsStore.length; i++) {
      if (documentsStore[i].name === "ineFront" || documentsStore[i].name === "ineBack") {
        documentsStore.splice(i, 1);
      }
    }
  }
  function handleIdentification(
    identificationSelection,
    counterState,
    currentStepState,
    currentPositionName
  ) {
    if (identificationSelection) {
      counterState += 1;
      const { selection } = identificationSelection.value;
      if (selection === "INE") {
        const ineFront = searchForDocument("ineFront");
        const ineBack = searchForDocument("ineBack");
        if (ineFront && ineBack) {
          currentStepState = 5;
          currentPositionName = "bankStatement";
        } else {
          currentStepState = 3;
          currentPositionName = "ineFront";
          deleteIneMemory();
        }
      } else if (selection === "passport") {
        const passport = searchForDocument("passport");
        if (passport) {
          currentStepState = 4;
          currentPositionName = "bankStatement";
        } else {
          currentStepState = 3;
          currentPositionName = "passport";
        }
      }
    }
    return { counterState, currentStepState, currentPositionName };
  }

  useEffect(() => {
    if (documentsStore.length === 0) {
      return;
    }
    let currentStepState = 0;
    let counterState = 0;
    let currentPositionName = "";
    const csf = searchForDocument("CSF");
    if (csf) {
      counterState += 1;
      currentStepState = 2;
      currentPositionName = "identificationSelection";
    }
    const identificationSelection = searchForDocument(
      "identificationSelection"
    );
    ({ counterState, currentStepState, currentPositionName } =
      handleIdentification(
        identificationSelection,
        counterState,
        currentStepState,
        currentPositionName
      ));
    const bankStatement = searchForDocument("bankStatement");
    if (bankStatement) {
      counterState += 1;
      currentStepState = 4;
      currentPositionName = "logo";
    }
    setCurrentStep(currentStepState);
    setCounter(counterState);
    if (
      identificationSelection &&
      identificationSelection.value.selection === "passport"
    ) {
      setCurrentPosition({
        name: currentPositionName,
        maxLength: pathPassport.length,
        type: "pathPassport",
      });
    } else {
      setCurrentPosition({
        name: currentPositionName,
        maxLength: pathINE.length,
        type: "pathINE",
      });
    }
    const logo = searchForDocument("logo");
    if (logo) {
      handleDocumentsFinalReupload();
    }
  }, []);
  return (
    <div className={s.overlay}>
      {currentPosition.name === "info" ? (
        <InfoDocuments
          goNextSection={() => {
            setCurrentStep(currentStep + 1);
          }}
        />
      ) : (
        <div className={s.modal}>
          <h1 className={s.title}>Documentos</h1>
          <div className={s.main}>
            <div className={s.image}>
              <ViewsIcons currentPosition={currentPosition} />
            </div>
            <MetaViews
              currentPosition={currentPosition}
              onSendMessage={onSendMessage}
              reupload={reupload}
            />
          </div>
          <div className={s.controls}>
            {currentPosition.name !== "validating" &&
            currentPosition.name !== "reuploadTheFollowingDocuments" &&
            currentPosition.name !== "success" ? (
              <LightBlueBtn
                extraClass={s.control__prev}
                handleOnClick={() => {
                  let pageName = "";
                  switch (currentPosition.name) {
                    case "CSF":
                      pageName = "Sube la Constancia de Situación Fiscal";
                      break;
                    case "identificationSelection":
                      pageName = "Selecciona un documento de identificación";
                      break;
                    case "ineFront":
                      pageName =
                        "Sube la parte delantera de tu credencial de elector";
                      break;
                    case "ineBack":
                      pageName = "Sube el reverso de tu credencial de elector";
                      break;
                    case "passport":
                      pageName = "Pasaporte";
                      break;
                    case "bankStatement":
                      pageName = "Sube tu estado de cuenta";
                      break;
                    case "logo":
                      pageName = "Logo";
                      break;
                    case "success":
                      pageName = "Validamos tus documentos";
                      break;
                    default:
                      pageName = "Error";
                  }
                  TagManager.dataLayer(BaseMD.MD3A(false, pageName));
                  if (currentStep > 0) {
                    setCurrentStep(currentStep - 1);
                  }
                }}
                text="Regresar"
              />
            ) : (
              <div />
            )}

            {stepCurrent !== 0 && (
              <div className={s.stepper}>
                <div className={s.stepList}>
                  <li className={s.stepItem}>
                    <span
                      className={`${s.stepNumber} ${
                        stepCurrent === 1 ? s.activo : null
                      }`}
                    >
                      {" "}
                    </span>
                  </li>
                  <li className={s.stepItem}>
                    <span
                      className={`${s.stepNumber} ${
                        stepCurrent === 2 ? s.activo : null
                      }`}
                    >
                      {" "}
                    </span>
                  </li>
                  <li className={s.stepItem}>
                    <span
                      className={`${s.stepNumber} ${
                        stepCurrent === 3 ? s.activo : null
                      }`}
                    >
                      {" "}
                    </span>
                  </li>
                  <li className={s.stepItem}>
                    <span
                      className={`${s.stepNumber} ${
                        stepCurrent === 4 ? s.activo : null
                      }`}
                    >
                      {" "}
                    </span>
                  </li>
                </div>
              </div>
            )}

            {currentPosition.name !== "validating" && (
              <BlueBtnForDocuments
                extraClass={s.control__next}
                currentStep={currentStep}
                currentPosition={currentPosition}
                setCurrentStep={setCurrentStep}
                validatingPath={validatingPath}
                pathINE={pathINE}
                pathPassport={pathPassport}
                pathReupload={documentsToReUpload}
                handleFinalStep={handleFinalStep}
              >
                Continuar
              </BlueBtnForDocuments>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
