import React from 'react'
import styles from './LoaderModal.module.sass'

const LoaderModal = ({ tittle }) => {
    const s = styles
    return (
        <>
            <div>
                <div className={s.header}>
                    <div className={s.loader}>
                        <svg viewBox="0 0 400 400" className={s.spinner}>
                            <g transform="translate(200, 200)">
                                <circle cx="0" cy="0" r="100"></circle>
                            </g>
                        </svg>
                    </div>
                    <h2 className={s.tittle}>{tittle}</h2>
                </div>
            </div>
        </>
    )
}

export default LoaderModal