import {
  getDataFromReduxBankAccount,
  getDataFromReduxTypePerson,
} from "../../../scripts/getDataFromRedux";

export const personaMoral = (state) => {
  const moralBankData = getDataFromReduxBankAccount(state);
  const moralData = getDataFromReduxTypePerson(state);
  const nomComercial =
    moralData.proofTaxSituation.nameCom || moralData.proofTaxSituation.name;
  return {
    moral: {
      rfc: moralData.proofTaxSituation.rfc,
      razonSocial: moralData.proofTaxSituation.name,
      nombreComercial: nomComercial,
      nombreTitular: moralBankData.name,
      banco: moralBankData.bankName,
      clabe: moralBankData.clabeInterbancaria.replace(/\s+/g, ""),
      cuenta: moralBankData.numeroCuenta,
    },
  };
};

export const personaFisica = (state) => {
  const fisicaData = getDataFromReduxBankAccount(state);

  return {
    fisica: {
      nombreTitular: fisicaData.name,
      banco: fisicaData.bankName,
      clabe: fisicaData.clabeInterbancaria.replace(/\s+/g, ""),
      cuenta: fisicaData.numeroCuenta,
    },
  };
};

export const tipoPersona = (state) => {
  const typeOfPerson = getDataFromReduxTypePerson(state);

  function removeAccents(str) {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }

  const CSFReg = removeAccents(
    typeOfPerson.proofTaxSituation.regimen.toUpperCase()
  );

  const catRegPM = [
    "REGIMEN GENERAL DE LEY PERSONAS MORALES",
    "PERSONAS MORALES CON FINES NO LUCRATIVOS",
    "SOCIEDADES COOPERATIVAS DE PRODUCCION QUE OPTAN POR DIFERIR SUS INGRESOS",
    "ACTIVIDADES AGRICOLAS, GANADERAS, SILVICOLAS Y PESQUERAS",
    "OPCIONAL PARA GRUPOS DE SOCIEDADES",
    "COORDINADOS",
  ];

  const catRegPF = [
    "PERSONAS FISICAS CON ACTIVIDADES EMPRESARIALES Y PROFESIONALES",
    "REGIMEN DE LAS ACTIVIDADES EMPRESARIALES CON INGRESOS A TRAVES DE PLATAFORMAS TECNOLOGICAS",
    "SUELDOS Y SALARIOS E INGRESOS ASIMILADOS A SALARIOS",
    "REGIMEN DE SUELDOS Y SALARIOS E INGRESOS ASIMILADOS A SALARIOS",
    "ARRENDAMIENTO",
    "REGIMEN DE ENAJENACION O ADQUISICION DE BIENES",
    "REGIMEN DE LOS INGRESOS POR OBTENCION DE PREMIOS",
    "REGIMEN DE LOS INGRESOS POR DIVIDENDOS (SOCIOS Y ACCIONISTAS)",
    "REGIMEN DE LOS INGRESOS POR INTERESES",
    "DEMAS INGRESOS",
    "SIN OBLIGACIONES FISCALES",
    "INCORPORACION FISCAL",
    "DE LOS REGIMENES FISCALES PREFERENTES Y DE LAS EMPRESAS MULTINACIONALES",
  ];

  if (catRegPM.includes(CSFReg)) {
    return 1;
  }
  if (catRegPF.includes(CSFReg)) {
    return 2;
  }
  return 3;
};
