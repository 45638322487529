/**
 *  If value given is a string, it will be coverted to uppercase
 * @param {string} value - value to be converted to uppercase
 * @returns string with uppercase
 */
export function toUppercase(value) {
  if (typeof value === "string") {
    return value.toUpperCase();
  }
  return value;
}
