import React, { useState, useEffect } from "react";
import { CheckOutlined } from "@ant-design/icons";
import styles from "./PasswordCheckListValidation.module.sass";

const PasswordCheckListValidation = ({ clv, validation, validationDuplicated }) => {
  const s = styles;
  const [clvValue, setClvValue] = useState("");
  const [validations, setValidations] = useState({
    upper: false,
    number: false,
    minLength: false,
    especial: false,
  });

  useEffect(() => {
    setClvValue(clv);
  }, [clv]);

  const funCaracteresEspeciales = () => {
    const caracteresEspeciales = [];

    clvValue.split("").forEach((char) => {
      if (/[^A-Za-z0-9!\"#$%&()=_\-.,]/.test(char) || char === ' ') {
        caracteresEspeciales.push(char);
      }
    });

    if (caracteresEspeciales.length > 0) {
      return false;
    }

    return true;
  };

  useEffect(() => {
    setValidations({
      upper: /[A-Z]/.test(clvValue),
      number: /[0-9]/.test(clvValue),
      minLength: clvValue.length >= 8,
      especial: funCaracteresEspeciales() && /(.*)([!\"#$%&()=_\-.,]{1})(.*)/.test(clvValue),
      duplicated: !(/^(?!.*(.)\1{2})/.test(clvValue)),
    });
  }, [clvValue]);




  useEffect(() => {
    validationDuplicated(validations.duplicated);

    if (
      validations.upper &&
      validations.number &&
      validations.minLength &&
      validations.especial
    ) {
      validation(true);
    } else {
      validation(false);
    }
  }, [validations]);

  return (
    <div className={s.passwordValidationsChecklist}>
      <p className={s.checkListTittle}>Tu contraseña debe tener:</p>
      <div className={s.checkListItem}>
        <CheckOutlined
          className={`${s.icon} ${validations.upper ? s.iconValid : ""}`}
        />
        <p>Al menos una mayúscula</p>
      </div>
      <div className={s.checkListItem}>
        <CheckOutlined
          className={`${s.icon} ${validations.number ? s.iconValid : ""}`}
        />
        <p>Al menos un número</p>
      </div>
      <div className={s.checkListItem}>
        <CheckOutlined
          className={`${s.icon} ${validations.minLength ? s.iconValid : ""}`}
        />
        <p>Al menos 8 caracteres</p>
      </div>
      <div className={s.checkListItem}>
        <CheckOutlined
          className={`${s.icon} ${validations.especial ? s.iconValid : ""}`}
        />
        <p>Al menos un carácter especial (!”#$%&()=_-.,)</p>
      </div>
    </div>
  );
};

export default PasswordCheckListValidation;
