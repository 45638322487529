import axios from "axios";

export default async function postAssociate(params) {
  let result = false;
  
  await axios
    .post(process.env.REACT_APP_CREATE_ASSOCIATE_URL, params, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
    .then((response) => {
      result = response.status == 200 && response.data.status == "SUCCESS";
    })
    .catch((err) => {
      if (err.response){
          err={errorCode:err.response.data.error.errorCode, errorMessage:err.response.data.error.errorMessage, error:new Error(err)}
          throw err;
        }
      throw new Error(err);
    });
  return result;
}
