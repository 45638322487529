import React, { useEffect } from "react";
import TagManager from "react-gtm-module";
import { useDispatch, useSelector } from "react-redux";
import BaseMD from "../../../../../google-analytics/events/baseMD";
import { prevSection } from "../../../../../redux-modules/form/actions";
import BlueBtn from "../../../../BlueBtn/BlueBtn";
import LightBlueBtn from "../../../../lightBlueBtn/LightBlueBtn";
import styles from "./InfoDocuments.module.sass";

export default function InfoDocuments({ goNextSection }) {
  const s = styles;
  const dispatch = useDispatch();
  const currentSection = useSelector((state) => state.currentSection.name);
  const typeOfPersonId = useSelector((state) => state.typeOfPerson?.id);
  const officialData = useSelector((state) => state.officialData);
  useEffect(() => {
    TagManager.dataLayer(BaseMD.MD3(officialData, typeOfPersonId));
  }, []);
  return (
    <div className={s.infoDocuments}>
      <section className={s.contactForm}>
        <h1 className={s.title}>Documentos</h1>
        <h2 className={s.subtitle}>Sube los documentos</h2>
        <p className={s.extraInfo}>
          Validaremos que cumplan con las características necesarias para
          continuar con la solicitud
        </p>

        <p className={s.extraInfo}>Los documentos deben:</p>

        <ul className={s.recomendations}>
          <li>
            <strong>Ser originales y estar vigentes</strong>
          </li>
          <li>
            <strong>Verse con claridad</strong>
          </li>
          <li>
            <strong>Ser a color</strong> (no se validarán imágenes de fotocopias
            o en blanco y negro)
          </li>
          <li>
            <strong>Estar en formato PDF, JPG, JPEG o PNG</strong> (según
            corresponda)
          </li>
          <li>
            {" "}
            <strong>Pesar máximo 2 MB</strong>
          </li>
        </ul>
        <div className={s.nav}>
          <LightBlueBtn
            extraClass={s.btnFilesBack}
            handleOnClick={() => {
              if (
                confirm("¿Estás seguro que deseas salir? Perderás tu progreso")
              ) {
                TagManager.dataLayer(BaseMD.MD3A(false, "Sube los documentos"));
                dispatch(prevSection());
              }
            }}
            text="Regresar"
          />

          <BlueBtn
            text="Continuar"
            extraClass={s.btnFiles}
            handleOnClickBlueBtn={() => {
              TagManager.dataLayer(BaseMD.MD3A(true, "Sube los documentos"));
              goNextSection();
            }}
          />
        </div>
      </section>
    </div>
  );
}
