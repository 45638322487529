/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import TagManager from "react-gtm-module";
import { connect, useSelector } from "react-redux";
import BaseMD from "../../../google-analytics/events/baseMD";
import {
  nextSection,
  prevSection,
  verifyValidationsOfficialData,
} from "../../../redux-modules/form/actions";
import { DivisionLine } from "../../DivisionLine";
import { RadioMainSelector } from "../../inputs/RadioMainSelector";
import styles from "./OfficialData.module.sass";
import { MoralTaxpayer } from "./moralTaxpayer";
import { PhysicalTaxpayer } from "./physicalTaxpayer";
import { tipoPersona } from "./dataAutoFill";
import saveState from "../../../scripts/saveState";

function OfficialDataComponent({ state, dispatch }) {
  const s = styles;

  const [callValidation, setCallValidation] = useState(false);
  const [typeOfTaxpayer, setTypeOfTaxpayer] = useState(tipoPersona(state));
  const officialData = useSelector(() => state.officialData);
  const typeOfPersonId = useSelector(() => state.typeOfPerson?.id);
  const [render, setRender] = useState(false);

  useEffect(() => {
    TagManager.dataLayer(BaseMD.DM5(officialData, typeOfPersonId));
    if (typeOfTaxpayer === 1) {
      document.getElementById("Persona moraltypeOfPerson").click();
      document.getElementById(
        "Persona física con actividad empresarialtypeOfPerson"
      ).disabled = true;
      document.getElementById(
        "Persona física con actividad empresarial2"
      ).style.color = "#959595";
    }
    if (typeOfTaxpayer === 2) {
      document
        .getElementById("Persona física con actividad empresarialtypeOfPerson")
        .click();
      document.getElementById("Persona moraltypeOfPerson").disabled = true;
      document.getElementById("Persona moral1").style.color = "#959595";
    }
    setRender(true);
  }, []);

  const type = [
    {
      name: "Persona moral",
      id: 1,
    },
    {
      name: "Persona física con actividad empresarial",
      id: 2,
    },
  ];

  function handleOnChange(optionId) {
    setTypeOfTaxpayer(optionId);
    setCallValidation(false);
  }

  useEffect(() => {
    if (typeOfTaxpayer === 1) {
      TagManager.dataLayer(BaseMD.DM5A());
    } else if (typeOfTaxpayer === 2) {
      TagManager.dataLayer(BaseMD.DM5B());
    }
  }, [typeOfTaxpayer]);

  function goNextSection() {
    if (state.officialDataValidations === true) {
      TagManager.dataLayer(BaseMD.DM5J(officialData, typeOfTaxpayer));
      saveState(state);
      dispatch(nextSection());
    }
  }

  const altTitle =
    "Datos oficiales. Estás en el paso 2 de 5. Si eres persona moral necesitarás escribir datos del representante legal, socios y administradores de tu empresa. Si eres persona física necesitarás escribir tus datos fiscales incluyendo dirección.";

  return (
    <>
      <section className={s.contactForm}>
        <h1 className={s.title} alt={altTitle}>
          Datos oficiales
        </h1>
        <RadioMainSelector
          optionsList={type}
          title="¿Cuál es tu régimen fiscal?*"
          storeName="typeOfPerson"
          handleValueChange={(e) => {
            handleOnChange(e);
          }}
        />
        <div className={s.divisionLine} />
        <DivisionLine />
        {render && typeOfTaxpayer === 2 && (
          <PhysicalTaxpayer callValidation={callValidation} />
        )}
        {render && typeOfTaxpayer === 1 && (
          <MoralTaxpayer callValidation={callValidation} />
        )}
        {typeOfTaxpayer === 3 && <div className={s.whiteSpace} />}
      </section>
      <div className={s.heroControls}>
        <button
          type="button"
          aria-label="Regresar a la sección anterior"
          className={s.btnBack}
          onClick={() => {
            TagManager.dataLayer(BaseMD.DM5C(typeOfTaxpayer));
            saveState(state);
            dispatch(prevSection());
            setCallValidation(true);
          }}
        >
          <span className="arrow">🡠 </span>Regresar
        </button>
        <button
          aria-label="Continuar al llenado de Datos de la empresa"
          className={`${s.btnNext} ${
            state.officialDataValidations === true ? s.active : ""
          }`}
          type="button"
          onClick={() => {
            setCallValidation(true);
            dispatch(verifyValidationsOfficialData());
            goNextSection();
          }}
        >
          Continuar
        </button>
      </div>
    </>
  );
}

OfficialDataComponent.propTypes = {
  dispatch: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  state: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    state,
  };
}

export const OfficialData = connect(mapStateToProps)(OfficialDataComponent);
