/**
 * Recieves a file and validates if it is a PNG file
 * @param {File} file
 * @returns {string} error message if the file is not a PNG file
 * @returns {boolean} true if the file is a PNG file
 */
export default function validatePNG(file) {
  const isPNG = file.type === "image/png";

  if (!isPNG) {
    return "El archivo debe ser una imagen PNG";
  }

  return true;
}
