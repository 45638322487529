import React, { useState, useEffect } from "react";
import styles from "./ChangePassword.module.sass";
import { useMediaQuery } from "react-responsive";
import arrowLeftIcon from "../../../images/icons/arrow-left.svg";
import TextInputWelcome from "../../inputs/TextInputWelcome/TextInputWelcome";
import TagManager from "react-gtm-module";
import BaseMD from "../../../google-analytics/events/baseMD";
import PasswordCheckListValidation from "../../PasswordCheckListValidation/PasswordCheckListValidation";
import WelcomeBtn from "../../WelcomeBtn/WelcomeBtn";
import updateClv from "../../../scripts/updateClv";
import { ConfigProvider as ConfigProvider5 } from "antd-v5";
import { Modal } from "antd-v5";
import ModalError from "../../modals/ModalError/ModalError";
import SuccessModal from "../../modals/SuccessModal/SuccessModal";
import { useLocation } from "react-router-dom";
import ModalInfo from "../../modals/ModalInfo/ModalInfo";
import { useNavigate } from "react-router-dom";

const ChangePassword = () => {
  const s = styles;
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    setUuid(searchParams.get("uuid"));
    setEmail(searchParams.get("email"));
    TagManager.dataLayer(BaseMD.MD6UpdatePass());
  }, []);

  const [formData, setFormData] = useState({
    clv: "",
    confirmarClv: "",
  });

  const [uuid, setUuid] = useState("");
  const [email, setEmail] = useState("");

  const [validPassword, setValidPassword] = useState(false);
  const [validPasswordDuplicated, setValidPasswordDuplicated] = useState(false);
  const [validConfirmPassword, setValidConfirmPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(true);

  const [showModalError, setShowModalError] = useState(false);
  const [showModalTokenError, setShowModalTokenError] = useState(false);
  const [showModalSuccess, setShowModalSuccess] = useState(false);
  const toggleError = () => setShowModalError(!showModalError);
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    TagManager.dataLayer(BaseMD.MD6CUpdatePass());

    const body = {
      clv: formData.clv,
      email: email,
      iduRecuperarCredenciales: uuid,
    };

    const resp = await updateClv(body);
    setIsLoading(false);
    if (resp === 500) {
      setShowModalError(true);
      return;
    }
    if (resp.error) {
      if (resp.error.errorCode === 40081 || resp.error.errorCode === 40082) {
        TagManager.dataLayer(
          BaseMD.MD7ModalUpPass(
            "Modal: La solicitud para restablecer tu contraseña ha vencido "
          )
        );
        setShowModalTokenError(true);
        return;
      }
      setShowModalError(true);
      return;
    }
    TagManager.dataLayer(
      BaseMD.MD7ModalUpPass(
        "Modal: Tu contraseña ha sido cambiada exitosamente "
      )
    );
    setShowModalSuccess(true);
  };

  const handleOnChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    const passwordEquals = formData.clv === formData.confirmarClv;
    let timeout;
    clearTimeout(timeout);

    if (!validPassword || validPasswordDuplicated) {
      setBtnDisabled(true);
      return;
    }

    if (passwordEquals) {
      setValidConfirmPassword(true);
    } else {
      setValidConfirmPassword(false);
      setBtnDisabled(true);
    }

    if (validPassword && !validPasswordDuplicated && passwordEquals) {
      timeout = setTimeout(() => {
        setBtnDisabled(false);
      }, 1251);
    }

    return () => clearTimeout(timeout);
  }, [formData, validPassword, validPasswordDuplicated]);

  const handleRedirect = (event) => {
    const eventMapp = {
      volver: BaseMD.MD6AUpdatePass(),
      aceptarSuccess: BaseMD.MD7AModalUpPass(
        "Modal: Tu contraseña ha sido cambiada exitosamente - Aceptar"
      ),
      aceptarToken: BaseMD.MD7AModalUpPass(
        "Modal: La solicitud para restablecer tu contraseña ha vencido - Aceptar"
      ),
      cerrarSuccess: BaseMD.MD7AModalUpPass(
        "Modal: Tu contraseña ha sido cambiada exitosamente - Cerrar"
      ),
      cerrarToken: BaseMD.MD7AModalUpPass(
        "Modal: La solicitud para restablecer tu contraseña ha vencido - Cerrar"
      ),
    };

    if (eventMapp[event]) {
      TagManager.dataLayer(eventMapp[event]);
    }

    navigate("/");
  };

  return (
    <>
      <ConfigProvider5 prefixCls="ant5">
        <Modal
          open={showModalError}
          footer={null}
          onCancel={toggleError}
          centered
          closeIcon={null}
          width={600}
          maskClosable={false}
        >
          <ModalError toggle={toggleError} MD="MD7ModalUpPass" MDEvent="MD7AModalUpPass" />
        </Modal>
        <Modal
          open={showModalSuccess}
          footer={null}
          onCancel={() => {
            handleRedirect("cerrarSuccess");
          }}
          centered
          closeIcon={null}
          width={700}
          maskClosable={false}
        >
          <SuccessModal
            toggle={() => {
              handleRedirect("cerrarSuccess");
            }}
            tittle={"Tu contraseña ha sido cambiada exitosamente"}
          >
            <p>Ahora puedes continuar con el proceso de alta de tu negocio</p>
            <div className={s.modalBtn}>
              <WelcomeBtn
                tittle="Aceptar"
                disabled={false}
                onClick={() => {
                  handleRedirect("aceptarSuccess");
                }}
              />
            </div>
          </SuccessModal>
        </Modal>
        <Modal
          open={showModalTokenError}
          footer={null}
          onCancel={() => {
            handleRedirect("cerrarToken");
          }}
          centered
          closeIcon={null}
          width={620}
          maskClosable={false}
        >
          <ModalInfo
            toggle={() => {
              handleRedirect("cerrarToken");
            }}
            tittle={"La solicitud para restablecer tu contraseña ha vencido"}
            center={true}
          >
            <div className={s.childrenModal}>
              <p>
                Solicita nuevamente el correo de restablecimiento de contraseña. 
              </p>
              <p>
                Asegúrate de utilizar el último que recibiste
              </p>
              <div className={s.modalBtn}>
                <WelcomeBtn
                  tittle="Aceptar"
                  disabled={false}
                  onClick={() => {
                    handleRedirect("aceptarToken");
                  }}
                />
              </div>
            </div>
          </ModalInfo>
        </Modal>
      </ConfigProvider5>

      <div className={s.container}>
        <div className={s.btnRegresarContainer}>
          <button
            type="button"
            className={s.btnRegresar}
            onClick={() => {
              handleRedirect("volver");
            }}
          >
            <img src={arrowLeftIcon} alt="Ir al inicio" />{" "}
            {isMobile ? "" : "Ir al inicio"}
          </button>
        </div>
        <div className={s.changePasswordForm}>
          <div className={s.imageContainer}>
            <img
              alt="Logo de Coppel Pay."
              src={process.env.REACT_APP_LOGO_COPPELPAY}
              width="324"
              height="154"
              className={s.imageLogo}
            />
          </div>
          <h2 className={s.title}>Ingresa tu nueva contraseña</h2>
          <form onSubmit={handleSubmit}>
            <TextInputWelcome
              id="clv"
              label="Contraseña"
              name="clv"
              value={formData.clv}
              onChange={handleOnChange}
              placeholder="Escribe tu contraseña"
              inputType="password"
              errorMsj="Máximo 2 caracteres iguales continuos"
              hasPasswordError={validPasswordDuplicated}
              MDPasswordVisibility={(flagShow) => {
                TagManager.dataLayer(BaseMD.MD6BUpdatePass(flagShow));
              }}
            />

            {formData.clv.length > 0 && (
              <div className={s.passwordCheckListValidation}>
                <PasswordCheckListValidation
                  clv={formData.clv}
                  validation={setValidPassword}
                  validationDuplicated={setValidPasswordDuplicated}
                />
              </div>
            )}

            <TextInputWelcome
              id="clvConfirmar"
              label="Confirma contraseña"
              name="confirmarClv"
              value={formData.confirmarClv}
              onChange={handleOnChange}
              placeholder="Escribe tu contraseña"
              inputType="password"
              errorMsj="Las contraseñas deben coincidir"
              successMsj="Las contraseñas coinciden"
              hasPasswordError={!validConfirmPassword}
              validConfirmPassword={validConfirmPassword}
              MDPasswordVisibility={(flagShow) => {
                TagManager.dataLayer(BaseMD.MD6BUpdatePass(flagShow));
              }}
            />

            <div className={s.btnContainer}>
              <WelcomeBtn
                tittle="Cambiar contraseña"
                disabled={btnDisabled}
                onClick={handleSubmit}
                loading={isLoading}
              />
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
