import loadable from "@loadable/component";
import { Steps } from "antd";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import TagManager from "react-gtm-module";
import { connect, useDispatch } from "react-redux";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Modal, ConfigProvider as ConfigProvider5 } from "antd-v5";
import { Header } from "../Header";
import { RecoveryClv } from "../sections/RecoveryClv/RecoveryClv";
import SectionSwitch from "../SectionSwitch/SectionSwitch";
import styles from "./App.module.sass";
import ModalInfo from "../modals/ModalInfo/ModalInfo";
import ChangePassword from "../sections/ChangePassword/ChangePassword";
import { saveStateOnServer } from "../../redux-modules/form/actions";

const Footer = loadable(() => import("../Footer"), {
  resolveComponent: (component) => component.Footer,
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <SectionSwitch />,
  },
  {
    path: "/forget-password",
    element: <RecoveryClv />,
  },
  {
    path: "/resetPassword",
    element: <ChangePassword />,
  },
]);
function App({ state }) {
  const s = styles;
  const { Step } = Steps;
  const currentSection = state.currentSection.value;
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  let inactivityTimer;

  function resetTimer() {
    clearTimeout(inactivityTimer);
    inactivityTimer = setTimeout(function () {
      dispatch(saveStateOnServer());
    }, process.env.REACT_APP_TIME_OF_INACTIVITY); // 30 segundos en milisegundos
  }
  async function confirmExit(e) {
    e.preventDefault();
    // Implementar aqui el guardado de datos
    try {
      dispatch(saveStateOnServer());
      // se codifico el modal auxiliar para el caso en el que se informe al usuario que su proceso se ha guardado correctamente setShowModal(true);
    } catch (error) {
      return "You have attempted to leave this page. Are you sure?";
    }
    return "";
  }
  useEffect(() => {
    if (
      currentSection > 1 &&
      localStorage.getItem("eventBeforeunloadCharged") !== true
    ) {
      localStorage.setItem("eventBeforeunloadCharged", true);
      resetTimer();
      window.addEventListener("beforeunload", (e) => {
        return confirmExit(e);
      });
      window.addEventListener("unload", (e) => {
        return confirmExit(e);
      });
      document.addEventListener("mousemove", resetTimer);
      document.addEventListener("keypress", resetTimer);
    } else if (currentSection <= 1) {
      localStorage.setItem("eventBeforeunloadCharged", false);
    }
  }, [currentSection]);

  useEffect(() => {
    const tagManagerArgs = {
      gtmId: "GTM-PHSGLW7",
    };
    TagManager.initialize(tagManagerArgs);
  }, []);

  const showModalSaved = () => {
    setShowModal(false);
  };

  return (
    <div className={`${currentSection === 0 ? s.bgWhite : s.bgGray}`}>
      <ConfigProvider5 prefixCls="ant5">
        <Modal
          open={showModal}
          footer={null}
          onCancel={showModalSaved}
          centered
          closeIcon={null}
          width={700}
          maskClosable={false}
        >
          <ModalInfo toggle={showModalSaved} tittle="ATENCION:">
            <div className={s.list}>
              <ul>
                <li>
                  Los cambios {!localStorage.getItem("isSaved") ? "NO " : ""}se
                  han guardado correctamente.
                </li>
              </ul>
            </div>
          </ModalInfo>
        </Modal>
      </ConfigProvider5>
      {/* Aqui verifica si es la pantalla de welcome para cambiar el header */}
      <div className={s.rootContent}>
        <Header isWelcome={currentSection === 0} />

        <main
          className={`${s.hero} ${currentSection === 0 ? s.bgWhite : s.bgGray}`}
        >
          {currentSection !== 0 && currentSection !== 6 ? (
            <div className={s.steps}>
              <Steps size="small" current={currentSection - 1}>
                <Step title="Documentos" />
                <Step title="Contactos" />
                <Step title="Datos oficiales" />
                <Step title="Datos de la empresa" />
                <Step title="Información comercial" />
              </Steps>
            </div>
          ) : null}
          <div className={s.heroForm}>
            <RouterProvider router={router} />
          </div>
        </main>

        <Footer />
      </div>
    </div>
  );
}

App.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  state: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    state,
  };
}

export default connect(mapStateToProps)(App);
