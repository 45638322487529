import React, { useEffect, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useDispatch } from "react-redux";
import { nextSection } from "../../../redux-modules/form/actions";
import TagManager from "react-gtm-module";
import BaseMD from "../../../google-analytics/events/baseMD";

import TextInputWelcome from "../../inputs/TextInputWelcome/TextInputWelcome";
import WelcomeBtn from "../../WelcomeBtn/WelcomeBtn";
import PasswordCheckListValidation from "../../PasswordCheckListValidation/PasswordCheckListValidation";
import styles from "./Register.module.sass";
import createLead from "../../../scripts/createLead";
import { ConfigProvider as ConfigProvider5 } from "antd-v5";
import { Modal } from "antd-v5";
import ModalInfo from "../../modals/ModalInfo/ModalInfo";
import ModalError from "../../modals/ModalError/ModalError";
import lock from "./lock.png";

const Register = () => {
  const s = styles;
  const captcha = useRef(null);
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    name: "",
    correo: "",
    confirmEmail: "",
    contraseña: "",
  });
  const [emailHasError, setEmailHasError] = useState(false);
  const [validPassword, setValidPassword] = useState(false);
  const [validPasswordDuplicated, setValidPasswordDuplicated] = useState(false);
  const [confirmEmailHasError, setConfirmEmailHasError] = useState(false);
  const [validEmail, setValidEmail] = useState(false);
  const [correctEmail, setCorrectEmail] = useState(false);
  const [validName, setValidName] = useState(false);
  const [checkBoxTerms, setCheckBoxTerms] = useState(false);
  const [imNotARobot, setImNotARobot] = useState(false);
  const [duplicateduser, setDuplicatedUser] = useState(false);

  const [btnDisabled, setBtnDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalError, setShowModalError] = useState(false);
  const toggleError = () => setShowModalError(!showModalError);

  let inputs = [
    {
      id: "name",
      label: "Nombre completo",
      name: "name",
      placeholder: "Escribe tu nombre",
      inputType: "usrName",
      errorMsj: "Debes ingresar tu nombre y apellido",
      usrNameHasError: !validName,
    },
    {
      id: "correo",
      label: "Correo",
      name: "correo",
      placeholder: "Escribe tu correo",
      inputType: "email",
      errorMsj: "Ingresa un formato de correo válido",
      hasEmailError: emailHasError,
      validEmail: correctEmail,
    },
    {
      id: "confirmEmail",
      label: "Confirma tu correo",
      name: "confirmEmail",
      placeholder: "Escribe tu correo",
      inputType: "emailConfirm",
      errorMsj: "El correo debe coincidir",
      hasConfirmError: !validEmail,
      validEmail: validEmail,
    },
    {
      id: "contraseña",
      label: "Contraseña",
      name: "contraseña",
      placeholder: "Escribe tu contraseña",
      inputType: "password",
      errorMsj: "Máximo 2 caracteres iguales continuos",
      hasPasswordError: validPasswordDuplicated,
      MDPasswordVisibility: (flagShow) => {
        TagManager.dataLayer(BaseMD.MD4ARegister(flagShow));
      },
    },
  ];

  useEffect(() => {
    TagManager.dataLayer(BaseMD.MD4Register());
  }, []);

  useEffect(() => {
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const isEmailValid = emailRegex.test(formData.correo);
    const areEmailsEqual = formData.correo.toLowerCase() === formData.confirmEmail.toLowerCase();

    setEmailHasError(!isEmailValid);
    setConfirmEmailHasError(!areEmailsEqual);

    if (isEmailValid) {
      setCorrectEmail(true);
    }else{
      setCorrectEmail(false);
    }

    if (isEmailValid && areEmailsEqual) {
      setValidEmail(true);
    } else {
      setValidEmail(false);
    }
  }, [formData.correo, formData.confirmEmail]);

  useEffect(() => {
    let validName = formData.name.trim().replace(/\s\s+/g, " ");

    if (validName < 1) {
      setValidName(false);
      return;
    }
    if (validName.split(" ").length < 2) {
      setValidName(false);
      return;
    }
    if (
      validName.split(" ")[0].length < 1 ||
      validName.split(" ")[1].length < 1
    ) {
      setValidName(false);
      return;
    }
    setValidName(true);
  }, [formData.name]);

  const handleOnChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleCheckChange = (e) => {
    TagManager.dataLayer(BaseMD.MD4BRegister("Terminos y condiciones"));
    setCheckBoxTerms(e.target.checked);
  };

  const handleRecaptcha = () => {
    TagManager.dataLayer(BaseMD.MD4BRegister("reCaptcha"));
    if (captcha.current.getValue()) {
      setImNotARobot(true);
    } else {
      setImNotARobot(false);
    }
  };

  useEffect(() => {
    if (
      validName &&
      validPassword &&
      !validPasswordDuplicated &&
      !emailHasError &&
      !confirmEmailHasError &&
      checkBoxTerms &&
      imNotARobot
    ) {
      setBtnDisabled(false);
    } else {
      setBtnDisabled(true);
    }
  }, [
    formData,
    checkBoxTerms,
    imNotARobot,
    validPassword,
    validPasswordDuplicated,
  ]);

  const handleSubmit = async (e) => {
    TagManager.dataLayer(BaseMD.MD4CRegister());
    setIsLoading(true);
    e.preventDefault();
    const body = {
      nombre: formData.name.trim().replace(/\s\s+/g, " "),
      email: formData.correo.toLowerCase(),
      clv: formData.contraseña,
    };
    const resp = await createLead(body);
    setIsLoading(false);
    if (resp === 500) {
      setShowModalError(true);
      return;
    }
    if (resp.error) {
      if (resp.error.errorCode === 40078) {
        setDuplicatedUser(true);
        setBtnDisabled(true);
        window.scrollTo(0, "smooth");
        return;
      }
      return;
    }
    setShowModal(true);
    TagManager.dataLayer(BaseMD.MD3Modal());
  };

  const handleCancel = () => {
    setShowModal(false);
    setShowModalError(false);
  };

  const handleRedirect = () => {
    TagManager.dataLayer(BaseMD.MD3AModal("Aceptar"));
    dispatch(nextSection());
    setShowModal(false);
  };

  const handleRedirectClose = () => {
    TagManager.dataLayer(BaseMD.MD3AModal("Cerrar"));
    dispatch(nextSection());
    setShowModal(false);
  }

  return (
    <>
      <ConfigProvider5 prefixCls="ant5">
        <Modal
          open={showModal}
          footer={null}
          onCancel={handleCancel}
          centered
          closeIcon={null}
          width={700}
          maskClosable = {false}
        >
          <ModalInfo
            toggle={handleRedirectClose}
            tittle={"Antes de comenzar toma en cuenta:"}
          >
            <div className={s.list}>
              <ul>
                <li>
                  Los cambios se podrán guardar para que retomes el proceso en
                  el momento que lo desees.
                </li>
                <li> Recuerda que tu solicitud estará sujeta a aprovación.</li>
              </ul>
            </div>
            <div className={s.modalBtn}>
              <WelcomeBtn
                tittle="Aceptar"
                disabled={false}
                onClick={handleRedirect}
              />
            </div>
          </ModalInfo>
        </Modal>
        <Modal
          open={showModalError}
          footer={null}
          onCancel={handleCancel}
          centered
          closeIcon={null}
          width={700}
        >
          <ModalError toggle={toggleError} tittle={"Coppel Pay se desconectó"}>
            <p>El sistema falló. Intenta de nuevo.</p>
            <div className={s.modalBtn}>
              <WelcomeBtn
                tittle="Aceptar"
                disabled={false}
                onClick={handleCancel}
              />
            </div>
          </ModalError>
        </Modal>
      </ConfigProvider5>

      <form onSubmit={handleSubmit}>
        {duplicateduser && (
          <div className={s.alertErrorContainer}>
            <div className={s.alertError}>
              <div className={s.col1}>
                <img src={lock} />
              </div>
              <div className={s.col2}>
                <p className={s.alert}>
                  No es posible continuar con tu registro, verifica la
                  información proporcionada
                </p>
              </div>
            </div>
          </div>
        )}
        <div className={s.inputsContainer}>
          {inputs.map((input) => (
            <TextInputWelcome
              key={input.id}
              {...input}
              value={formData[input.id]}
              onChange={handleOnChange}
            />
          ))}

          {formData.contraseña && (
            <div className={s.checkListValidation}>
              <PasswordCheckListValidation
                clv={formData.contraseña}
                validation={setValidPassword}
                validationDuplicated={setValidPasswordDuplicated}
              />
            </div>
          )}
        </div>
        <div className={s.checkboxWrapper}>
          <input
            type="checkbox"
            style={{ width: "24px", height: "24px" }}
            onChange={(e) => {
              handleCheckChange(e);
            }}
          />
          <p>
            He leido y acepto los{" "}
            <a
              href="https://centrodeayuda.coppel.com/s/seguridad-y-privacidad?language=es_MX"
              target="_blank"
              rel="noopener noreferrer"
            >
              términos y condiciones
            </a>
          </p>
        </div>
        <div className={s.recaptchaContainer}>
          <ReCAPTCHA
            ref={captcha}
            sitekey="6Lfk0d8eAAAAAM2w75cCicnMWmEhrUvu72gHNR_2"
            onChange={() => {
              handleRecaptcha();
            }}
          />
        </div>
        <div className={s.btnContainer}>
          <WelcomeBtn
            tittle="Continuar"
            disabled={btnDisabled}
            onClick={handleSubmit}
            loading={isLoading}
          />

        </div>
      </form>
    </>
  );
};

export default Register;
