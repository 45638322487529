function getValueFromMetamapArrays(myArray, nameKey) {
  for (const element of myArray) {
    if (element === undefined || element == null) continue;
    if (element[nameKey] !== undefined) {
      return element[nameKey].value;
    }
  }
  return "";
}
export default async function getDataFromRedux(reduxState) {
  function searchInputInReduxStore(nameToSearch, state) {
    return state.documents.filter((item) => item.name === nameToSearch);
  }

  let inputIne = searchInputInReduxStore("ineBack", reduxState);

  inputIne = inputIne.length > 0 ? inputIne[0].value.data : [];

  let inputPassport = searchInputInReduxStore("passport", reduxState);
  inputPassport = inputPassport.length > 0 ? inputPassport[0].value.data : [];
  let inputProofTaxSituation = searchInputInReduxStore("CSF", reduxState);
  inputProofTaxSituation =
    inputProofTaxSituation.length > 0
      ? inputProofTaxSituation[0].value.data
      : [];
  const proofTaxSituation = {
    rfc: getValueFromMetamapArrays(
      inputProofTaxSituation,
      "custom-constancia-fiscal-rfc"
    ),
    name: getValueFromMetamapArrays(inputProofTaxSituation, "name"),
    eonomicActivity: getValueFromMetamapArrays(
      inputProofTaxSituation,
      "activity"
    ),
  };
  if (inputIne.length > 0) {
    return {
      fullName: getValueFromMetamapArrays(inputIne, "fullName"),
      dateBirth: getValueFromMetamapArrays(inputIne, "dateOfBirth"),
      proofTaxSituation,
      ine: {
        documentNumber: getValueFromMetamapArrays(inputIne, "documentNumber"),
        OCRNumber: getValueFromMetamapArrays(inputIne, "ocrNumber"),
      },
    };
  }

  return {
    fullName: getValueFromMetamapArrays(inputPassport, "fullName"),
    dateBirth: getValueFromMetamapArrays(inputPassport, "dateOfBirth"),
    proofTaxSituation,
  };
}

export function getDataFromReduxTypePerson(reduxState) {
  function searchInputInReduxStore(nameToSearch, state) {
    return state.documents.filter((item) => item.name === nameToSearch);
  }

  let inputProofTaxSituation = searchInputInReduxStore("CSF", reduxState);
  inputProofTaxSituation =
    inputProofTaxSituation.length > 0
      ? inputProofTaxSituation[0].value.data
      : [];
  const proofTaxSituation = {
    rfc: getValueFromMetamapArrays(
      inputProofTaxSituation,
      "custom-constancia-fiscal-rfc"
    ),
    name: getValueFromMetamapArrays(inputProofTaxSituation, "name"),
    nameCom: getValueFromMetamapArrays(inputProofTaxSituation, "nom-comercial"),
    regimen: getValueFromMetamapArrays(
      inputProofTaxSituation,
      "custom-constancia-fiscal-regimen"
    ),
  };

  return { proofTaxSituation };
}

export function getDataFromReduxBankAccount(reduxState) {
  function searchInputInReduxStore(nameToSearch, state) {
    return state.documents.filter((item) => item.name === nameToSearch);
  }
  // eslint-disable-next-line sonarjs/no-identical-functions

  let inputBankAccount = searchInputInReduxStore("bankStatement", reduxState);

  inputBankAccount =
    inputBankAccount.length > 0 ? inputBankAccount[0].value.data : [];

  return {
    name: getValueFromMetamapArrays(inputBankAccount, "name"),
    clabeInterbancaria: getValueFromMetamapArrays(
      inputBankAccount,
      "clabe-interbancaria"
    ),
    bankName: getValueFromMetamapArrays(inputBankAccount, "bank-name"),
    numeroCuenta: getValueFromMetamapArrays(inputBankAccount, "num-cuenta"),
  };
}
