export const categories = [
  {
    name: "Abarrotes",
    id: 1,
  },
  {
    name: "Automotriz",
    id: 2,
  },
  {
    name: "Consumo",
    id: 3,
  },
  {
    name: "Educación",
    id: 4,
  },
  {
    name: "Entretenimiento",
    id: 5,
  },
  {
    name: "Mejora del hogar (ferreterías, tlapalerías)",
    id: 6,
  },
  {
    name: "Minoristas",
    id: 7,
  },
  {
    name: "Salud",
    id: 8,
  },
  {
    name: "Seguros",
    id: 9,
  },
  {
    name: "Servicios",
    id: 10,
  },
  {
    name: "Viajes",
    id: 11,
  },
];
