/* eslint-disable react/jsx-no-comment-textnodes */
import { Select } from "antd";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import TagManager from "react-gtm-module";
import { connect, useSelector } from "react-redux";
import BaseMD from "../../../google-analytics/events/baseMD";
import {
  updateInput,
  updateInputStatus,
  verifyValidationsOfficialData,
} from "../../../redux-modules/form/actions";
import styles from "./SelectWithId.module.sass";

const { Option } = Select;
function SelectWithIdComponent({
  id,
  name,
  options,
  storeName,
  callValidation,
  state,
  dispatch,
  defaultValue,
  customRequiredMessage,
}) {
  const s = styles;
  const [errorMsg, setErrorMsg] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [selectedValue, setSelectedValue] = useState(defaultValue);
  const officialData = useSelector((state) => state.officialData);
  const typeOfPersonId = useSelector((state) => state.typeOfPerson?.id);
  function handleChange(value) {
    const payload = { name: storeName, value, validated: true };
    let categoryName = "";
    options.map((item) => {
      if (item.id === value) {
        categoryName = item.name;
      }
      return null;
    });

    TagManager.dataLayer(
      BaseMD.MD7H(officialData, typeOfPersonId, categoryName, "Categoría")
    );

    const payloadStatus = { name: storeName, validated: true };
    dispatch(updateInput(payload));
    dispatch(updateInputStatus(payloadStatus));
    setErrorMsg("");

    setSelectedValue(value);

    if (value !== defaultValue) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }

    const currentSection = state.currentSection.name;
    if (currentSection === "officialData") {
      dispatch(verifyValidationsOfficialData());
    }
  }

  const alreadyMounted = useRef(false);

  function searchInputInReduxStore(nameToSearch) {
    const currentSection = state.currentSection.name;
    return state[currentSection].find((item) => item.name === nameToSearch);
  }

  // bla bla bla..... call this when user click next button
  useEffect(() => {
    const section = state.currentSection.name;
    if (alreadyMounted.current) {
      state[section].map((inputItem) => {
        if (inputItem.name === storeName && inputItem.value === null) {
          if (customRequiredMessage) {
            setErrorMsg(customRequiredMessage);
          } else {
            setErrorMsg("Selecciona una opción");
          }
        }
        return null;
      });
    } else {
      const currentValue = searchInputInReduxStore(storeName);
      if (currentValue !== undefined && currentValue.value !== null) {
        // if value exist in redux store, set it in the input
        handleChange(currentValue.value);
      } else {
        // first render / prepare for next render
        const payload = { name: storeName, value: null };
        const payloadStatus = { name: storeName, validated: false };
        dispatch(updateInput(payload));
        dispatch(updateInputStatus(payloadStatus));
      }
      alreadyMounted.current = true;
    }
  }, [callValidation]);

  return (
    <div className={s.wrapper}>
      <div className={`${s.label} ${isVisible ? s.visible : s.hidden}`}>
        {name}
      </div>
      <Select
        value={selectedValue}
        name={name}
        id={id}
        defaultValue={selectedValue}
        style={{ width: "100%" }}
        onChange={(e) => {
          handleChange(e);
        }}
      >
        <Option value={defaultValue} hidden>
          {defaultValue}
        </Option>
        {options.map((item) => {
          return (
            <Option key={item.name + storeName} value={item.id}>
              {item.name}
            </Option>
          );
        })}
      </Select>
      <small className={s.error}>{errorMsg}</small>
    </div>
  );
}

SelectWithIdComponent.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  storeName: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  dispatch: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  state: PropTypes.object.isRequired,
  callValidation: PropTypes.bool,
  defaultValue: PropTypes.string.isRequired,
  customRequiredMessage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
};

SelectWithIdComponent.defaultProps = {
  callValidation: false,
  customRequiredMessage: false,
};

function mapStateToProps(state) {
  return {
    state,
  };
}

export const SelectWithId = connect(
  mapStateToProps,
  null
)(SelectWithIdComponent);
