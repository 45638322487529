import axios from 'axios';

const createLead = (data) => {
    return axios
        .post(process.env.REACT_APP_CREATE_LEAD, data)
        .then((response) => {
            if (response.status === 200 && response.data.status === "SUCCESS") {
                localStorage.setItem("token", response.data.data.token);
                localStorage.setItem("email", response.data.data.correo);
                return response.data;
            }
            return 500;
        })
        .catch(function (error) {
            if (error.response) {
              
                return error.response.data;
            } else if (error.request) {
              
                return 500;
            } else {
                return 500;
            }
          });

};

export default createLead; 