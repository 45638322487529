import React from "react";
import PropTypes from "prop-types";
import styles from "./InformationCard.module.sass";

/**
 * Renders an information card with an image and title.
 * @param {Object} props - The component props.
 * @param {string} props.imageSrc - The source URL for the image.
 * @param {string} props.title - The title of the card.
 * @param {string} props.description - The description of the card.
 * @param {boolean} props.isImageAtRight - Determines if the image is at the right or top of the card.
 * @returns {JSX.Element} - The rendered component.
 */
export function InformationCard({
  imageSrc,
  title,
  description,
  isImageAtRight,
}) {
  return (
    <div className={styles.container}>
      <div
        className={isImageAtRight ? styles.cardRightImage : styles.cardTopImage}
      >
        <img className={styles.image} src={imageSrc} alt={title} />
        <div className={styles.body}>
          <h4 className={styles.title}>{title}</h4>
          <p className={styles.description}>{description}</p>
        </div>
      </div>
    </div>
  );
}

InformationCard.propTypes = {
  imageSrc: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  isImageAtRight: PropTypes.bool,
};
InformationCard.defaultProps = {
  isImageAtRight: true,
};
