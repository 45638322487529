// redux
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

// style
import { ExclamationCircleFilled } from "@ant-design/icons";
import TagManager from "react-gtm-module";
import { Modal, ConfigProvider as ConfigProvider5 } from "antd-v5";
import styles from "./Login.module.sass";
// Custom Hooks and Components
import WelcomeBtn from "../../WelcomeBtn/WelcomeBtn";
import TextInputWelcome from "../../inputs/TextInputWelcome/TextInputWelcome";
import autenticateLead from "../../../scripts/login";
import { replaceState, nextSection } from "../../../redux-modules/form/actions";

import LoginMD from "../../../google-analytics/events/LoginMD";

import ModalInfo from "../../modals/ModalInfo/ModalInfo";
import ModalError from "../../modals/ModalError/ModalError";

export function Login() {
  const s = styles;
  const [validPassword, setValidPassword] = useState(false);
  const [validEmail, setValidEmail] = useState(false);
  const [incorrectCredentials, setIncorrectCredentials] = useState(false);
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [showModalError, setShowModalError] = useState(false);

  const [newState, setNewState] = useState(null);
  const navigate = useNavigate();

  function setState() {
    TagManager.dataLayer(LoginMD.MD2Unregistered());
    if (newState === null) {
      dispatch(nextSection());
    } else {
      dispatch(dispatch(replaceState(newState)));
    }
  }
  function validateEmail(email) {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  }
  function validatePassword(password) {
    return password.length >= 8;
  }
  async function onClickContinue() {
    let result;
    try {
      result = await autenticateLead({
        correo: document.getElementById("inputCorreo").value,
        clave: document.getElementById("inputContraseña").value,
      });
    } catch (err) {
      result = {
        code: err.errorCode,
        message: err.errorMessage,
        error: err.error,
      };
    }

    switch (result.code) {
      case 200:
        setNewState(result.data.state);
        TagManager.dataLayer(LoginMD.MD3());
        setShowModal(true);
        break;
      case 40078:
        TagManager.dataLayer(
          LoginMD.MD2E(
            "Los datos proporcionados son incorrectos, verifícalos y vuelve a intentarlo",
            "Correo y/o contraseña incorrectos"
          )
        );
        setIncorrectCredentials(true);
        break;
      default:
        setShowModalError(true);
        break;
    }
  }

  const handleCancel = () => {
    setShowModal(false);
    TagManager.dataLayer(LoginMD.MD3A(false));
  };

  const handleRedirect = () => {
    TagManager.dataLayer(LoginMD.MD3A(true));
    setState();
  };

  const toggle = () => {
    TagManager.dataLayer(LoginMD.MD3A(false));
    setState();
  };
  const toggleError = () => setShowModalError(!showModalError);

  return (
    <section className={s.container}>
      <ConfigProvider5 prefixCls="ant5">
        <Modal
          open={showModal}
          footer={null}
          onCancel={handleCancel}
          centered
          closeIcon={null}
          width={700}
          maskClosable={false}
        >
          <ModalInfo
            toggle={toggle}
            tittle="Antes de continuar toma en cuenta:"
          >
            <div className={s.list}>
              <ul>
                <li>
                  Los cambios se podrán guardar para que retomes el proceso en
                  el momento que lo desees.
                </li>
                <li> Recuerda que tu solicitud estará sujeta a aprovación.</li>
              </ul>
            </div>
            <div className={s.modalBtn}>
              <WelcomeBtn
                tittle="Aceptar"
                disabled={false}
                onClick={handleRedirect}
              />
            </div>
          </ModalInfo>
        </Modal>
        <Modal
          open={showModalError}
          footer={null}
          onCancel={() => {
            setShowModalError(false);
          }}
          centered
          closeIcon={null}
          width={700}
        >
          <ModalError toggle={toggleError} tittle="Coppel Pay se desconectó">
            <p>El sistema falló. Intenta de nuevo.</p>
            <div className={s.modalBtn}>
              <WelcomeBtn
                tittle="Aceptar"
                disabled={false}
                onClick={() => {
                  setShowModalError(false);
                }}
              />
            </div>
          </ModalError>
        </Modal>
      </ConfigProvider5>
      <div>
        {incorrectCredentials && (
          <div className={s.errorContainer}>
            <ExclamationCircleFilled className={`${s.errorIcon}`} />
            <text className={s.errorMessage}>
              Los datos proporcionados son incorrectos, verifícalos y vuelve a
              intentarlo
            </text>
          </div>
        )}
        <TextInputWelcome
          id="inputCorreo"
          label="Correo"
          placeholder="Escribe tu correo"
          inputType="email"
          onChange={(e) => {
            setIncorrectCredentials(false);
            setValidEmail(validateEmail(e.target.value));
          }}
          validEmail={validEmail}
          hasEmailError={!validEmail}
          errorMsj="Ingresa un formato de correo válido"
        />
        <TextInputWelcome
          id="inputContraseña"
          label="Contraseña"
          placeholder="Escribe tu contraseña"
          inputType="password"
          onChange={(e) => {
            setIncorrectCredentials(false);
            setValidPassword(validatePassword(e.target.value));
          }}
          MDPasswordVisibility={(flagShow) => {
            TagManager.dataLayer(LoginMD.MD2A(flagShow));
          }}
        />
        <button
          type="button"
          className={s.buttonText}
          onClick={() => {
            TagManager.dataLayer(LoginMD.MD2B());
            navigate("/forget-password");
          }}
        >
          ¿Olvidaste tu contraseña?
        </button>
        <div className={s.btnContainer}>
          <WelcomeBtn
            tittle="Continuar"
            disabled={!(validEmail && validPassword)}
            onClick={() => {
              TagManager.dataLayer(LoginMD.MD2C());
              onClickContinue();
            }}
          />
        </div>
      </div>
    </section>
  );
}
