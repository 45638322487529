import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import TagManager from "react-gtm-module";
import { connect } from "react-redux";
import BaseMD from "../../../../google-analytics/events/baseMD";
import blueUserIcon from "../../../../images/icons/blueUserIcon.svg";
import {
  NewAdminAdded,
  NewAdminRemoved,
  NewAssociateAdded,
  NewAssociateRemoved,
  cleanStore,
  copyData,
  resetAdminCount,
  updateRadio,
  verifyValidationsOfficialData,
} from "../../../../redux-modules/form/actions";
import { DivisionLine } from "../../../DivisionLine";
import { CheckBox } from "../../../inputs/CheckBox";
import { RadioGroup } from "../../../inputs/RadioGroup/RadioGroup";
import TextInput from "../../../inputs/TextInput/TextInput";
import TextInputWithDobleValue from "../../../inputs/TextInputWithDobleValue/TextInputWithDobleValue";
import styles from "./MoralTaxpayer.module.sass";
import { SelectWithId } from "../../../inputs/SelectWithId";
import { getRegimen } from "../../../../regimenes/regimenes";

function MoralTaxpayerComponent({ callValidation, state, dispatch }) {
  const regimenes = getRegimen("Moral");
  const s = styles;
  const [hasMoreAdministrators, setHasMoreAdministrators] = useState(false);
  const [checkDatosMoral, setCheckDatosMoral] = useState(
    state.officialData.find((item) => item.name === "checkDatosMoral")?.value ??
      false
  );
  const [associates, setAssociates] = useState([
    {
      name: "",
    },
  ]);

  const [administrators, setAdministrators] = useState([
    {
      name: "",
    },
  ]);

  const membersOptions = [
    {
      name: "Sí",
      id: 1,
    },
    {
      name: "No",
      id: 2,
    },
  ];

  useEffect(() => {
    const payload = { name: "associates", value: "Sí", validated: true };
    dispatch(updateRadio(payload));
    // validate when user come back to this section if there is any associate or admin already added
    let hasAssociates = false;
    state.officialData.forEach((offDataitem) => {
      if (offDataitem.name === "administrators" && offDataitem.value === "Sí") {
        setHasMoreAdministrators(true);

        const administratorsAlreadyCreated = state.officialData.filter((i) => {
          return i.name.includes("extraAdministrator");
        });
        setAdministrators(administratorsAlreadyCreated);
      }

      if (offDataitem.name === "associates" && offDataitem.value === "Sí") {
        const associatesAlreadyCreated = state.officialData.filter((i) => {
          return i.name.includes("extraAssociate");
        });
        setAssociates(associatesAlreadyCreated);
        hasAssociates = true;
      }
    });
    if (associates.length === 1 && !hasAssociates) {
      setAssociates([
        ...associates,
        {
          id: associates.length + 1,
          name: `extraAssociate${associates.length + 1}NameAndLastname`,
        },
      ]);
    }
  }, []);

  function deleteAssociate(name) {
    setAssociates(associates.filter((items) => items.name !== name));
  }

  function deleteAdministrator(name) {
    setAdministrators(administrators.filter((admin) => admin.name !== name));
  }

  function handleAdministrators(e) {
    const { value } = e.target;
    if (value === "Sí") {
      dispatch(NewAdminAdded());
      dispatch(verifyValidationsOfficialData());
      TagManager.dataLayer(BaseMD.DM5E(true));

      return setHasMoreAdministrators(true);
    }
    TagManager.dataLayer(BaseMD.DM5E(false));

    dispatch(resetAdminCount());
    dispatch(verifyValidationsOfficialData());
    setAdministrators([
      {
        id: 1,
      },
    ]);
    return setHasMoreAdministrators(false);
  }

  const copyLegal = () => {
    const payloadTech = {
      moralTaxpayerName: "contactAdminName",
      moralTaxpayerLastname: "contactAdminFirstLastname",
      moralTaxpayerMothersLastname: "contactAdminSecondLastname",
      moralTaxpayerRfc: "contactAdminRFC",
      moralTaxpayerCURP: "contactAdminCURP",
    };
    dispatch(copyData(payloadTech));
  };
  const cleanDataLegal = () => {
    const payload = {
      sectionName: "officialData",
      fields: [
        "moralTaxpayerName",
        "moralTaxpayerLastname",
        "moralTaxpayerMothersLastname",
        "moralTaxpayerRfc",
        "moralTaxpayerCURP",
      ],
    };
    dispatch(cleanStore(payload));
  };
  return (
    <div className={s.taxpayerData}>
      <div className={s.titleAndDescription}>
        <div className={s.column}>
          <h2 className={s.secondaryTitle}>Datos del representante legal</h2>
          <CheckBox
            classExt={s.changeCheckbox}
            storeName="checkDatosLegal"
            title="Usar los mismos datos del administrador"
            externalHandler={(e) => {
              setCheckDatosMoral(e.target.checked);
              if (e.target.checked) {
                copyLegal();
              } else {
                cleanDataLegal();
              }
              dispatch(verifyValidationsOfficialData());
            }}
            isRequired={false}
          />
        </div>
        Escribe los datos del representante legal de la empresa
      </div>
      <div className={s.inputsTaxpayer}>
        <TextInput
          required
          title="Nombre"
          placeholder="Nombre"
          storeName="moralTaxpayerName"
          inputValidation="name"
          callValidation={callValidation}
          canCopy={checkDatosMoral}
          copyFrom="contactAdminName"
          sectionCopyFrom="contact"
        />

        <TextInput
          required
          title="Apellido paterno"
          placeholder="Apellido paterno"
          storeName="moralTaxpayerLastname"
          inputValidation="mothersLastName"
          callValidation={callValidation}
          canCopy={checkDatosMoral}
          copyFrom="contactAdminFirstLastname"
          sectionCopyFrom="contact"
        />

        <TextInput
          required
          title="Apellido materno"
          placeholder="Apellido materno"
          storeName="moralTaxpayerMothersLastname"
          inputValidation="mothersLastName"
          callValidation={callValidation}
          canCopy={checkDatosMoral}
          copyFrom="contactAdminSecondLastname"
          sectionCopyFrom="contact"
        />

        <TextInput
          required
          title="RFC"
          placeholder="RFC"
          storeName="moralTaxpayerRfc"
          inputValidation="rfc"
          aditionalInfo="Escribe el RFC con homoclave"
          callValidation={callValidation}
          canCopy={checkDatosMoral}
          copyFrom="contactAdminRFC"
          sectionCopyFrom="contact"
        />

        <div className={s.selectWrapper}>
          <SelectWithId
            id="moralTaxpayerRegimen"
            storeName="moralTaxpayerRegimen"
            name="Regimen"
            defaultValue="Regimen*"
            customRequiredMessage="Elige una opción para continuar"
            options={regimenes}
            callValidation={callValidation}
          />
        </div>

        <TextInput
          placeholder="CURP"
          callValidation={callValidation}
          storeName="moralTaxpayerCURP"
          title="CURP"
          sectionCopyFrom="contact"
          copyFrom="contactAdminRFC"
          inputValidation="curp"
          required
        />
      </div>
      <DivisionLine />
      <div className={s.titleAndDescription}>
        <h3 className={s.secondaryTitle}>¿La empresa cuenta con más socios?</h3>
        <p>Los socios son aquellas personas que invierten en tu empresa</p>
        <div className={s.members}>
          <div className="addAssociates">
            {associates.map((item, index) => {
              return (
                <TextInputWithDobleValue
                  typeOfInput="associates"
                  callValidation={callValidation}
                  index={index}
                  indexRemove={1}
                  key={item.name}
                  onClickDelete={() => {
                    TagManager.dataLayer(BaseMD.DM5G());
                    dispatch(NewAssociateRemoved({ index }));
                    dispatch(verifyValidationsOfficialData());
                    deleteAssociate(item.name);
                  }}
                />
              );
            })}
          </div>
          <button
            className={s.blueBtn}
            type="button"
            onClick={() => {
              TagManager.dataLayer(BaseMD.DM5F());
              dispatch(NewAssociateAdded());
              dispatch(verifyValidationsOfficialData());
              setAssociates([
                ...associates,
                {
                  id: associates.length + 1,
                  name: `extraAssociate${associates.length + 1}NameAndLastname`,
                },
              ]);
            }}
          >
            <div className={s.blueBtnIcon}>
              <img src={blueUserIcon} alt="usuario asociado" />
            </div>
            <div className={s.blueBtnText}>Añadir socio</div>
          </button>
        </div>
      </div>
      <DivisionLine />
      <div className={s.titleAndDescription}>
        <h3 className={s.secondaryTitle}>
          ¿La empresa cuenta con más administradores?
        </h3>
        <p className={s.sectionDescription}>
          Los administradores son aquellas personas que dirigen tu empresa,
          pueden ser accionistas o personas que no pertenecen al capital de la
          sociedad.
        </p>

        <RadioGroup
          storeName="administrators"
          optionsList={membersOptions}
          externalHandler={(e) => {
            handleAdministrators(e);
          }}
          callValidation={callValidation}
        />
        {hasMoreAdministrators && (
          <div className="">
            {administrators.map((item, index) => {
              return (
                <TextInputWithDobleValue
                  typeOfInput="administrators"
                  index={index}
                  key={item.name}
                  onClickDelete={() => {
                    TagManager.dataLayer(BaseMD.DM5I());
                    dispatch(NewAdminRemoved({ index }));
                    dispatch(verifyValidationsOfficialData());
                    deleteAdministrator(item.name);
                  }}
                />
              );
            })}
            <button
              className={s.blueBtn}
              type="button"
              onClick={() => {
                TagManager.dataLayer(BaseMD.DM5H());
                dispatch(NewAdminAdded());
                dispatch(verifyValidationsOfficialData());
                setAdministrators([
                  ...administrators,
                  {
                    id: administrators.length + 1,
                    name: `administrators${
                      administrators.length + 1
                    }NameAndLastname`,
                  },
                ]);
              }}
            >
              <div className={s.blueBtnIcon}>
                <img src={blueUserIcon} alt="usuario asociado" />
              </div>
              <div className={s.blueBtnText}>Añadir administrador</div>
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    state,
  };
}

MoralTaxpayerComponent.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  state: PropTypes.object.isRequired,
  callValidation: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
};

MoralTaxpayerComponent.defaultProps = {
  callValidation: false,
};

export const MoralTaxpayer = connect(
  mapStateToProps,
  null
)(MoralTaxpayerComponent);
