import React from "react";
import PropTypes from "prop-types";

import styles from "./BlueBtn.module.sass";

export default function BlueBtn({ text, handleOnClickBlueBtn, extraClass }) {
  const s = styles;

  return (
    <button
      type="button"
      className={`${s.btn} ${extraClass}`}
      onClick={handleOnClickBlueBtn}
    >
      {text}
    </button>
  );
}

BlueBtn.propTypes = {
  text: PropTypes.string.isRequired,
  handleOnClickBlueBtn: PropTypes.func.isRequired,
  extraClass: PropTypes.string,
};

BlueBtn.defaultProps = {
  extraClass: "",
};
