/* eslint-disable react/no-find-dom-node */
/* eslint-disable no-console */
import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import TagManager from "react-gtm-module";
import { connect, useSelector } from "react-redux";
import BaseMD from "../../../google-analytics/events/baseMD";
import confirmationIcon from "../../../images/icons/ConfirmationIcon.svg";
import CircleOne from "../../../images/icons/number-circle-1-bold.svg";
import CircleTwo from "../../../images/icons/number-circle-2-bold.svg";
import CircleThree from "../../../images/icons/number-circle-3-bold.svg";
import styles from "./Confirmation.module.sass";
import saveState from "../../../scripts/saveState";

function Component({ state }) {
  const s = styles;
  const typeOfPersonId = useSelector((state) => state.typeOfPerson?.id);
  const officialData = useSelector((state) => state.officialData);

  useEffect(() => {
    TagManager.dataLayer(BaseMD.MD8(officialData, typeOfPersonId));
  }, []);

  useEffect(() => {
    saveState(state);
  }, [state]);

  function valueFromContact(stateName) {
    const input = state.contact.filter((item) => {
      return item.name === stateName;
    });

    return input[0]?.value;
  }

  return (
    <div>
      <div className={s.BodyForm}>
        <div className={s.whiteCard}>
          <br />
          <br />
          <img src={confirmationIcon} alt="Icono Confirmación" />
          <br />
          <br />
          <h1 className={s.title}>Recibimos tu solicitud</h1>
          <div className={s.insideCard}>
            <h3 className={s.subTitle}>Estos son los siguientes pasos:</h3>
            <div className={s.w100p1}>
              <div className={s.bigIcons}>
                <img src={CircleOne} alt="" />
              </div>
              <div className={s.w90}>
                <h4 className={s.trititle}>
                  Revisaremos los datos y documentos que enviaste
                </h4>
              </div>
            </div>
            <div className={s.w100p1}>
              <div className={s.bigIcons}>
                <img src={CircleTwo} alt="" />
              </div>
              <div className={s.w90}>
                <h4 className={s.trititle}>
                  Evaluaremos si tu solicitud puede ser aprobada
                </h4>
              </div>
            </div>
            <div className={s.w100p1}>
              <div className={s.bigIcons}>
                <img src={CircleThree} alt="" />
              </div>
              <div className={s.w90}>
                <h4 className={s.trititle}>
                  En caso de aprobación, enviaremos las instrucciones para
                  completar tu registro al correo{" "}
                  {valueFromContact("contactAdminEmail")}
                </h4>
              </div>
            </div>
            <br />
            <br />
            <h4 className={s.trititle}>
              Si necesitamos confirmar algún dato, te contactaremos.
            </h4>
            <h4 className={s.trititle}>
              La repuesta a tu solicitud estará lista a la brevedad, manténte
              pendiente
            </h4>
            <br />
            <br className={s.showResponsive} />
            <h4 className={s.trititle}>
              ¿Tienes dudas? Envíanos un correo a{" "}
              <a
                href="mailto:afiliatecoppelpay@coppel.com"
                onClick={() => {
                  TagManager.dataLayer(
                    BaseMD.MD8A(officialData, typeOfPersonId)
                  );
                }}
              >
                afiliatecoppelpay@coppel.com
              </a>
            </h4>
            <br />
            <br className={s.showResponsive} />
          </div>
          <button
            id="btnNext"
            alt="Ir a la siguiente sección"
            type="button"
            className={s.btnBack}
            onClick={() => {
              TagManager.dataLayer(BaseMD.MD8B(officialData, typeOfPersonId));
              window.location.href =
                process.env.REACT_APP_COPPEL_PAY_LANDING_LINK;
            }}
          >
            Volver a la página principal
          </button>
          <br />
          <br />
        </div>
        <br />
      </div>
    </div>
  );
}

Component.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  state: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    state,
  };
}

export const Confirmation = connect(mapStateToProps)(Component);
