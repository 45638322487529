/**
 * Object where keys->obj[name]
 * and value->[value] for each object in the array given.
 *
 * @param {Array} arrayGiven
 * @returns {Object}
 */
export default function convertArrayToJson(arrayGiven) {
  return arrayGiven.reduce((object, value) => {
    object[value.name] = value.value;
    return object;
  }, {});
}
