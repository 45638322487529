import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import TagManager from "react-gtm-module";
import { connect } from "react-redux";
import BaseMD from "../../../google-analytics/events/baseMD";
import {
  cleanStore,
  copyData,
  nextSection,
  prevSection,
  validationsCompletedContact,
} from "../../../redux-modules/form/actions";
import { CheckBox } from "../../inputs/CheckBox";
import { RadioGroup } from "../../inputs/RadioGroup/RadioGroup";
import TextInput from "../../inputs/TextInput/TextInput";
import styles from "./Contact.module.sass";
import saveState from "../../../scripts/saveState";
// eslint-disable-next-line react/prop-types
function Component({ state, dispatch }) {
  const s = styles;
  const [checkTech, setCheckTech] = useState(
    state.contact.find((item) => item.name === "checkDataTech")?.value ?? false
  );
  const [checkComercial, setCheckComercial] = useState(
    state.contact.find((item) => item.name === "checkDataComercial")?.value ??
      false
  );
  const [callValidation, setCallValidation] = useState(false);
  const optionsEmail = [
    {
      name: "Empresarial",
      id: "1",
    },
    {
      name: "Personal",
      id: "2",
    },
  ];

  const optionsPhone = [
    {
      name: "Oficina",
      id: "1",
    },
    {
      name: "Celular",
      id: "2",
    },
    {
      name: "Casa",
      id: "3",
    },
  ];

  const goNextSection = () => {
    if (state.contactValidations === true) {
      TagManager.dataLayer(BaseMD.MD4C());
      saveState(state);
      return dispatch(nextSection());
    }
    return null;
  };

  useEffect(() => {
    TagManager.dataLayer(BaseMD.MD4());
  }, []);

  const altTitle =
    "Contactos. Estás en el paso 1 de 5. Necesitarás escribir los datos del contacto administrador, del contacto técnico y del contacto comercial";
  const copyTech = () => {
    const payloadTech = {
      contactTechName: "contactAdminName",
      contactTechFirstLastname: "contactAdminFirstLastname",
      contactTechSecondLastname: "contactAdminSecondLastname",
      contactTechPhoneType: "contactAdminPhoneType",
      contactTechEmailType: "contactAdminEmailType",
      contactTechPhone: "contactAdminPhone",
      contactTechEmail: "contactAdminEmail",
    };
    dispatch(copyData(payloadTech));
  };
  const copyComercial = () => {
    const payloadCommercial = {
      contactComercialName: "contactAdminName",
      contactComercialFirstLastname: "contactAdminFirstLastname",
      contactComercialSecondLastname: "contactAdminSecondLastname",
      contactComercialPhoneType: "contactAdminPhoneType",
      contactComercialEmailType: "contactAdminEmailType",
      contactComercialPhone: "contactAdminPhone",
      contactComercialEmail: "contactAdminEmail",
    };
    dispatch(copyData(payloadCommercial));
  };
  const copyToState = () => {
    if (checkTech) {
      copyTech();
    }

    if (checkComercial) {
      copyComercial();
    }
  };

  const cleanDataTech = () => {
    const payload = {
      sectionName: "contact",
      fields: [
        "contactTechName",
        "contactTechFirstLastname",
        "contactTechSecondLastname",
        "contactTechPhoneType",
        "contactTechEmailType",
        "contactTechPhone",
        "contactTechEmail",
      ],
    };
    dispatch(cleanStore(payload));
  };

  const cleanDataCommnercial = () => {
    const payload = {
      sectionName: "contact",
      fields: [
        "contactComercialName",
        "contactComercialFirstLastname",
        "contactComercialSecondLastname",
        "contactComercialPhoneType",
        "contactComercialEmailType",
        "contactComercialPhone",
        "contactComercialEmail",
      ],
    };
    dispatch(cleanStore(payload));
  };
  useEffect(() => {
    copyToState();
    dispatch(validationsCompletedContact());
  }, [state.contact]);
  return (
    <>
      <section className={s.contactForm}>
        <h1 className={s.title} alt={altTitle}>
          Contactos
        </h1>
        <h3 className={s.subtitle}>
          <strong>
            Recuerda que debes llenar todas las secciones marcadas con * para
            poder continuar
          </strong>
        </h3>
        <h2 className={s.secondaryTitle}>Administrador</h2>
        <h3 className={s.subtitle}>
          Será la persona que tendrá acceso a la plataforma de Coppel Pay para
          gestionar tu cuenta, crear usuarios y consultar pagos y movimientos
        </h3>
        <div className={s.inputsSection}>
          <TextInput
            id="1234"
            title="Nombre"
            placeholder="Nombre"
            storeName="contactAdminName"
            inputValidation="name"
            required
            callValidation={callValidation}
          />

          <TextInput
            id="1235"
            title="Apellido paterno"
            placeholder="Apellido paterno"
            storeName="contactAdminFirstLastname"
            inputValidation="lastName"
            required
            callValidation={callValidation}
          />
          <TextInput
            id="1235"
            title="Apellido materno"
            placeholder="Apellido materno"
            storeName="contactAdminSecondLastname"
            inputValidation="mothersLastName"
            required
            callValidation={callValidation}
          />
          <TextInput
            id="1235"
            title="RFC"
            placeholder="RFC"
            storeName="contactAdminRFC"
            required
            inputValidation="rfc"
            aditionalInfo="Escribe el RFC con homoclave"
            callValidation={callValidation}
          />
          <TextInput
            id="1235"
            title="CURP"
            placeholder="CURP"
            storeName="contactAdminCURP"
            required
            inputValidation="curp"
            callValidation={callValidation}
          />
        </div>

        <div className={s.radiosSection}>
          <RadioGroup
            title="Tipo de teléfono"
            storeName="contactAdminPhoneType"
            optionsList={optionsPhone}
            callValidation={callValidation}
          />
          <RadioGroup
            title="Tipo de correo"
            storeName="contactAdminEmailType"
            optionsList={optionsEmail}
            callValidation={callValidation}
          />
          <br />
          <TextInput
            id="1235"
            title="Teléfono"
            type="number"
            placeholder="Teléfono"
            storeName="contactAdminPhone"
            aditionalInfo="Escribe el número a 10 dígitos"
            hasLengthLimit={10}
            inputValidation="phone"
            callValidation={callValidation}
            required
          />
          <TextInput
            id="1235"
            title="Correo electrónico"
            placeholder="Correo electrónico"
            storeName="contactAdminEmail"
            aditionalInfo="Será el correo principal de tu cuenta en Coppel Pay"
            required
            inputValidation="email"
            callValidation={callValidation}
          />
        </div>

        <div className={s.column}>
          <h2 className={s.secondaryTitle}>Técnico</h2>
          <CheckBox
            classExt={s.changeCheckbox}
            storeName="checkDataTech"
            title="Usar los mismos datos del administrador"
            externalHandler={(e) => {
              setCheckTech(e.target.checked);
              if (e.target.checked) {
                copyTech();
              } else {
                cleanDataTech();
              }
              dispatch(validationsCompletedContact());
            }}
            isRequired={false}
          />
        </div>
        <h3 className={s.subtitle}>
          Será la persona que nos ayudará a integrar Coppel Pay en el sistema de
          tu negocio
        </h3>
        <div className={s.inputsSection}>
          <TextInput
            id="1234"
            title="Nombre"
            placeholder="Nombre"
            storeName="contactTechName"
            callValidation={callValidation}
            required
            inputValidation="name"
            canCopy={checkTech}
            copyFrom="contactAdminName"
            sectionCopyFrom="contact"
          />
          <TextInput
            id="1235"
            title="Apellido paterno"
            placeholder="Apellido paterno"
            storeName="contactTechFirstLastname"
            inputValidation="lastName"
            required
            callValidation={callValidation}
            canCopy={checkTech}
            copyFrom="contactAdminFirstLastname"
            sectionCopyFrom="contact"
          />
          <TextInput
            id="1235"
            title="Apellido materno"
            placeholder="Apellido materno"
            storeName="contactTechSecondLastname"
            inputValidation="mothersLastName"
            required
            callValidation={callValidation}
            canCopy={checkTech}
            copyFrom="contactAdminSecondLastname"
            sectionCopyFrom="contact"
          />
        </div>
        <div className={s.radiosSection}>
          <RadioGroup
            title="Tipo de teléfono"
            storeName="contactTechPhoneType"
            optionsList={optionsPhone}
            callValidation={callValidation}
            canCopy={checkTech}
            copyFrom="contactAdminPhoneType"
            sectionCopyFrom="contact"
          />
          <RadioGroup
            title="Tipo de correo"
            storeName="contactTechEmailType"
            optionsList={optionsEmail}
            callValidation={callValidation}
            canCopy={checkTech}
            copyFrom="contactAdminEmailType"
            sectionCopyFrom="contact"
          />
          <br />
          <TextInput
            id="1235"
            title="Teléfono"
            placeholder="Teléfono"
            type="number"
            storeName="contactTechPhone"
            aditionalInfo="Escribe el número a 10 dígitos"
            hasLengthLimit={10}
            inputValidation="phone"
            required
            callValidation={callValidation}
            canCopy={checkTech}
            copyFrom="contactAdminPhone"
            sectionCopyFrom="contact"
          />
          <TextInput
            id="1235"
            title="Correo electrónico"
            placeholder="Correo electrónico"
            storeName="contactTechEmail"
            aditionalInfo="Lo usaremos solo como un medio de contacto"
            required
            inputValidation="email"
            callValidation={callValidation}
            canCopy={checkTech}
            copyFrom="contactAdminEmail"
            sectionCopyFrom="contact"
          />
        </div>
        <div className={s.column}>
          <h2 className={s.secondaryTitle}>Comercial</h2>
          <CheckBox
            classExt={s.changeCheckbox}
            storeName="checkDataComercial"
            title="Usar los mismos datos del administrador"
            externalHandler={(e) => {
              setCheckComercial(e.target.checked);
              if (e.target.checked) {
                copyComercial();
              } else {
                cleanDataCommnercial();
              }
              dispatch(validationsCompletedContact());
            }}
            isRequired={false}
          />
        </div>
        <h3 className={s.subtitle}>
          Será la persona encargada de formalizar los acuerdos comerciales con
          Coppel Pay
        </h3>
        <div className={s.inputsSection}>
          <TextInput
            id="1234"
            title="Nombre"
            placeholder="Nombre"
            inputValidation="name"
            storeName="contactComercialName"
            required
            callValidation={callValidation}
            canCopy={checkComercial}
            copyFrom="contactAdminName"
            sectionCopyFrom="contact"
          />

          <TextInput
            id="1235"
            title="Apellido paterno"
            placeholder="Apellido paterno"
            storeName="contactComercialFirstLastname"
            inputValidation="mothersLastName"
            required
            callValidation={callValidation}
            canCopy={checkComercial}
            copyFrom="contactAdminFirstLastname"
            sectionCopyFrom="contact"
          />
          <TextInput
            id="1235"
            title="Apellido materno"
            placeholder="Apellido materno"
            storeName="contactComercialSecondLastname"
            inputValidation="mothersLastName"
            required
            callValidation={callValidation}
            canCopy={checkComercial}
            copyFrom="contactAdminSecondLastname"
            sectionCopyFrom="contact"
          />
        </div>
        <div className={s.radiosSection}>
          <RadioGroup
            title="Tipo de teléfono"
            storeName="contactComercialPhoneType"
            optionsList={optionsPhone}
            callValidation={callValidation}
            canCopy={checkComercial}
            copyFrom="contactAdminPhoneType"
            sectionCopyFrom="contact"
          />
          <RadioGroup
            title="Tipo de correo"
            storeName="contactComercialEmailType"
            optionsList={optionsEmail}
            callValidation={callValidation}
            canCopy={checkComercial}
            copyFrom="contactAdminEmailType"
            sectionCopyFrom="contact"
          />
          <br />
          <TextInput
            id="1235"
            title="Teléfono"
            type="number"
            placeholder="Teléfono"
            storeName="contactComercialPhone"
            aditionalInfo="Escribe el número a 10 dígitos"
            inputValidation="phone"
            hasLengthLimit={10}
            required
            callValidation={callValidation}
            canCopy={checkComercial}
            copyFrom="contactAdminPhone"
            sectionCopyFrom="contact"
          />
          <TextInput
            id="1235"
            title="Correo electrónico"
            placeholder="Correo electrónico"
            storeName="contactComercialEmail"
            aditionalInfo="Lo usaremos solo como un medio de contacto"
            inputValidation="email"
            required
            callValidation={callValidation}
            canCopy={checkComercial}
            copyFrom="contactAdminEmail"
            sectionCopyFrom="contact"
          />
        </div>
      </section>
      <div className={s.heroControls}>
        <button
          type="button"
          aria-label="Ir a la sección previa"
          className={s.btnBack}
          onClick={() => {
            TagManager.dataLayer(BaseMD.MD4B());
            saveState(state);
            dispatch(prevSection());
          }}
        >
          <span className="arrow">🡠 </span>Regresar al inicio
        </button>
        <button
          aria-label="Continuar al llenado de Datos oficiales"
          className={`${s.btnNext} ${state.contactValidations ? s.active : ""}`}
          type="button"
          onClick={() => {
            setCallValidation(true);
            dispatch(validationsCompletedContact());
            goNextSection();
          }}
        >
          Continuar
        </button>
      </div>
    </>
  );
}

Component.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  state: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    state,
  };
}

export const Contacto = connect(mapStateToProps)(Component);
