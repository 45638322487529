import React from "react";
import styles from "./WelcomeBtn.module.sass";

const WelcomeBtn = ({ tittle, disabled, onClick, loading }) => {
    const s = styles;
    if (loading){
        return <button className={`${s.btnInactive}`} disabled>...</button>
    }
    return <button type="button" className={`${disabled? s.btnInactive : s.btnActive}`} disabled={disabled} onClick={onClick}>{tittle}</button>;
};

export default WelcomeBtn;
