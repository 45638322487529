import axios from "axios";

function saveToken(token) {
  localStorage.setItem("token", token);
}
function saveEmail(email) {
  localStorage.setItem("email", email);
}

function saveIdAfiliado(idAfiliado) {
  localStorage.setItem("idAfiliado", idAfiliado);
}

export default async function autenticateLead(params) {
  let result = null;

  await axios
    .post(process.env.REACT_APP_AUTENTICATE_LEAD, params, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      if (response.status === 200 && response.data.status === "SUCCESS") {
        saveToken(response.data.data.token);
        saveEmail(response.data.data.correo);
        saveIdAfiliado(response.data.data.idAfiliado);
      }
      result = response.data;
    })
    .catch((err) => {
      if (err.response) {
        err = {
          errorCode: err.response.data.error.errorCode,
          errorMessage: err.response.data.error.errorMessage,
          error: new Error(err),
        };
        throw err;
      }
      throw new Error(err);
    });
  return result;
}
