/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import TagManager from "react-gtm-module";
import { connect, useSelector } from "react-redux";
import BaseMD from "../../../google-analytics/events/baseMD";
import {
  nextSection,
  prevSection,
  verifyValidationsCompanyData,
} from "../../../redux-modules/form/actions";
import styles from "./CompanyData.module.sass";
import { MoralCompanyData } from "./MoralCompany/MoralCompanyData";
import { PhysicalCompanyData } from "./PhysicalCompany/PhysicalCompanyData";
import saveState from "../../../scripts/saveState";

function Component({ state, dispatch }) {
  const s = styles;

  const [callValidation, setCallValidation] = useState(false);
  const officialData = useSelector((state) => state.officialData);
  const typeOfPersonId = useSelector((state) => state.typeOfPerson?.id);
  useEffect(() => {
    TagManager.dataLayer(BaseMD.MD6(officialData, typeOfPersonId));
  }, []);

  function goNextSection() {
    if (state.companyDataValidations === true) {
      TagManager.dataLayer(BaseMD.MD6D(officialData, typeOfPersonId));
      saveState(state);
      dispatch(nextSection());
    }
  }

  return (
    <>
      <section className={s.contactForm}>
        {state.typeOfPerson.name === "moral" && (
          <MoralCompanyData callValidation={callValidation} />
        )}
        {state.typeOfPerson.name === "physical" && (
          <PhysicalCompanyData callValidation={callValidation} />
        )}
      </section>
      <div className={s.heroControls}>
        <button
          type="button"
          aria-label="Ir a la sección anterior"
          className={s.btnBack}
          onClick={() => {
            TagManager.dataLayer(BaseMD.MD6C(officialData, typeOfPersonId));
            saveState(state);
            dispatch(prevSection());
            setCallValidation(true);
          }}
        >
          <span className="arrow">🡠 </span>Regresar
        </button>
        <button
          aria-label="Continuar al llenado de Información comercial"
          className={`${s.btnNext} ${
            state.companyDataValidations ? s.active : ""
          }`}
          type="button"
          onClick={() => {
            setCallValidation(true);
            dispatch(verifyValidationsCompanyData());
            goNextSection();
          }}
        >
          Continuar
        </button>
      </div>
    </>
  );
}

Component.propTypes = {
  dispatch: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  state: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    state,
  };
}

export const CompanyData = connect(mapStateToProps)(Component);
