/* eslint no-console: ["error", { allow: ["error"] }] */

import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import TagManager from "react-gtm-module";
import { connect, useSelector } from "react-redux";
import BaseMD from "../../../google-analytics/events/baseMD";
import useFetchPostalData from "../../../hooks/useFetchPostalData";
import search from "../../../images/icons/search.svg";
import {
  updateInput,
  updateInputStatus,
} from "../../../redux-modules/form/actions";
import styles from "./PCInput.module.sass";

function PCInputComponent({
  title,
  storeName,
  placeholder,
  required,
  aditionalInfo,
  callValidation,
  dispatch,
  state,
}) {
  const s = styles;
  const [visibilityLabel, setVisibilityLabel] = useState(s.hidden);
  const [validationErrorMsg, setValidationErrorMsg] = useState("");
  const [validationErrorClass, setValidationErrorClass] = useState("");
  const [requiredIdentifier, setRequiredIdentifier] = useState("");
  const currentSectionMD = useSelector((state) => state.currentSection.name);
  const typeOfPersonId = useSelector((state) => state.typeOfPerson?.id);
  const officialData = useSelector((state) => state.officialData);

  // validations
  function validateRequired() {
    const postalCode = document.getElementById(storeName).value;

    if (postalCode !== "") {
      setValidationErrorMsg("");
      setValidationErrorClass("");
    } else {
      setValidationErrorMsg("Escribe el código postal");
      setValidationErrorClass(s.error);
    }

    // error class hides aditional info text
    const payload = { name: storeName, validated: true };
    dispatch(updateInputStatus(payload));
  }

  function validatePostalCode() {
    const postalCode = document.getElementById(storeName).value;
    const onlyNumbersNoEmptyString = "\\d{5,}";

    if (postalCode.match(onlyNumbersNoEmptyString)) {
      setValidationErrorMsg("");
      setValidationErrorClass("");
      const pcPayload = { name: storeName, value: postalCode, validated: true };
      dispatch(updateInputStatus(pcPayload));
      return true;
    }

    setValidationErrorMsg(
      "El campo Código Postal debe ser numérico de 5 posiciones"
    );

    setValidationErrorClass(s.error);

    const payload = { name: storeName, value: postalCode, validated: false };
    dispatch(updateInputStatus(payload));

    return false;
  }

  let inputValue;
  const valueFromStore = (reduxState, sName) => {
    const currentSection = reduxState.currentSection.name;

    if (reduxState) {
      reduxState[currentSection].map((input) => {
        if (input.name === sName) {
          inputValue = input.value;
        }
        return input;
      });
    }
    return inputValue;
  };

  function handleOnBlur() {
    validateRequired();
    validatePostalCode();
  }

  function handleOnChange(ivalue) {
    if (ivalue !== "") {
      setVisibilityLabel(s.visible);
    } else {
      setVisibilityLabel(s.hidden);
    }

    const { value: postalCode } = document.getElementById(storeName);
    const pay = { name: storeName, value: postalCode, validated: false };
    dispatch(updateInput(pay));
  }

  const [URL, setURL] = useState("");

  const copomexResponse = useFetchPostalData(URL).data;

  const handleOnClickSearch = () => {
    TagManager.dataLayer(BaseMD.DM5L(officialData, typeOfPersonId));
    const postalCode = document.getElementById(storeName).value;
    if (validatePostalCode()) {
      setURL(
        `https://api.copomex.com/query/info_cp/${postalCode}?token=${process.env.REACT_APP_COPOMEX_TOKEN}`
      );
    }
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const didMount = useRef(false);
  const didFetch = useRef(false);

  useEffect(() => {
    if (didFetch.current === true) {
      if (
        copomexResponse.error?.response.data.code_error === 102 ||
        copomexResponse.error?.response.data.code_error === 105 ||
        copomexResponse.error?.response.data.code_error === 104
      ) {
        setValidationErrorMsg(
          "El código postal no existe, revísalo e intenta de nuevo."
        );
        setValidationErrorClass(s.error);
        console.error(copomexResponse.error.response.data);
        return () => null;
      }

      if (copomexResponse.error?.response.data.code_error === 5) {
        setValidationErrorMsg(
          "Servicio no disponible introduzca los datos manualmente"
        );
        setValidationErrorClass(s.error);
        console.error(copomexResponse.error.response.data);
        return () => null;
      }

      const pcInfo = JSON.parse(JSON.stringify(copomexResponse.data[0]));
      let pcInfoOptions = JSON.parse(JSON.stringify(copomexResponse.data));

      if (pcInfoOptions instanceof Array) {
        pcInfoOptions = pcInfoOptions.map((item) => {
          return item.response.asentamiento;
        });
      } else {
        pcInfoOptions = ["Introduzca un código postal válido"];
      }
      if (pcInfo.response !== undefined) {
        const country = pcInfo.response.pais;
        const pcState = pcInfo.response.estado;
        const municipality = pcInfo.response.municipio;
        const city = pcInfo.response.ciudad;
        const suburbOptions = pcInfoOptions;
        const { currentSection } = state;

        // validate = false because it's not an user input

        const payloadSuburbOptions = {
          name: `suburbOptions${capitalizeFirstLetter(currentSection.name)}`,
          value: suburbOptions,
          validated: false,
        };

        const payloadSuburb = {
          name: `suburb${capitalizeFirstLetter(currentSection.name)}`,
          value: null,
          validated: false,
        };

        const payloadState = {
          name: `state${capitalizeFirstLetter(currentSection.name)}`,
          value: pcState,
          validated: true,
        };

        const payloadMunicipality = {
          name: `municipality${capitalizeFirstLetter(currentSection.name)}`,
          value: municipality,
          validated: true,
        };

        const payloadCity = {
          name: `city${capitalizeFirstLetter(currentSection.name)}`,
          value: city,
          validated: true,
        };

        const payloadCountry = {
          name: `country${capitalizeFirstLetter(currentSection.name)}`,
          value: country,
          validated: true,
        };

        dispatch(updateInput(payloadSuburbOptions));
        dispatch(updateInput(payloadSuburb));
        dispatch(updateInput(payloadMunicipality));
        dispatch(updateInput(payloadCity));
        dispatch(updateInput(payloadState));
        dispatch(updateInput(payloadCountry));
        return () => true;
      }
    }
    didFetch.current = true;
    return () => {
      didMount.current;
    };
  }, [copomexResponse]);

  // Triggered by page continue button
  useEffect(() => {
    if (required) {
      setRequiredIdentifier("*");
      if (didMount.current) {
        validateRequired();
      } else {
        didMount.current = true;
      }
    }
  }, [callValidation]);
  useEffect(() => {
    if (validationErrorMsg === "") return;

    TagManager.dataLayer(
      BaseMD.MD3C(
        validationErrorMsg,
        currentSectionMD,
        title,
        officialData,
        typeOfPersonId
      )
    );
  }, [validationErrorMsg]);

  return (
    <div className={`${validationErrorClass} ${s.input}`}>
      <div className={s.inputAndSearchWrapper}>
        <label
          htmlFor={storeName}
          className={`${visibilityLabel} ${s.inputTitle}`}
        >
          {title + requiredIdentifier}
        </label>
        <input
          className={s.inputText}
          value={valueFromStore(state, storeName) || ""}
          type="text"
          id={storeName}
          placeholder={placeholder + requiredIdentifier}
          maxLength="5"
          onChange={(e) => {
            handleOnChange(e.value);
          }}
          onBlurCapture={handleOnBlur}
        />
        <button
          type="button"
          className={s.searchIcon}
          onClick={handleOnClickSearch}
        >
          <img src={search} alt="Lupa para buscar el código postal" />
        </button>
      </div>
      <small className={s.inputErrorFeedback}>{validationErrorMsg}</small>
      <small className={s.inputAditionalInfo}>{aditionalInfo}</small>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    state,
  };
}

export const PCInput = connect(mapStateToProps)(PCInputComponent);

PCInputComponent.propTypes = {
  title: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  required: PropTypes.bool,
  aditionalInfo: PropTypes.string,
  storeName: PropTypes.string,
  callValidation: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  state: PropTypes.object.isRequired,
};

PCInputComponent.defaultProps = {
  callValidation: false,
  required: false,
  aditionalInfo: "",
  storeName: "",
};
