import React, { useState } from "react";
import BlueBtnFileWithValidations from "../../BlueBtn/BlueBtnWithValidations";
import BlueBtnLogo from "../../BlueBtnLogo/BlueBtnLogo";
import ListErrors from "../../ListErrors/ListErrors";
import UlList from "../../inputs/UlList/UlList";
import styles from "./modalUploadProcess.module.sass";
import FileInput from "./sections/FileInput/FileInput";
import IdOrPassaport from "./sections/IdOrPassaport/IdOrPassaport";
import Stepper from "../../loader/StepperDocs";
import StepperReload from "../../loader/StepperReload";

export default function MetaViews({
  currentPosition,
  onSendMessage,
  reupload,
}) {
  const s = styles;
  let styleTitle = "";
  const [stillWaiting, setStillWaiting] = useState(false);

  if (reupload) {
    styleTitle = s.reupload;
  }

  const handlerStillWaiting = () => {
    setStillWaiting(true);
  };

  return (
    <div className={s.content}>
      {currentPosition.name === "reuploadTheFollowingDocuments" && (
        <FileInput
          title="Sube de nuevo los siguientes documentos"
          mainContent={
            <ListErrors subTitle="Detectamos un problema con estos documentos:" />
          }
        />
      )}
      {currentPosition.name === "CSF" && (
        <FileInput
          title="Sube la Constancia de Situación Fiscal"
          storeName="CSF"
          mainContent={
            <UlList
              info={[
                "Debe ser emitida por el SAT",
                "Mostrar el RFC con homoclave",
                "Tener un tiempo menor a 3 meses",
                "Tener una extensión mínima de 2 páginas",
                "Formato PDF",
              ]}
            />
          }
          button={
            <BlueBtnFileWithValidations
              storeName="CSF"
              text="Subir archivo"
              className={s.btnUploadFile}
              onlyPdf
              onSendMessage={onSendMessage}
              title="Sube la Constancia de Situación Fiscal"
            />
          }
          stepCurrent={1}
        />
      )}
      {currentPosition.name === "identificationSelection" && (
        <FileInput
          title="Selecciona un documento de 
identificación"
          mainContent={
            <IdOrPassaport
              options={[
                { name: "Credencial de elector", value: "INE" },
                { name: "Pasaporte", value: "passport" },
              ]}
              name="idOrPassport"
            />
          }
          stepCurrent={2}
        />
      )}
      {currentPosition.name === "passport" && (
        <FileInput
          title="Pasaporte"
          storeName="passport"
          mainContent={
            <UlList
              info={["Debe estar vigente", "Formato PDF, PNG, JPEG o JPG"]}
            />
          }
          button={
            <BlueBtnFileWithValidations
              storeName="passport"
              text="Subir archivo"
              className={s.btnUploadFile}
              onSendMessage={onSendMessage}
              title="Pasaporte"
            />
          }
          stepCurrent={2}
        />
      )}
      {currentPosition.name === "ineFront" && (
        <FileInput
          storeName="ineFront"
          title="Sube la parte delantera de tu credencial de elector"
          mainContent={
            <UlList
              info={[
                "Vigente",
                "Fotografía de tu credencial de elector formatos PDF, JPG, JPEG o PNG (no escaneada)",
              ]}
            />
          }
          button={
            <BlueBtnFileWithValidations
              storeName="ineFront"
              text="Subir archivo"
              className={s.btnUploadFile}
              onSendMessage={onSendMessage}
              title="Sube la parte delantera de tu credencial de elector"
            />
          }
          stepCurrent={2}
        />
      )}
      {currentPosition.name === "ineBack" && (
        <FileInput
          storeName="ineBack"
          title="Sube el reverso de tu credencial 
de elector"
          mainContent={
            <UlList
              info={[
                "Vigente",
                "Fotografía de tu credencial de elector formatos PDF, JPG, JPEG o PNG (no escaneada)",
              ]}
            />
          }
          button={
            <BlueBtnFileWithValidations
              storeName="ineBack"
              text="Subir archivo"
              className={s.btnUploadFile}
              onSendMessage={onSendMessage}
              title="Sube el reverso de tu credencial 
              de elector"
            />
          }
          stepCurrent={2}
        />
      )}

      {currentPosition.name === "bankStatement" && (
        <FileInput
          storeName="bankStatement"
          title="Sube tu estado de cuenta"
          mainContent={
            <UlList
              info={[
                "La cuenta debe ser de débito",
                "El documento debe mostrar datos de la de la cuenta y CLABE interbancaria",
                "Tiene que ser menor a 3 meses",
                "Puedes ocultar las cantidades de tus balances y saldo",
                "El titular debe ser la persona física o moral que realice el registro",
                "Formato PDF",
              ]}
            />
          }
          button={
            <BlueBtnFileWithValidations
              storeName="bankStatement"
              text="Subir archivo"
              className={s.btnUploadFile}
              onSendMessage={onSendMessage}
              onlyPdf
              title="Sube tu estado de cuenta"
            />
          }
          stepCurrent={3}
        />
      )}
      {currentPosition.name === "logo" && (
        <FileInput
          storeName="logo"
          title="Logo"
          mainContent={
            <UlList
              info={[
                "Formato PNG o JPG",
                "Peso del archivo menor a 2 MB",
                "Que la imagen no tenga fondo o este sea transparente",
                "Debe tener una medida de 100 x 100 px",
              ]}
            />
          }
          button={
            <BlueBtnLogo
              storeName="logo"
              text="Subir archivo"
              className={s.btnUploadFile}
            />
          }
          stepCurrent={4}
        />
      )}
      {currentPosition.name === "validating" && (
        <FileInput
          title={
            stillWaiting ? "stillWaiting" : "Estamos cargando tus documentos"
          }
          mainContent={
            <div className={styleTitle}>
              {stillWaiting ? (
                <p>No cierres ni actualices la página</p>
              ) : (
                <p>
                  Recibimos tus documentos y los estamos procesando, <br />
                  <strong>Esto nos tomará algunos minutos</strong>, no cierres
                  ni actualices la página
                </p>
              )}
              <div>
                {reupload ? (
                  <StepperReload funct={handlerStillWaiting} />
                ) : (
                  <Stepper funct={handlerStillWaiting} />
                )}
              </div>
            </div>
          }
        />
      )}
      {currentPosition.name === "success" && (
        <FileInput
          title="Validamos tus documentos"
          mainContent={<p>Ya puedes continuar con tu registro</p>}
        />
      )}
    </div>
  );
}
