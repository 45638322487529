import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { useDispatch, useSelector } from "react-redux";
import {
  deleteItemByName,
  updateInput,
} from "../../../../../redux-modules/form/actions";

import styles from "./IdOrPassaport.module.sass";

export default function IdOrPassaport({ name, options }) {
  const s = styles;
  const dispatch = useDispatch();
  const store = useSelector((state) => state.documents);
  const [optionSelected, setOptionSelected] = useState(null);

  function handleOptionClick(e) {
    const payload = {
      name: "identificationSelection",
      value: {
        status: "verified",
        errors: [],
        selection: e.target.value,
        validated: true,
      },
    };

    dispatch(updateInput(payload));

    if (e.target.value === "INE") {
      // delete passport to avoid conflicts when counting documents
      dispatch(deleteItemByName("passport"));
    } else if (e.target.value === "passport") {
      // delete ine to avoid conflicts when counting documents
      dispatch(deleteItemByName({ name: "ineFront" }));
      dispatch(deleteItemByName({ name: "ineBack" }));
    }
  }

  useEffect(() => {
    const currentSelectionInStore = store.find((item) => {
      return item.name === "identificationSelection";
    })?.value?.selection;

    if (currentSelectionInStore === "INE") {
      setOptionSelected("INE");
    } else if (currentSelectionInStore === "passport") {
      setOptionSelected("passport");
    } else {
      handleOptionClick({ target: { value: "INE" } });
    }
  }, [store]);

  return (
    <div className={s.container}>
      {options.map((option) => (
        <div key={option.value}>
          <input
            type="radio"
            checked={optionSelected === option.value}
            className={s.radio}
            value={option.value}
            name={name}
            onChange={handleOptionClick}
            id={option.value}
          />{" "}
          <label htmlFor={option.value}>{option.name}</label>
        </div>
      ))}
    </div>
  );
}

IdOrPassaport.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
};
