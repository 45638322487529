/**
 * Check that every document in the array of documents has a status "verified" or "rejected"
 * @param {Array} documents - Array of documents
 * @returns {Boolean} - True if all documents are valid, false otherwise
 */
export default function validateAllDocumentsAreUploaded(documents) {
  return documents.every((document) => {
    return (
      document.value.status === "verified" ||
      document.value.status === "rejected"
    );
  });
}
