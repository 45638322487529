import React from "react";
import DocumentIcon from "../../DocumentIcon/DocumentIcon";

export default function ViewsIcons({ currentPosition }) {
  return (
    <>
      {currentPosition.name === "CSF" && <DocumentIcon imgLogo="constancia" />}
      {currentPosition.name === "identificationSelection" && (
        <DocumentIcon imgLogo="id" />
      )}
      {currentPosition.name === "passport" && (
        <DocumentIcon imgLogo="pasaporte" />
      )}
      {currentPosition.name === "ineFront" && <DocumentIcon imgLogo="id" />}
      {currentPosition.name === "ineBack" && <DocumentIcon imgLogo="id" />}
      {currentPosition.name === "bankStatement" && (
        <DocumentIcon imgLogo="estadoCuenta" />
      )}
      {currentPosition.name === "logo" && <DocumentIcon imgLogo="logoIcon" />}
      {currentPosition.name === "validating" && (
        <DocumentIcon imgLogo="validandoDocs" />
      )}
      {currentPosition.name === "reuploadTheFollowingDocuments" && (
        <DocumentIcon imgLogo="docsError" />
      )}

      {currentPosition.name === "success" && <DocumentIcon imgLogo="docsOk" />}
    </>
  );
}
