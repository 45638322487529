import { encode as base64Encode } from "base-64";
import axios from "axios";
import { data } from "./entities/Data";
import { input } from "./entities/Input";

// TODO: Flujo de envio de archivos a metamap

const getDocumentType = (storeName, fileName, backName) => {
  const array = [];
  fileName = fileName.toLowerCase();
  const dp = "document-photo";
  switch (storeName) {
    case "bankStatement":
      array.push(
        input(
          "custom-document-photo",
          0,
          data("custom-bank-account", "multi", fileName)
        )
      );
      return array;
    case "CSF":
      array.push(
        input(
          "custom-document-photo",
          0,
          data("custom-constancia-fiscal", "multi", fileName)
        )
      );
      return array;
    case "ineBack":
      array.push(input(dp, 0, data("national-id", "front", fileName, "MX")));
      array.push(input(dp, 0, data("national-id", "back", backName, "MX")));
      return array;
    case "passport":
      array.push(input(dp, 0, data("passport", "front", fileName, "MX")));
      return array;
    default:
      return array;
  }
};

const getFormDocumentType = (storeName) => {
  const document = "document";
  const customDocument = "custom-document";
  switch (storeName) {
    case "CSF":
      return customDocument;
    case "ineBack":
      return document;
    case "bankStatement":
      return customDocument;
    case "logo":
      return document;
    case "passport":
      return document;
    default:
      return customDocument;
  }
};

async function loginMetamap() {
  const headers = new Headers();
  const basicAuth = base64Encode(`${process.env.REACT_APP_MATI_CLIENT_ID}:${process.env.REACT_APP_MATI_CLIENT_SECRET}`)
  headers.append(
    "Authorization",
    `Basic ${basicAuth}`
  );
  headers.append("Content-Type", "application/x-www-form-urlencoded");
  const dataLogin = "grant_type=client_credentials";

  return fetch(`${process.env.REACT_APP_METAMAP_URL}/oauth/`, {
    method: "POST",
    headers,
    body: dataLogin,
  }).then((resLogin) => resLogin.json());
};

export const getVerification = async (flowId, token) => {
  const headersVerification = new Headers();
  headersVerification.append("Content-Type", "application/json");
  headersVerification.append("Authorization", `Bearer ${token}`);

  return fetch(`${process.env.REACT_APP_METAMAP_URL}/v2/verifications/`, {
    method: "POST",
    headers: headersVerification,
    mode: "cors",
    body: JSON.stringify({ flowId }),
  }).then((verificationMeta) => verificationMeta.json());
};

async function executeSendFile(
  identity,
  token,
  formDocumentType,
  myJSON,
  file,
  storeName,
  fileIneFront
) {
  const formData = new FormData();
  if (storeName === "ineBack") {
    formData.append(
      formDocumentType,
      fileIneFront,
      fileIneFront.name.toLowerCase()
    );
    myJSON = JSON.stringify(
      getDocumentType(storeName, fileIneFront.name, file.name.toLowerCase())
    );
  }
  formData.append("inputs", myJSON);
  formData.append(formDocumentType, file, file.name.toLowerCase());

  axios
    .post(
      `${process.env.REACT_APP_METAMAP_URL}/v2/identities/${identity}/send-input`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      response.data.forEach((item) => {
        if (item.error) {
          throw new Error('Error al enviar archivo a metamap');
        }
      });
    });
};

export async function sendFileToMetamap(file, flowId, storeName, fileIneFront) {
  // login
  const login = await loginMetamap();
  const token = login.access_token;
  // verification
  const verification = await getVerification(flowId, token);

  const { identity } = verification;
  const documentId = verification.id;
  // sendFile
  const documentType = getDocumentType(storeName, file.name);
  const myJSON = JSON.stringify(documentType);
  const formDocumentType = getFormDocumentType(storeName);

  await executeSendFile(
    identity,
    token,
    formDocumentType,
    myJSON,
    file,
    storeName,
    fileIneFront
  );

  return {
    id: documentId,
    identity: identity
  }
}

export function getIdDocumentType(storeName) {
  switch (storeName) {
    case "ineBack":
      return 1;
    case "CSF":
      return 2;
    case "bankStatement":
      return 3;
    case "passport":
      return 4;
  }
}