/* eslint-disable react/forbid-prop-types */
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import TagManager from "react-gtm-module";
import { connect, useSelector } from "react-redux";
import BaseMD from "../../../google-analytics/events/baseMD";
import searchInputInReduxStore from "../../../scripts/searchInputInReduxStore";
import checkFromStore from "../utils/checkFromStore";
import styles from "./RadioControllerSelector.module.sass";

export function RadioControllerSelectorComponent({
  state,
  externalHandler,
  storeName,
  options,
  title,
  propertyName,
  callValidation,
}) {
  const s = styles;

  const [validationErrorMsg, setValidationErrorMsg] = useState("");
  const currentSectionMD = useSelector((state) => state.currentSection.name);
  const typeOfPersonId = useSelector((state) => state.typeOfPerson?.id);
  const officialData = useSelector((state) => state.officialData);

  useEffect(() => {
    if (validationErrorMsg === "") return;
    TagManager.dataLayer(
      BaseMD.MD3C(
        validationErrorMsg,
        currentSectionMD,
        title,
        officialData,
        typeOfPersonId
      )
    );
  }, [validationErrorMsg]);

  function verifyRequired() {
    const radioButtons = document.querySelector(
      `input[name = ${propertyName}]:checked`
    );

    if (radioButtons === null) {
      setValidationErrorMsg("Elige una opción para continuar");
    } else {
      setValidationErrorMsg("");
    }
  }

  const alreadyMounted = useRef(false);

  useEffect(() => {
    if (alreadyMounted.current) {
      // show message if user click on next button without selecting an option
      verifyRequired();
    } else {
      alreadyMounted.current = true;
    }

    const currentInput = searchInputInReduxStore(storeName, state);

    if (currentInput !== undefined) {
      const radioButtons = document.querySelectorAll(
        `input[name = ${propertyName}]`
      );

      radioButtons.forEach((radioButton) => {
        if (radioButton.value === currentInput.value) {
          radioButton.checked = true;
        }
      });
      externalHandler(currentInput.value);
    }
  }, [callValidation]);
  return (
    <div className={s.radioWrapper}>
      <h3>{title}</h3>
      <div className={s.options}>
        {options.map((option) => {
          return (
            <label
              htmlFor={option.name + option.id}
              key={option.name + option.id}
            >
              <input
                type="radio"
                defaultChecked={
                  checkFromStore(option.id.toString(), storeName, state, "") ||
                  false
                }
                className={s.option}
                name={propertyName}
                id={option.name + option.id}
                onChange={(e) => {
                  externalHandler(e.target.value);
                  setValidationErrorMsg("");
                }}
                value={option.id || 1}
              />
              {option.name}
            </label>
          );
        })}
        <span className={s.inputErrorFeedback}>{validationErrorMsg}</span>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    state,
  };
}

export const RadioControllerSelector = connect(
  mapStateToProps,
  null
)(RadioControllerSelectorComponent);

RadioControllerSelectorComponent.propTypes = {
  state: PropTypes.object.isRequired,
  title: PropTypes.string,
  storeName: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  externalHandler: PropTypes.func,
  propertyName: PropTypes.string,
  callValidation: PropTypes.bool,
};

RadioControllerSelectorComponent.defaultProps = {
  externalHandler: () => {
    return null;
  },
  callValidation: false,
  propertyName: Math.ceil(Math.random() * 100),
  title: "",
};
