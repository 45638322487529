export default function makeCtrRecepcionPopData(
  officialData,
  commercialInfo,
  companyData,
  typeOfPersonName
) {
  let ctrRecepcionPopData;

  if (commercialInfo.commercialInformationWhereToUseCPPhysical === false) {
    ctrRecepcionPopData = null;
  } else if (
    commercialInfo.commercialInformationOptionsPOPAddress === "1" &&
    typeOfPersonName === "moral"
  ) {
    ctrRecepcionPopData = {
      street: companyData.streetCompanyData,
      outdoor_number: parseInt(companyData.exteriorNumberCompanyData, 10) || 0,
      interior_number:
        parseInt(companyData.interiorNumberCompanyData, 10) || 0,
      cp: parseInt(companyData.postalCodeCompanyData, 10),
      colony: companyData.suburbCompanyData,
      city: companyData.cityCompanyData,
      entity: companyData.stateCompanyData,
      country: 1,
      name: commercialInfo.commercialInformationPopName,
      phone: commercialInfo.commercialInformationPopPhone,
      email: commercialInfo.commercialInformationPopEmail,
      phone_type: commercialInfo.commercialInformationTypePhone,
      email_type: commercialInfo.commercialInformationTypeEmail,
    };
  } else if (
    commercialInfo.commercialInformationOptionsPOPAddress === "1" &&
    typeOfPersonName === "physical"
  ) {
    ctrRecepcionPopData = {
      street: officialData.streetOfficialData,
      outdoor_number: parseInt(officialData.exteriorNumberOfficialData, 10) || 0,
      interior_number:
        parseInt(officialData.interiorNumberOfficialData, 10) || 0,
      cp: parseInt(officialData.postalCodeOfficialData, 10),
      colony: officialData.suburbOfficialData,
      city: officialData.cityOfficialData,
      entity: officialData.stateOfficialData,
      country: 1,
      name: commercialInfo.commercialInformationPopName,
      phone: commercialInfo.commercialInformationPopPhone,
      email: commercialInfo.commercialInformationPopEmail,
      phone_type: commercialInfo.commercialInformationTypePhone,
      email_type: commercialInfo.commercialInformationTypeEmail,
    };
  } else {
    ctrRecepcionPopData = {
      street: commercialInfo.streetPhysicalTaxpayer,
      outdoor_number: parseInt(
        commercialInfo.exteriorNumberPhysicalTaxpayer,
        10
      ) || 0,
      interior_number:
        parseInt(commercialInfo.exteriorNumberPhysicalTaxpayer, 10) || 0,
      cp: parseInt(commercialInfo.physicalPostalCode, 10),
      colony: commercialInfo.suburbCommercialInfo,
      city:
        commercialInfo.cityCommercialInfo || commercialInfo.stateCommercialInfo,
      entity: commercialInfo.stateCommercialInfo,
      country: commercialInfo.countryCommercialInfo,
      name: commercialInfo.commercialInformationPopName,
      phone: commercialInfo.commercialInformationPopPhone,
      email: commercialInfo.commercialInformationPopEmail,
      phone_type: commercialInfo.commercialInformationTypePhone,
      email_type: commercialInfo.commercialInformationTypeEmail,
    };
  }
  return ctrRecepcionPopData;
}
