import axios from 'axios';

const updateClv = (data) => {
    return axios
        .post(process.env.REACT_APP_UPDATE_CLV, data)
        .then((response) => {
            if (response.status === 200 && response.data.status === "SUCCESS") {
                return response.data;
            }
            return 500;
        })
        .catch(function (error) {
            if (error.response) {
              
                return error.response.data;
            } else if (error.request) {
              
                return 500;
            } else {
                return 500;
            }
          });

};

export default updateClv; 