import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Contacto } from "../sections/Contact";
import { Welcome } from "../sections/Welcome";
import { OfficialData } from "../sections/OfficialData";
import { CommercialInformation } from "../sections/CommercialInformation";
import WebSocketClassComponent from "../../classcomponents/WebSocketClassComponent";
import { Confirmation } from "../sections/Confirmation";
import { CompanyData } from "../sections/CompanyData/CompanyData";

function SectionSwitch({ state }) {
  // TODO CURRENT SECTION REDUX
  const currentSection = state.currentSection.value;

  switch (currentSection) {
    case 0:
      return <Welcome />;
    case 1:
      return <WebSocketClassComponent />;
    case 2:
      return <Contacto />;
    case 3:
      return <OfficialData />;
    case 4:
      return <CompanyData />;
    case 5:
      return <CommercialInformation />;
    case 6:
      return <Confirmation />;
    default:
      break;
  }
}

SectionSwitch.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  state: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    state,
  };
}

export default connect(mapStateToProps)(SectionSwitch);
