import React, { useEffect } from "react";
import PropTypes from "prop-types";
import errorIcon from "../assets/conexion.svg";
import cerrarIcon from "../assets/cerrar.svg";
import styles from "./ModalError.module.sass";
import WelcomeBtn from "../../WelcomeBtn/WelcomeBtn";
import TagManager from "react-gtm-module";
import BaseMD from "../../../google-analytics/events/baseMD";

const ModalError = ({ toggle, MD, MDEvent }) => {
  const s = styles;

  useEffect(() => {
    TagManager.dataLayer(BaseMD[MD]("Modal - Ocurrió un problema"));
  }, []);

  const handleClose = (btn) => {
    if (btn === "Aceptar") {
      TagManager.dataLayer(
        BaseMD[MDEvent]("Modal - Ocurrió un problema - Aceptar")
      );
    }
    if (btn === "Cerrar") {
      TagManager.dataLayer(
        BaseMD[MDEvent]("Modal - Ocurrió un problema - Cerrar")
      );
    }

    toggle();
  };
  return (
    <>
      <div>
        <div>
          <button
            className={s.closeBtn}
            onClick={() => {
              handleClose("Cerrar");
            }}
          >
            <img src={cerrarIcon} alt="" />
          </button>
        </div>
        <div className={s.tittle}>
          <h2>Ocurrió un problema</h2>
        </div>
        <div className={s.imgContainer}>
          <img src={errorIcon} alt="" />
        </div>
        <div className={s.children}>
          <p>
            Por el momento no es posible continuar con el proceso, inténtalo más
            tarde
          </p>
          <div className={s.btnContainer}>
            <WelcomeBtn
              tittle="Aceptar"
              disabled={false}
              onClick={() => {
                handleClose("Aceptar");
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

ModalError.propTypes = {
  toggle: PropTypes.func.isRequired,
  MD: PropTypes.string.isRequired,
  MDEvent: PropTypes.string.isRequired,
};

export default ModalError;
