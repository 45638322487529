import axios from "axios";

export let plataformas = [];

axios
  .get(process.env.REACT_APP_GET_PLUGINS)
  .then(function (response) {
    plataformas = response.data.data;
  })
  .catch(function () {
    plataformas = [
      {
        name: "Ninguna de estas",
        id: 1,
      },
      {
        name: "Shopify",
        id: 2,
      },
      {
        name: "Adobe (antes Magento)",
        id: 3,
      },
      {
        name: "Prestashop",
        id: 4,
      },
      {
        name: "Vtex",
        id: 5,
      },
    ];
  });
