import PropTypes from "prop-types";
import React from "react";
import TagManager from "react-gtm-module";
import BaseMD from "../../google-analytics/events/baseMD";
import coppelLogo from "../../images/coppel-logo.svg";
import BlueBtn from "../BlueBtn/BlueBtn";
import styles from "./Header.module.sass";

export function Header({ isWelcome }) {
  const s = styles;
  return (
    <header className={`${s.header} ${isWelcome ? s.headerWelcome : ""}`}>
      <div className={s.header__logos}>
        <a
          href="https://www.coppel.com/"
          aria-label="Ir a página principal de Coppel.com. Abre en pestaña nueva"
          rel="noopener noreferrer"
          target="_blank"
          onClick={() => {
            TagManager.dataLayer(BaseMD.MD2A1("Logo Coppel.com"));
          }}
        >
          <img
            alt="Logo de Coppel."
            src={coppelLogo}
            width="140"
            height="105"
          />
        </a>
        <a
          href="https://www.coppel.com/coppel-pay"
          aria-label="Ir a página principal de Coppel Pay. Abre en pestaña nueva"
          rel="noopener noreferrer"
          target="_blank"
          className={isWelcome ? s.hiddenElement : ""}
          onClick={() => {
            TagManager.dataLayer(BaseMD.MD2A1("Logo Coppel Pay"));
          }}
        >
          <img
            alt="Logo de Coppel Pay."
            src={process.env.REACT_APP_LOGO_COPPELPAY}
            height="80"
          />
        </a>
      </div>
      {isWelcome && (
        <div>
          <BlueBtn
            extraClass={s.buttonCustomer}
            text="Soy cliente"
            handleOnClickBlueBtn={() => {
              TagManager.dataLayer(BaseMD.MD2A1("Soy cliente"));
              window.open(
                "https://www.coppel.com/coppel-pay",
                "_blank",
                "noopener noreferrer"
              );
            }}
          />
        </div>
      )}
    </header>
  );
}
Header.propTypes = {
  isWelcome: PropTypes.bool,
};

Header.defaultProps = {
  isWelcome: false,
};
