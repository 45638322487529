/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { ClockCircleFilled, InfoCircleFilled } from "@ant-design/icons";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import TagManager from "react-gtm-module";
import { connect } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import BaseMD from "../../../../google-analytics/events/baseMD";
import arrowLeftIcon from "../../../../images/icons/arrow-left.svg";
import { recoveryClvAPI } from "../../../../scripts/recoveryClvAPI";
import styles from "./RecoveryLanding.module.sass";

function RecoveryLandingComponent({ formData, handleEditEmail }) {
  const navigate = useNavigate();
  const s = styles;
  const boolActive = false;
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });
  const [countDown, setCountDown] = useState(60);
  const [isActive, setIsActive] = useState(false);
  const [intentos, setIntentos] = useState(0);
  const [showText, setShowText] = useState(false);
  const [showClock, setShowClock] = useState(false);
  const [msg, setMsg] = useState("");

  useEffect(() => {
    let timer;
    if (isActive) {
      setShowClock(true);
      timer = setInterval(() => {
        setCountDown((count) => {
          if (count <= 0) {
            clearInterval(timer);
            setIsActive(false);
            return 0;
          }
          return count - 1;
        });
      }, 1000);
    } else {
      clearInterval(timer);
      setCountDown(60);
      setShowClock(false);
    }
    return () => clearInterval(timer);
  }, [isActive]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `0${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const reSendEmail = () => {
    setIntentos(intentos + 1);
    recoveryClvAPI(formData);
    TagManager.dataLayer(BaseMD.DM5F_R());
  };
  useEffect(() => {
    if (intentos === 3) {
      setShowText(true);
      const msgOneMore =
        "Tienes un intento más para solicitar el reenvío de correo de recuperación de contraseña.";
      setMsg(msgOneMore);
      TagManager.dataLayer(BaseMD.DM5D_R(msgOneMore));
    }

    if (intentos === 4) {
      setShowText(true);
      const msgLast = "Superaste el número de intentos de reenvío permitidos.";
      setMsg(msgLast);
      TagManager.dataLayer(BaseMD.DM5D_R(msgLast));
    }
  }, [intentos]);

  const sendEmail = () => {
    setIsActive(true);
    reSendEmail();
  };

  const onClickEditEmail = () => {
    TagManager.dataLayer(BaseMD.DM5E_R());
    handleEditEmail(false);
  };

  const handleAccept = () => {
    TagManager.dataLayer(BaseMD.DM5G_R());
    navigate("/");
  };

  const handleBack = () => {
    TagManager.dataLayer(BaseMD.DM5A_R());
    navigate("/");
  };

  return (
    <div className={s.content}>
      <div className={s.btnRegresarContainer}>
        <button type="button" className={s.btnRegresar} onClick={handleBack}>
          <img src={arrowLeftIcon} alt="Ir al inicio" />{" "}
          {isMobile ? "" : "Ir al inicio"}
        </button>
      </div>
      <div className={s.recoveryForm}>
        <div className={s.imageContainer}>
          <img
            alt="Logo de Coppel Pay."
            src={process.env.REACT_APP_LOGO_COPPELPAY}
            width="324"
            height="154"
            className={s.imageLogo}
          />
        </div>
        <h2 className={s.title}>Restablece tu contraseña</h2>
        {showText && (
          <div className={s.divAlert}>
            <div className={s.col1}>
              <InfoCircleFilled />
            </div>
            <div className={s.col2}>
              <p className={s.alert}>
                <span>{msg}</span>
              </p>
            </div>
          </div>
        )}
        <p className={s.subtitleRecovery}>
          Si la dirección <strong>{formData.email}</strong> coincide con la de
          tu cuenta, te enviaremos por correo un link de recuperación de
          contraseña
        </p>
        <div className={s.linkContainer}>
          <a href="#" className={s.link} onClick={onClickEditEmail}>
            Editar correo
          </a>
        </div>
        <div className={s.linkContainer}>
          {!showClock && intentos < 4 && (
            <a href="#" className={s.link} onClick={sendEmail}>
              Reenviar correo
            </a>
          )}
          {showClock && intentos < 4 && (
            <p className={s.linkClock}>
              <ClockCircleFilled /> Reenviar correo en {formatTime(countDown)}
            </p>
          )}
        </div>
        {intentos === 4 && (
          <div className={s.btnContainer}>
            <button
              type="button"
              className={`${s.btnAceptar} ${boolActive ? s.active : ""}`}
              onClick={handleAccept}
            >
              Aceptar
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

RecoveryLandingComponent.propTypes = {
  formData: PropTypes.isRequired,
  handleEditEmail: PropTypes.func.isRequired,
};

export const RecoveryLanding = connect()(RecoveryLandingComponent);
