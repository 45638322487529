import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import TagManager from "react-gtm-module";
import { useDispatch, useSelector } from "react-redux";
import BaseMD from "../../../../../google-analytics/events/baseMD";
import { deleteItemByName } from "../../../../../redux-modules/form/actions";
import convertArrayToJson from "../../../../../utils/sass/js/convertArrayToObject";
import styles from "./FileInput.module.sass";

export default function FileInput({
  storeName,
  title,
  mainContent,
  button,
  stepCurrent = 0,
}) {
  const s = styles;
  const dispatch = useDispatch();
  if (title === "stillWaiting") {
    // const splitIndex = title.indexOf("está");
    // const firstRow = title.slice(0, splitIndex + 4);
    // const secondRow = title.slice(splitIndex + 4);
    title = (
      <>
        La carga de documentos nos está
        <br />
        llevando mas tiempo de lo esperado
      </>
    );
  }

  // fetch documents from state
  let documents = useSelector((state) => state.documents);
  // we convert them to json so we can use them as an object
  documents = convertArrayToJson(documents);
  // we find the document we want, then we can use the data

  const data = documents[storeName];
  const currentSection = useSelector((state) => state.currentSection.name);
  const typeOfPersonId = useSelector((state) => state.typeOfPerson?.id);
  const officialData = useSelector((state) => state.officialData);
  useEffect(() => {
    TagManager.dataLayer(BaseMD.MD3(officialData, typeOfPersonId, title));
  }, []);

  if (data?.errors) {
    data.errors = [...new Set(data.errors)];
    for (const error of data.errors) {
      if (typeof error !== "string") continue;
      TagManager.dataLayer(BaseMD.MD3C(error, currentSection, title));
    }
  }
  function handleOnDeleteDocument() {
    TagManager.dataLayer(BaseMD.MD3E(false, title));
    dispatch(deleteItemByName({ name: storeName }));
  }

  return (
    <div className={s.fileInput}>
      <h2 className={s.title}>{title}</h2>
      <div className={s.mainContent}>{mainContent}</div>
      {button && (
        <div className={s.file}>
          <div className={s.divButtonName}>
            <div className={s.fileBtn}>{button}</div>
            <div className={s.fileName}>
              <span className={s.name}>{data?.fileName ?? ""}</span>
              {data?.file && (
                <FontAwesomeIcon
                  icon={faCircleXmark}
                  className={s.close}
                  onClick={() => {
                    handleOnDeleteDocument();
                  }}
                />
              )}
            </div>
          </div>
          <div className={`${data?.fileName ? s.divErrors : s.divLocalErrors}`}>
            <div className={s.fileError}>
              {data?.errors?.map((message) => (
                <span key={message}>{message}</span>
              ))}
            </div>
          </div>
        </div>
      )}
      {stepCurrent !== 0 && (
        <div className={s.stepper}>
          <div className={s.stepList}>
            <li className={s.stepItem}>
              <span
                className={`${s.stepNumber} ${
                  stepCurrent === 1 ? s.activo : null
                }`}
              >
                {" "}
              </span>
            </li>
            <li className={s.stepItem}>
              <span
                className={`${s.stepNumber} ${
                  stepCurrent === 2 ? s.activo : null
                }`}
              >
                {" "}
              </span>
            </li>
            <li className={s.stepItem}>
              <span
                className={`${s.stepNumber} ${
                  stepCurrent === 3 ? s.activo : null
                }`}
              >
                {" "}
              </span>
            </li>
            <li className={s.stepItem}>
              <span
                className={`${s.stepNumber} ${
                  stepCurrent === 4 ? s.activo : null
                }`}
              >
                {" "}
              </span>
            </li>
          </div>
        </div>
      )}
    </div>
  );
}

FileInput.propTypes = {
  title: PropTypes.string.isRequired,
  mainContent: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  button: PropTypes.oneOfType([PropTypes.bool, PropTypes.node]),
};

FileInput.defaultProps = {
  mainContent: null,
  button: false,
};
