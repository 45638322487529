export default function checkFromStore(
  itemName,
  storeName,
  state,
  sectionCopyFrom,
  canCopy = false
) {
  let currentSection = state.currentSection.name;
  if (canCopy) currentSection = sectionCopyFrom;
  let isChecked = false;

  if (state[currentSection] !== undefined) {
    state[currentSection].map((item) => {
      if (item.name === storeName && item.value === itemName) {
        isChecked = true;
      }
      return isChecked;
    });
  }

  return isChecked;
}
