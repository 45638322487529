import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { DivisionLine } from "../../../DivisionLine";
import TextInput from "../../../inputs/TextInput/TextInput";

import styles from "./PhysicalCompanyData.module.sass";
import { TextArea } from "../../../inputs/TextArea";
import { banks } from "../ctl_bank";
import {
  updateInput,
  updateInputStatus,
} from "../../../../redux-modules/form/actions";
import { personaFisica } from "../../OfficialData/dataAutoFill";

function PhysicalCompanyDataComponent({ callValidation, state, dispatch }) {
  const s = styles;
  const [bankDescription, setBankDescription] = useState("");
  const { currentSection } = state;

  useEffect(() => {
    const dataPF = personaFisica(state);
    if (state.companyData && state.companyData.length > 0){
      return;
    }

    // BANK DATA
    dispatch(
      updateInput({
        name: "companyDataBankAccountTitularName",
        value: dataPF.fisica.nombreTitular ?? "",
        validated: dataPF.fisica.nombreTitular !== "",
      })
    );

    dispatch(
      updateInput({
        name: "companyDataBankNumber",
        value: dataPF.fisica.cuenta ?? "",
        validated: dataPF.fisica.cuenta !== "",
      })
    );

    dispatch(
      updateInput({
        name: "companyDataBankCLABE",
        value: dataPF.fisica.clabe ?? "",
        validated: dataPF.fisica.clabe !== "",
      })
    );
  }, []);

  function getValuesFromStoreBankNumber(reduxState) {
    let options;
    reduxState[currentSection.name].map((inputItem) => {
      if (inputItem.name === `companyDataBankCLABE`) {
        options = inputItem.value;
      }
      return options;
    });
    return options;
  }

  useEffect(() => {
    const options = getValuesFromStoreBankNumber(state);
    if (options) {
      let acount = options?.toString();
      acount = acount?.slice(0, 3);
      if (acount.length == 3) {
        let bank = banks.find((bank) => bank.acountId == acount);
        if (bank) {
          let bankName = bank.name;
          setBankDescription(bankName);
          dispatch(
            updateInput({
              name: "companyDataBankName",
              value: bankName,
              validated: true,
            })
          );
          dispatch(
            updateInputStatus({ name: "companyDataBankName", validated: true })
          );
          return;
        }
        setBankDescription("N/A");
        dispatch(
          updateInputStatus({ name: "companyDataBankName", validated: false })
        );
        return;
      }
      setBankDescription("");
      dispatch(
        updateInputStatus({ name: "companyDataBankName", validated: false })
      );
      return;
    }
    setBankDescription("Ingresa tu CLABE interbancaria");
  }, [getValuesFromStoreBankNumber(state)]);

  const altTitle =
    "Datos de la empresa. Estás en el paso 3 de 5. Necesitarás escribir tus redes sociales, sitio web, palabras clave y datos bancarios";
  return (
    <div className={s.taxpayerData}>
      <div className={s.titleAndDescription}>
        <h1 className={s.title} alt={altTitle}>
          Datos de la empresa
        </h1>
      </div>
      <div className={s.titleAndDescription}>
        <h2 className={s.title}>¿Cuál es el nombre comercial de tu empresa?</h2>
      </div>
      <div className={s.inputComercialName}>
        <TextInput
          required
          title="Nombre comercial"
          placeholder="Nombre comercial"
          storeName="companyDataCommercialName"
          inputValidation="default"
          aditionalInfo="Este no debe ser la razón social, sino como es conocida tu empresa como marca"
          callValidation={callValidation}
          hasLengthLimit={50}
          customRequiredMessage="Debes escribir el nombre comercial de tu empresa para continuar"
        />
      </div>
      <DivisionLine />
      <div className={s.titleAndDescription}>
        <h2 className={s.title}>Redes sociales de la empresa</h2>
      </div>
      <div className={s.inputsCompanyData}>
        <TextInput
          title="Facebook"
          placeholder="Facebook"
          storeName="companyDataFacebook"
          inputValidation="default"
          callValidation={callValidation}
        />

        <TextInput
          title="Instagram"
          placeholder="Instagram"
          storeName="companyDataInstagram"
          inputValidation="default"
          callValidation={callValidation}
        />

        <div className={s.whiteSpace} />

        <TextInput
          title="Twitter"
          placeholder="Twitter"
          storeName="companyDataTwitter"
          inputValidation="default"
          callValidation={callValidation}
        />

        <TextInput
          title="Sitio Web"
          placeholder="Sitio Web"
          storeName="companyDataWebsite"
          inputValidation="default"
          callValidation={callValidation}
        />

        <div className={s.whiteSpace} />

        <TextArea
          customRequiredMessage="Escribe las palabras clave"
          required
          storeName="companyDataKeywords"
          callValidation={callValidation}
          title="Ingresa palabras clave de la empresa"
          placeholder={`Escribe palabras relacionadas con el producto o servicio, separadas con coma. Por ejemplo: 
"pintura, brochas, ferretería".`}
        />
      </div>

      <DivisionLine />
      <div className={s.titleAndDescription}>
        <h2 className={s.title}>Cuenta bancaria</h2>
        <p className={s.subtitle}>
          Verifica que los datos de la cuenta que va a recibir los pagos sean
          correctos
        </p>
      </div>
      <div className={s.inputsCompanyData}>
        <TextInput
          required
          title="Nombre completo del titular"
          placeholder="Nombre completo del titular"
          storeName="companyDataBankAccountTitularName"
          inputValidation="default"
          callValidation={callValidation}
          aditionalInfo="Debe coincidir con el nombre del contribuyente"
          thisValueWillBeSetFromRedux
        />
        <TextInput
          required
          title="Número de cuenta"
          placeholder="Número de cuenta"
          storeName="companyDataBankNumber"
          inputValidation="default"
          callValidation={callValidation}
          hasLengthLimit={18}
          type="number"
          thisValueWillBeSetFromRedux
        />
        <div className={s.whiteSpace} />

        <TextInput
          required
          title="CLABE interbancaria"
          placeholder="CLABE interbancaria"
          storeName="companyDataBankCLABE"
          type="number"
          hasLengthLimit="18"
          inputValidation="CLABE"
          callValidation={callValidation}
          aditionalInfo="Escribe los 18 dígitos"
          thisValueWillBeSetFromRedux
        />
        <div className={s.inputReadOnly}>
          <div
            className={`${
              bankDescription == "Ingresa tu CLABE interbancaria"
                ? s.hidden
                : {}
            } ${
              bankDescription == "" || bankDescription == "N/A"
                ? s.bankNameTitleDisabled
                : s.bankNameTitle
            } `}
          >
            {" "}
            Nombre del banco*
          </div>
          <div className={s.bankName}>{bankDescription}</div>
        </div>
      </div>

      <DivisionLine />
    </div>
  );
}

PhysicalCompanyDataComponent.propTypes = {
  callValidation: PropTypes.bool,
};

PhysicalCompanyDataComponent.defaultProps = {
  callValidation: false,
};
function mapStateToProps(state) {
  return {
    state,
  };
}

export const PhysicalCompanyData = connect(mapStateToProps)(
  PhysicalCompanyDataComponent
);
