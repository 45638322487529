/* eslint-disable react/jsx-no-comment-textnodes */
import { Select } from "antd";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import TagManager from "react-gtm-module";
import { connect, useSelector } from "react-redux";
import BaseMD from "../../../google-analytics/events/baseMD";
import {
  updateInput,
  updateInputStatus,
  verifyValidationsCommercialInformation,
  verifyValidationsOfficialData,
} from "../../../redux-modules/form/actions";
import searchInputInReduxStore from "../../../scripts/searchInputInReduxStore";
import styles from "./Select.module.sass";

const { Option } = Select;
function SelectUIComponent({
  id,
  name,
  options,
  storeName,
  callValidation,
  state,
  dispatch,
  defaultValue,
  customRequiredMessage,
  onChange,
  externalValidation = false,
}) {
  const s = styles;
  const [errorMsg, setErrorMsg] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [selectedValue, setSelectedValue] = useState({
    alreadySelectedValue: false,
    value: defaultValue,
  });
  const alreadyMounted = useRef(false);
  const currentSectionMD = useSelector((state) => state.currentSection.name);
  const typeOfPersonId = useSelector((state) => state.typeOfPerson?.id);
  const officialData = useSelector((state) => state.officialData);

  useEffect(() => {
    if (errorMsg === "") return;

    TagManager.dataLayer(
      BaseMD.MD3C(
        errorMsg,
        currentSectionMD,
        name,
        officialData,
        typeOfPersonId
      )
    );
  }, [errorMsg]);
  function handleChange(value) {
    if (
      value !== undefined &&
      value !== null &&
      value !== "Introduce un código postal"
    ) {
      setSelectedValue({
        alreadySelectedValue: true,
        value,
      });

      if (!externalValidation) {
        const payload = { name: storeName, value, validated: true };
        const payloadStatus = { name: storeName, validated: true };

        dispatch(updateInput(payload));
        dispatch(updateInputStatus(payloadStatus));
      }
      setErrorMsg("");
    }

    if (value !== defaultValue) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
    if (!externalValidation) {
      const currentSection = state.currentSection.name;
      switch (currentSection) {
        case "officialData":
          dispatch(verifyValidationsOfficialData());
          break;
        case "commercialInfo":
          dispatch(verifyValidationsCommercialInformation());
          break;
        default:
          break;
      }
    }
  }

  useEffect(() => {
    if (alreadyMounted.current) {
      const input = searchInputInReduxStore(storeName, state);
      if (input.length === 0 || input[0].value === null) {
        if (customRequiredMessage) {
          setErrorMsg(customRequiredMessage);
        } else {
          setErrorMsg("Selecciona una opción para continuar");
        }
      }
    } else {
      alreadyMounted.current = true;
    }

    if (searchInputInReduxStore(storeName, state).length > 0) {
      const conlog = searchInputInReduxStore(storeName, state);

      setIsVisible(true);
      setSelectedValue({
        alreadySelectedValue: true,
        value: conlog[0].value,
      });
    }
  }, [callValidation]);

  return (
    <div className={s.wrapper}>
      <div className={`${s.label} ${isVisible ? s.visible : s.hidden}`}>
        {name}
      </div>
      <Select
        name={name}
        id={id}
        value={selectedValue.value}
        style={{ width: "100%" }}
        onChange={(e) => {
          handleChange(e);
          if (onChange) onChange(e);
        }}
      >
        {options.map((item) => {
          return (
            <Option key={item + storeName} value={item}>
              {item}
            </Option>
          );
        })}
      </Select>
      <small className={s.error}>{errorMsg}</small>
    </div>
  );
}

SelectUIComponent.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  storeName: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  dispatch: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  state: PropTypes.object.isRequired,
  callValidation: PropTypes.bool,
  defaultValue: PropTypes.string.isRequired,
  customRequiredMessage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  externalValidation: PropTypes.bool,
};

SelectUIComponent.defaultProps = {
  customRequiredMessage: false,
  callValidation: false,
};

function mapStateToProps(state) {
  return {
    state,
  };
}

export const SelectUI = connect(mapStateToProps, null)(SelectUIComponent);
