import React from "react";
import { createRoot } from "react-dom/client";
import "antd/dist/antd.min.css";

// Redux
import { Provider } from "react-redux";
import { createStore } from "@reduxjs/toolkit";
import { reducer } from "./redux-modules/form/index";

import App from "./components/App/App";
import "./index.css";

const initialState = {
  currentSection: {
    value: 0,
    name: "welcome",
  },
  requiredInputsCount: {
    officialData: null,
    counterNewAssociates: 0,
    counterNewAdmins: 0,
  },
  welcome: [],
  documents: [],
  contact: [],
  officialData: [],
  companyData: [],
  commercialInfo: [],
  confirmation: [],
  typeOfPerson: undefined,
  infoMetamap: [],
  contactValidations: false,
  officialDataValidations: false,
  companyDataValidations: false,
  commercialInformationValidations: false,
  utils: [{ count: 0 }],
};
const store = createStore(
  reducer,
  initialState,
  // eslint-disable-next-line no-underscore-dangle
  process.env.REACT_APP_ENV_NAME !== "prod"
    ? window.__REDUX_DEVTOOLS_EXTENSION__?.({
        trace: true,
        traceLimit: 25,
      })
    : (f) => f
);

const divRoot = document.getElementById("root");
const root = createRoot(divRoot);

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <form autoComplete="off" id="form">
      <App />
    </form>
  </Provider>
  // </React.StrictMode>,
);
