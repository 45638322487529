import React from "react";
import PropTypes from "prop-types";

import styles from "./documentIcon.module.sass";

function DocumentIcon({ imgLogo }) {
  return <div className={`${styles.container} ${styles[imgLogo]}`} />;
}

DocumentIcon.propTypes = {
  imgLogo: PropTypes.string,
};

DocumentIcon.defaultProps = {
  imgLogo: "placeHolder",
};

export default DocumentIcon;
