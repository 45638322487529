import { useState, useEffect } from "react";
import axios from "axios";

function useFetchPostalData(url) {
  const [data, setData] = useState(null);

  useEffect(() => {
    const source = axios.CancelToken.source();
    function fetchPCData() {
      axios
        .get(url, { cancelToken: source.token })
        .then((res) => {
          setData(res);
        })
        .catch((err) => {
          setData({ error: err });
        });
    }
    fetchPCData();
  }, [url]);

  return { data };
}
export default useFetchPostalData;
