import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./StepperDocs.sass";

export default function Stepper({ funct }) {
  const steps = [
    "Constancia de situación fiscal",
    "Identificación oficial",
    "Estado de cuenta",
    "Logo",
  ];

  const [currentStep, setCurrentStep] = useState(0);
  const metamapCounter = useSelector((state) => state.utils);

  const processed = metamapCounter.map(({ count }) => count);
  let alterStep = 0;

  if (alterStep !== processed[0] && alterStep <= 3) {
    // eslint-disable-next-line prefer-destructuring
    alterStep = processed[0];
  }

  useEffect(() => {
    setCurrentStep(alterStep + 1);
  }, [alterStep]);

  setTimeout(() => {
    funct();
  }, 180000);

  return (
    <div className="steps-container">
      {steps?.map((step, i) => (
        <div
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          className={`step-item ${currentStep === i} ${
            (i < currentStep || false) && "complete"
          }`}
        >
          <div className="step" />
          <p className="pText">{step}</p>
        </div>
      ))}
    </div>
  );
}
