export const banks = [
  {
    name: "BANAMEX",
    acountId: "002",
    id: "BANAMEX",
  },
  {
    name: "BANCOMEXT",
    acountId: "006",
    id: "BANCOMEXT",
  },
  {
    name: "BANOBRAS",
    acountId: "009",
    id: "BANOBRAS",
  },
  {
    name: "BBVA BANCOMER",
    acountId: "012",
    id: "BBVA BANCOMER",
  },
  {
    name: "SANTANDER",
    acountId: "014",
    id: "SANTANDER",
  },
  {
    name: "BANJERCITO",
    acountId: "019",
    id: "BANJERCITO",
  },
  {
    name: "HSBC",
    acountId: "021",
    id: "HSBC",
  },
  {
    name: "BANBAJIO",
    acountId: "030",
    id: "BANBAJIO",
  },
  {
    name: "IXE",
    acountId: "032",
    id: "IXE",
  },
  {
    name: "INBURSA",
    acountId: "036",
    id: "INBURSA",
  },
  {
    name: "INTERACCIONES",
    acountId: "037",
    id: "INTERACCIONES",
  },
  {
    name: "MIFEL",
    acountId: "042",
    id: "MIFEL",
  },
  {
    name: "SCOTIABANK",
    acountId: "044",
    id: "SCOTIABANK",
  },
  {
    name: "BANREGIO",
    acountId: "058",
    id: "BANREGIO",
  },
  {
    name: "INVEX",
    acountId: "059",
    id: "INVEX",
  },
  {
    name: "BANSI",
    acountId: "060",
    id: "BANSI",
  },
  {
    name: "AFIRME",
    acountId: "062",
    id: "AFIRME",
  },
  {
    name: "BANORTE",
    acountId: "072",
    id: "BANORTE",
  },
  {
    name: "THE ROYAL BANK",
    acountId: "102",
    id: "THE ROYAL BANK",
  },
  {
    name: "AMERICAN EXPRESS",
    acountId: "103",
    id: "AMERICAN EXPRESS",
  },
  {
    name: "BAMSA",
    acountId: "106",
    id: "BAMSA",
  },
  {
    name: "TOKYO",
    acountId: "108",
    id: "TOKYO",
  },
  {
    name: "JP MORGAN",
    acountId: "110",
    id: "JP MORGAN",
  },
  {
    name: "BMONEX",
    acountId: "112",
    id: "BMONEX",
  },
  {
    name: "VE POR MAS",
    acountId: "113",
    id: "VE POR MAS",
  },
  {
    name: "ING",
    acountId: "116",
    id: "ING",
  },
  {
    name: "DEUTSCHE",
    acountId: "124",
    id: "DEUTSCHE",
  },
  {
    name: "CREDIT SUISSE",
    acountId: "126",
    id: "CREDIT SUISSE",
  },
  {
    name: "AZTECA",
    acountId: "127",
    id: "AZTECA",
  },
  {
    name: "AUTOFIN",
    acountId: "128",
    id: "AUTOFIN",
  },
  {
    name: "BARCLAYS",
    acountId: "129",
    id: "BARCLAYS",
  },
  {
    name: "COMPARTAMOS",
    acountId: "130",
    id: "COMPARTAMOS",
  },
  {
    name: "BANCO FAMSA",
    acountId: "131",
    id: "BANCO FAMSA",
  },
  {
    name: "BMULTIVA",
    acountId: "132",
    id: "BMULTIVA",
  },
  {
    name: "ACTINVER",
    acountId: "133",
    id: "ACTINVER",
  },
  {
    name: "WAL-MART",
    acountId: "134",
    id: "WAL-MART",
  },
  {
    name: "NAFIN",
    acountId: "135",
    id: "NAFIN",
  },
  {
    name: "INTERBANCO",
    acountId: "136",
    id: "INTERBANCO",
  },
  {
    name: "BANCOPPEL",
    acountId: "137",
    id: "BANCOPPEL",
  },
  {
    name: "ABC CAPITAL",
    acountId: "138",
    id: "ABC CAPITAL",
  },
  {
    name: "UBS BANK",
    acountId: "139",
    id: "UBS BANK",
  },
  {
    name: "CONSUBANCO",
    acountId: "140",
    id: "CONSUBANCO",
  },
  {
    name: "VOLKSWAGEN",
    acountId: "141",
    id: "VOLKSWAGEN",
  },
  {
    name: "CIBANCO",
    acountId: "143",
    id: "CIBANCO",
  },
  {
    name: "BBASE",
    acountId: "145",
    id: "BBASE",
  },
  {
    name: "BANSEFI",
    acountId: "166",
    id: "BANSEFI",
  },
  {
    name: "HIPOTECARIA FEDERAL",
    acountId: "168",
    id: "HIPOTECARIA FEDERAL",
  },
  {
    name: "MONEXCB",
    acountId: "600",
    id: "MONEXCB",
  },
  {
    name: "GBM",
    acountId: "601",
    id: "GBM",
  },
  {
    name: "MASARI",
    acountId: "602",
    id: "MASARI",
  },
  {
    name: "VALUE",
    acountId: "605",
    id: "VALUE",
  },
  {
    name: "ESTRUCTURADORES",
    acountId: "606",
    id: "ESTRUCTURADORES",
  },
  {
    name: "TIBER",
    acountId: "607",
    id: "TIBER",
  },
  {
    name: "VECTOR",
    acountId: "608",
    id: "VECTOR",
  },
  {
    name: "B&B",
    acountId: "610",
    id: "B&B",
  },
  {
    name: "ACCIVAL",
    acountId: "614",
    id: "ACCIVAL",
  },
  {
    name: "MERRILL LYNCH",
    acountId: "615",
    id: "MERRILL LYNCH",
  },
  {
    name: "FINAMEX",
    acountId: "616",
    id: "FINAMEX",
  },
  {
    name: "VALMEX",
    acountId: "617",
    id: "VALMEX",
  },
  {
    name: "UNICA",
    acountId: "618",
    id: "UNICA",
  },
  {
    name: "MAPFRE",
    acountId: "619",
    id: "MAPFRE",
  },
  {
    name: "PROFUTURO",
    acountId: "620",
    id: "PROFUTURO",
  },
  {
    name: "CB ACTINVER",
    acountId: "621",
    id: "CB ACTINVER",
  },
  {
    name: "OACTIN",
    acountId: "622",
    id: "OACTIN",
  },
  {
    name: "CBDEUTSCHE",
    acountId: "626",
    id: "CBDEUTSCHE",
  },
  {
    name: "ZURICH",
    acountId: "627",
    id: "ZURICH",
  },
  {
    name: "ZURICHVI",
    acountId: "628",
    id: "ZURICHVI",
  },
  {
    name: "SU CASITA",
    acountId: "629",
    id: "SU CASITA",
  },
  {
    name: "CB INTERCAM",
    acountId: "630",
    id: "CB INTERCAM",
  },
  {
    name: "CI BOLSA",
    acountId: "631",
    id: "CI BOLSA",
  },
  {
    name: "BULLTICK CB",
    acountId: "632",
    id: "BULLTICK CB",
  },
  {
    name: "STERLING",
    acountId: "633",
    id: "STERLING",
  },
  {
    name: "FINCOMUN",
    acountId: "634",
    id: "FINCOMUN",
  },
  {
    name: "HDI SEGUROS",
    acountId: "636",
    id: "HDI SEGUROS",
  },
  {
    name: "ORDER",
    acountId: "637",
    id: "ORDER",
  },
  {
    name: "AKALA",
    acountId: "638",
    id: "AKALA",
  },
  {
    name: "CB JPMORGAN",
    acountId: "640",
    id: "CB JPMORGAN",
  },
  {
    name: "REFORMA",
    acountId: "642",
    id: "REFORMA",
  },
  {
    name: "STP",
    acountId: "646",
    id: "STP",
  },
  {
    name: "TELECOMM",
    acountId: "647",
    id: "TELECOMM",
  },
  {
    name: "EVERCORE",
    acountId: "648",
    id: "EVERCORE",
  },
  {
    name: "SEGMTY",
    acountId: "651",
    id: "SEGMTY",
  },
  {
    name: "ASEA",
    acountId: "652",
    id: "ASEA",
  },
  {
    name: "KUSPIT",
    acountId: "653",
    id: "KUSPIT",
  },
  {
    name: "SOFIEXPRESS",
    acountId: "655",
    id: "SOFIEXPRESS",
  },
  {
    name: "UNAGRA",
    acountId: "656",
    id: "UNAGRA",
  },
  {
    name: "OPCIONES EMPRESARIALES DEL NOROESTE",
    acountId: "659",
    id: "OPCIONES EMPRESARIALES DEL NOROESTE",
  },
  {
    name: "CLS",
    acountId: "901",
    id: "CLS",
  },
  {
    name: "INDEVAL",
    acountId: "902",
    id: "INDEVAL",
  },
  {
    name: "LIBERTAD",
    acountId: "670",
    id: "LIBERTAD",
  },
];
