/**
 * Pass a file, if the file size is greater than 2MB, it will return an error message
 * else it will return true
 * @param {File} file
 */
export default function validateFileSize(file) {
  if (file.size < 2000000) {
    return true;
  }

  return "Revisa que el archivo pese menos de 2 MB";
}
